import { useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer';
import { LogoCarousel } from '../Components/Logos/LogoCarousel';
import { NavBar } from '../Components/NavBar';
import TestimonialCarousel from '../Components/Testimonials/TestimonialCarousel';
import simplifiedLogo from '../media/img/simplifiedApproch.png';
import dollarLogo from '../media/img/dollarLogo.png';
import tailorSolutionLogo from '../media/img/tailorSolution.png';
import AboutInformation from '../Components/AboutInformation';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { NavButton } from '../Components/Button';
import heroMainImgMobile from '../media/img/heroMainImgMobile.png';
import { EnterCoverageModal } from '../Components/Modals/EnterCoverageModal';

const Home = () => {
  const [modal, setModal] = useState(false);
  const [faceAmount, setFaceAmount] = useState(0);
  const [newData, setNewData] = useState('');
  const LocalUsageData = localStorage.getItem('usageData');
  const navigate = useNavigate();

  const onCalculateNeeds = () => {
    navigate('/simple-term-life-insurance-calculator');
  };

  const onGetQuote = () => {
    if (LocalUsageData) {
      navigate('/quotes-summary');
    } else {
      return setModal(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='min-h-screen bg-explainWhite'>
      <Helmet>
        <title>ExplainLife Insurance | Term Life Insurance Provider</title>
        <meta
          name='description'
          content='Get term life insurance in minutes and make informed decisions to protect what matters most with our expert guidance from $500k to $10 million.'
        />
        <meta
          property='og:title'
          content='ExplainLife Insurance | Term Life Insurance Provider'
        />
        <meta
          property='og:description'
          content='Get term life insurance in minutes and make informed decisions to protect what matters most with our expert guidance from $500k to $10 million.'
        />
      </Helmet>
      <NavBar />
      <div className='pt-20 text-darkBrown'>
        <div className='flex md:flex-col bg-lightBrown md:gap-5 items-center justify-between bg-heroImg md:bg-gradient-to-b from-lightBrown to-[#FFFBF8] bg-no-repeat bg-right bg-contain lg:bg-cover'>
          <div className='max-w-[1500px] w-full px-16 sm:px-5 mx-auto py-20 xs:pt-10 xs:pb-5'>
            <div className='w-[450px] sm:w-full xs:text-center'>
              <h1 className='font-judson_italic font-light text-5xl sm:text-4xl mb-4'>
                Providing Financial Security for Your Loved Ones
              </h1>
              <p className='text-sm text-darkBrown'>
                Get life insurance in minutes and make informed decisions to
                protect what matters most with our expert guidance.
              </p>
              <div className='mt-5 flex xs:flex-col xs:justify-center xs:items-center gap-3'>
                <div className='flex xs:w-full justify-center'>
                  <NavButton variant='primary' onClick={onCalculateNeeds}>
                    <div className='flex flex-row gap-2 items-center'>
                      How much life insurance do I need?
                      <ChevronRightIcon className='w-4 h-4' />
                    </div>
                  </NavButton>
                </div>
                <div className='flex xs:w-full justify-center'>
                  <NavButton variant='secondary' onClick={onGetQuote}>
                    <div className='flex flex-row gap-1 items-center'>
                      Get a quote
                      <ChevronRightIcon className='w-4 h-4' />
                    </div>
                  </NavButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden md:block'>
          <img src={heroMainImgMobile} className='w-full' alt='' />
        </div>
        <div className='bg-explainWhite'>
          <div className='client-carousel pb-[20px] sm:pb-5'>
            <div className='pt-4 my-0 h-full max-w-[1500px] xxl:w-[95vw] m-auto'>
              <LogoCarousel />
            </div>
          </div>
          <div className='whyChooseDiv max-w-[1500px] px-16 pb-14 md:px-5 m-auto'>
            <h2 className='font-judson_italic text-center xs:text-start block subpixel-antialiased font-light text-4xl sm:text-3xl mb-10 xs:mb-0'>
              Why Choose ExplainLife?
            </h2>
            <div className='flex flex-wrap gap-30 justify-center lg:justify-center'>
              <div className='w-4/12 md:w-full px-4 lg:py-5 xs:px-0'>
                <div className='heading flex items-center gap-3'>
                  <img src={simplifiedLogo} alt='simplifiedLogo' />
                  <h3 className='font-judson_italic font-light text-2xl'>
                    Simplified Approach
                  </h3>
                </div>
                <p className='mt-2'>
                  Accessible and easy-to-understand life insurance planning,
                  cutting through jargon to empower you in making informed
                  choices for your family's future.
                </p>
              </div>
              <div className='w-4/12 md:w-full px-4 lg:py-5 xs:px-0'>
                <div className='heading items-center flex gap-3'>
                  <img src={tailorSolutionLogo} alt='tailorSolutionLogo' />
                  <h3 className='font-judson_italic font-light text-2xl'>
                    Personalized Recommendations
                  </h3>
                </div>
                <p className='mt-2'>
                  Tailored life insurance plans designed to meet your unique
                  needs, providing comprehensive and proper coverage for your
                  family.
                </p>
              </div>
              <div className='w-4/12 md:w-full px-4 lg:py-5 xs:px-0'>
                <div className='heading items-center flex gap-3'>
                  <img src={dollarLogo} alt='dollarLogo' />
                  <h3 className='font-judson_italic font-light text-2xl'>
                    No Pressure, No Spam
                  </h3>
                </div>
                <p className='mt-2'>
                  Get expert advice without sharing personal details until
                  you're ready to apply. We prioritize your peace of mind, so we
                  only ask for information when it's completely necessary.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-mintGreen'>
          <AboutInformation />
        </div>
      </div>
      <div className='bg-explainWhite py-[5rem] md:py-[2rem]'>
        <div className='flex justify-center md:flex-col max-w-[1500px] m-auto px-16 md:px-10 xs:px-5 w-full gap-[75px] md:gap-0 sm:h-fit '>
          <div className='flex flex-col md:justify-center text-darkBrown w-5/12 md:w-full font-medium'>
            <h2 className='font-judson_italic block subpixel-antialiased text-4xl sm:text-3xl mb-4'>
              Our 3-Step Process
            </h2>
            <p className='xs:text-sm'>
              ExplainLife offers a simple three-step process for obtaining
              hassle-free life insurance. Calculate your specific needs with our
              user-friendly calculator, receive instant personalized quotes, and
              apply for your chosen policy directly on our website.
            </p>
            <p className='my-5 xs:text-sm'>
              Safeguard your family's future easily with our streamlined
              process.
            </p>
            <div className='pt-5 flex gap-3 md:hidden'>
              <NavButton variant='primary' onClick={onCalculateNeeds}>
                <div className='flex flex-row gap-2 items-center'>
                  How much life insurance do I need?
                  <ChevronRightIcon className='w-4 h-4' />
                </div>
              </NavButton>
              <NavButton variant='secondary' onClick={onGetQuote}>
                <div className='flex flex-row gap-1 items-center'>
                  Get a quote
                  <ChevronRightIcon className='w-4 h-4' />
                </div>
              </NavButton>
            </div>
          </div>
          <div className='stepsMainDiv text-darkBrown w-fit md:w-full flex flex-col gap-5'>
            <div className='first rounded-xl bg-lightBrown p-5'>
              <div className='flex items-center gap-3'>
                <div className='bg-mintGreen inline-block px-4 py-2 rounded-full'>
                  1
                </div>
                <h3 className='font-judson_italic font-light text-2xl'>
                  Calculate Needs
                </h3>
              </div>
              <p className='mt-3'>
                Use our calculator to understand your family's unique life
                insurance needs
              </p>
            </div>
            <div className='second rounded-xl bg-lightBrown p-5'>
              <div className='flex items-center gap-3'>
                <div className='bg-mintGreen inline-block px-4 py-2 rounded-full'>
                  2
                </div>
                <h3 className='font-judson_italic font-light text-2xl'>
                  Get a quote
                </h3>
              </div>
              <p className='mt-3'>
                Get instant quotes and see our personalized recommendation
              </p>
            </div>
            <div className='third rounded-xl bg-lightBrown p-5'>
              <div className='flex items-center gap-3'>
                <div className='bg-mintGreen inline-block px-4 py-2 rounded-full'>
                  3
                </div>
                <h3 className='font-judson_italic font-light text-2xl'>
                  Apply Now
                </h3>
              </div>
              <p className='mt-3'>
                Apply for your selected life insurance policy right here on our
                website!
              </p>
            </div>
          </div>
          <div className='pt-5 hidden gap-3 md:flex md:flex-col md:justify-center md:items-start'>
            <NavButton
              variant='primary'
              className='w-fit justify-center'
              onClick={onCalculateNeeds}
            >
              <div className='flex flex-row gap-2 items-center'>
                How much life insurance do I need?
                <ChevronRightIcon className='w-4 h-4' />
              </div>
            </NavButton>
            <NavButton
              variant='secondary'
              className='w-fit justify-center'
              onClick={onGetQuote}
            >
              <div className='flex flex-row gap-1 items-center'>
                Get a quote
                <ChevronRightIcon className='w-4 h-4' />
              </div>
            </NavButton>
          </div>
        </div>
        <div className=''>
          <div className='flex flex-col px-10 xs:px-5 pt-[5rem] xs:py-[3rem] gap-3 max-w-[1500px] 2xl:m-auto'>
            <h2 className='font-judson_italic font-light text-darkBrown text-4xl sm:text-4xl text-center'>
              What Our Clients Say
            </h2>
            <TestimonialCarousel />
          </div>
        </div>
      </div>
      <Footer />
      <EnterCoverageModal
        show={modal}
        setShow={setModal}
        faceAmount={faceAmount}
        setFaceAmount={setFaceAmount}
        newData={newData}
        setNewData={setNewData}
        isGetQuoteBtn={true}
      />
    </div>
  );
};

export default Home;
