import { useEffect, useState } from "react";
import { NavBar } from "../Components/NavBar";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { NavButton } from "../Components/Button";
import { useNavigate } from "react-router-dom";
import buildin1 from "../media/img/building.png";
import Footer from "../Components/Footer";
import axios from "axios";
import { BaseURL } from "../helpers/API/ApiData";
import { Helmet } from "react-helmet";
import { capitalizeFirstLetter } from "./BlogCategory";
import { ErrorToast } from "../helpers/Toast";

const Blog = () => {
  const navigate = useNavigate();
  const [cat1, setCat1] = useState<any>([]);
  const [cat2, setCat2] = useState<any>([]);
  const [cat3, setCat3] = useState<any>([]);
  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    await axios
      .get(BaseURL + "/user/get/group/blog")
      .then((res) => {
        setData(res?.data?.data);
        setCat1(res?.data?.data?.foundations_of_term_life_insurance);
        setCat2(res?.data?.data?.types_of_term_life_insurance);
        setCat3(res?.data?.data?.frequently_asked_questions);
      })
      .catch((err) => ErrorToast(err?.message));
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleBlogPostClick = (blogInfo: any) => {
    const formattedTitle = blogInfo?.title.replaceAll(" ", "-").toLowerCase();

    navigate("/blog/posts/" + blogInfo?._id + "/" + formattedTitle);
  };

  return (
    <>
      <div className="flex text-darkBrown flex-col bg-lightBrown">
        <Helmet>
          <title>Life Insurance Blog | ExplainLife</title>
          <meta
            name="description"
            content="ExplainLife is here to help you navigate life! From term life insurance to general life insurance tips, ExplainLife has advice you can count on!"
          />
          <meta
            property="og:title"
            content="Life Insurance Blog | ExplainLife"
          />
          <meta
            property="og:description"
            content="ExplainLife is here to help you navigate life! From term life insurance to general life insurance tips, ExplainLife has advice you can count on!"
          />
        </Helmet>
        <NavBar />
        <div className="max-w-[1500px] mx-auto w-full px-16 md:px-5  mt-10 pb-14 ">
          <div className="bg-lightBrown mt-20">
            <h1 className="text-5xl font-judson_italic font-medium text-darkBrown ">
              ExplainLife Guide
            </h1>
            <p className="mt-5 ">
              Your resource center for in-depth guides and articles that'll walk
              you though everything you want to know about life insurance.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full bg-explainWhite">
        <div className="flex max-w-[1500px] mx-auto w-full px-16 md:px-5">
          <div className="flex lg:flex-col mt-12">
            <div className="w-1/2 lg:w-full pr-20">
              <h2 className="text-4xl font-judson_italic font-bold text-darkBrown">
                Top Picks
              </h2>
              <h2 className="text-2xl font-judson_italic font-bold text-darkBrown mt-5">
                Our Top Picks for Quick Life Insurance Learning
              </h2>
              <h2 className="text-lg font-base text-darkBrown mt-5">
                Start your life insurance journey here with these three
                articles. Handpicked for their clear and concise explanations,
                they provide a quick yet comprehensive overview of life
                insurance fundamentals. By understanding these essential
                concepts, you'll be well-equipped to make the best insurance
                decision for your family's future.
              </h2>
            </div>

            <div className="w-1/2 lg:w-full lg:mt-5">
              {data?.map((item: any, ind: number) => {
                return (
                  <div
                    className="flex xs:flex-col bg-lightBrown rounded-xl my-3"
                    key={ind}
                  >
                    <div className="p-5 w-[225px] h-[175px] mx-auto xs:w-full">
                      <img
                        src={item?.blogData[0]?.picture}
                        alt=""
                        className="w-full h-full rounded-xl cursor-pointer object-cover"
                        onClick={() => handleBlogPostClick(item?.blogData[0])}
                      />
                    </div>
                    <div className="p-5 flex-1 border-darkBrown/[.3] ">
                      <h2 className="text-base font-judson_italic border w-fit px-3 border-darkBrown/[.3]">
                        {item?.blogData[0]?.author}
                      </h2>
                      <h2
                        className="text-xl font-judson_italic cursor-pointer font-bold text-darkBrown mt-2"
                        onClick={() => handleBlogPostClick(item?.blogData[0])}
                      >
                        {item?.blogData[0]?.title}
                      </h2>
                      <p>{item?.blogData[0]?.shortDescription}</p>
                    </div>
                  </div>
                );
              })}
              {cat1?.length > 0 && (
                <div className="flex xs:flex-col bg-lightBrown rounded-xl ">
                  <div className="p-5 w-[225px] h-[175px] mx-auto xs:w-full">
                    <img
                      src={cat1[0]?.picture}
                      alt=""
                      className="w-full h-full rounded-xl cursor-pointer object-cover"
                      onClick={() => handleBlogPostClick(cat1[0])}
                    />
                  </div>
                  <div className="p-5 flex-1 border-darkBrown/[.3] ">
                    <h2 className="text-base font-judson_italic border w-fit px-3 border-darkBrown/[.3]">
                      {cat1[0]?.author}
                    </h2>
                    <h2
                      className="text-xl font-judson_italic cursor-pointer font-bold text-darkBrown mt-2"
                      onClick={() => handleBlogPostClick(cat1[0])}
                    >
                      {cat1[0]?.title}
                    </h2>
                    <p>{cat1[0]?.shortDescription}</p>
                  </div>
                </div>
              )}
              {cat2?.length > 0 && (
                <div className="flex xs:flex-col bg-lightBrown rounded-xl my-5">
                  <div className="p-5 w-[225px] h-[175px] mx-auto xs:w-full">
                    <img
                      src={cat2[0]?.picture}
                      alt=""
                      className="w-full h-full cursor-pointer rounded-xl object-cover"
                      onClick={() => handleBlogPostClick(cat2[0])}
                    />
                  </div>
                  <div className="p-5 flex-1 border-darkBrown/[.3] ">
                    <h2 className="text-base font-judson_italic border w-fit px-3 border-darkBrown/[.3] ">
                      {cat2[0]?.author}
                    </h2>
                    <h2
                      className="text-xl font-judson_italic cursor-pointer font-bold text-darkBrown mt-2"
                      onClick={() => handleBlogPostClick(cat2[0])}
                    >
                      {cat2[0]?.title}
                    </h2>
                    <p>{cat2[0]?.shortDescription}</p>
                  </div>
                </div>
              )}
              {cat3?.length > 0 && (
                <div className="flex xs:flex-col bg-lightBrown rounded-xl mb-10">
                  <div className="p-5 w-[225px] h-[175px] mx-auto xs:w-full">
                    <img
                      src={cat3[0]?.picture}
                      alt=""
                      className="w-full h-full cursor-pointer rounded-xl object-cover"
                      onClick={() => handleBlogPostClick(cat3[0])}
                    />
                  </div>
                  <div className="p-5 flex-1 border-darkBrown/[.3] ">
                    <h2 className="text-base font-judson_italic border w-fit px-3 border-darkBrown/[.3] ">
                      {cat3[0]?.author}
                    </h2>
                    <h2
                      className="text-xl font-judson_italic cursor-pointer font-bold text-darkBrown mt-2"
                      onClick={() => handleBlogPostClick(cat3[0])}
                    >
                      {cat3[0]?.title}
                    </h2>
                    <p>{cat3[0]?.shortDescription}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {data?.map((item: any, ind: number) => {
        return (
          <div
            className={`${ind % 2 === 0 ? "bg-lightBrown" : "bg-explainWhite"
              } py-10`}
          >
            <div className="max-w-[1500px] mx-auto w-full px-16 md:px-5">
              <div className="flex justify-between items-center">
                <h2 className="text-4xl xxs:text-2xl font-judson_italic text-darkBrown  mt-10">
                  {item?.categoryName
                    ? capitalizeFirstLetter(item?.categoryName)
                    : "-"}
                </h2>
                <div>
                  <div>
                    <NavButton
                      variant="secondary"
                      className="mt-10"
                      onClick={() =>
                        navigate(
                          "/blog/" +
                          item?.categoryName
                            ?.replaceAll("_", "-")
                            .replaceAll(" ", "-")
                        )
                      }
                    >
                      <div className="flex flex-row gap-1 whitespace-nowrap">
                        See all
                        <ChevronRightIcon className="w-4 h-4" />
                      </div>
                    </NavButton>
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-wrap lg:justify-center gap-10 py-10">
                {item?.blogData?.length > 0 &&
                  item?.blogData?.slice(0, 3)?.map((v: any, ind: any) => {
                    return (
                      <div
                        className="border border-darkBrown/[.3] w-1/3 lg:w-2/3 xs:w-full md:mx-5 rounded-3xl overflow-hidden"
                        key={ind}
                      >
                        <img
                          src={v?.picture || buildin1}
                          alt="pic"
                          className="w-full object-cover cursor-pointer h-[250px]"
                          onClick={() => handleBlogPostClick(v)}
                        />
                        <h2 className="text-base font-judson_italic border w-fit px-3 border-darkBrown/[.3] mt-5 mx-5">
                          {v?.author}
                        </h2>
                        <h2
                          className="text-2xl font-judson_italic cursor-pointer font-bold text-darkBrown mt-2 px-5"
                          onClick={() => handleBlogPostClick(v)}
                        >
                          {v?.title || "-"}
                        </h2>
                        <h2 className="text-lg text-darkBrown pb-5 px-5 py-5">
                          {v?.shortDescription}
                        </h2>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        );
      })}
      <div className="bg-explainWhite py-10">
        <div className=" max-w-[1500px] mx-auto w-full px-16 md:px-5 mt-10 pb-14 ">
          <div className="flex justify-center">
            <h2 className="text-4xl font-judson_italic font-bold text-darkBrown text-center">
              Personalized life insurance for your family
            </h2>
          </div>
          <div>
            <p className="flex justify-center mt-5 text-center">
              With ExplainLife, secure the proper life insurance for your
              family. Avoid pushy agents and confusing jargon.
            </p>
          </div>
          <div className="flex justify-center mt-10">
            <NavButton
              variant="primary"
              onClick={() => navigate("/simple-term-life-insurance-calculator")}
            >
              <div className="flex flex-row gap-2 items-center">
                How much life insurance do I need?
                <ChevronRightIcon className="w-4 h-4" />
              </div>
            </NavButton>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
