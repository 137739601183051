import { Dialog, Transition } from "@headlessui/react";
import { ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cleanNumber, formatCurrency } from "../../utils/utils";
import { mouseDown, mouseUp } from "../Button";
import { CalculatorFormContext } from "../../Pages/Calculator/context";

const COVERAGE_INPUT_CLASSES = classNames(
  "text-center",
  "w-56",
  "xs:w-full",
  "font-medium",
  "text-xl",
  "outline-none",
  "h-7",
  "text-darkBrown",
  "px-2",
  "py-5",
  "outline-none",
  "mb-4",
  "ml-1",
  "focus:ring-0",
  "border-b-2",
  "border-darkBrown",
  "bg-transparent",
  "tracking-wider",
  "rounded-none"
);

export const ChangeCoverageModal = (props: any) => {
  const navigate = useNavigate();
  const { show, setShow, price, setPrice } = props;
  const { formData } = useContext(CalculatorFormContext);
  const [faceAmount, setFaceAmount] = useState(false);
  const [amount, setAmount] = useState<any>(formatCurrency(price));

  const handleChangeFaceValue = (event: any) => {
    const newFaceValue: any = formatCurrency(cleanNumber(event.target.value));
    setAmount(newFaceValue);
  };

  useEffect(() => {
    setAmount(formatCurrency(price));
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (faceAmount && window.innerWidth > 1023) {
      inputRef.current?.focus();
    }
  }, [faceAmount]);

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog onClose={() => setShow(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30 z-[1000] flex items-center justify-center">
              <Dialog.Panel className="max-w-[640px] max-h-[85vh] overflow-auto transform rounded-2xl bg-mintGreen md:mx-5 py-5 shadow-xl transition-all">
                <button
                  type="button"
                  className="box-content rounded-none border-none absolute right-[15px] top-[15px]"
                  onClick={() => setShow(false)}
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
                <div className="px-6 flex flex-col">
                  <h2 className="text-darkBrown text-center text-[24px] xs:text-[22px] xs:mt-3 font-medium w-[450px] xs:w-[90vw] mx-auto">
                    Be careful!
                  </h2>
                  <div>
                    <p className="text-darkBrown text-center mt-5 xs:text-sm">
                      If you choose to input a different coverage amount, you
                      will override all of the previous data entered and we will
                      not be able to give you a personalized breakdown.
                    </p>
                    {!faceAmount && (
                      <div className="mt-5 flex xs:flex-col gap-5 text-lg xs:text-base">
                        <button
                          className="bg-darkBrown text-center text-white rounded-full py-3 px-8 xs:px-4 cursor-pointer"
                          onClick={() => {
                            setShow(false);
                          }}
                        >
                          <span>Back</span>
                        </button>
                        <button
                          onPointerDown={mouseDown}
                          onPointerUp={mouseUp}
                          onPointerLeave={mouseUp}
                          onMouseDown={mouseDown}
                          onMouseUp={mouseUp}
                          onMouseLeave={mouseUp}
                          className="bg-lightBrown w-full rounded-full font-medium py-3 px-8 xs:px-4 shadow-[4px_4px_0px_#3C2C28] border-2 border-darkBrown duration-75 tracking-wide xs:text-sm"
                          onClick={() => setFaceAmount(!faceAmount)}
                        >
                          Enter Life Insurance Coverage Amount
                        </button>
                      </div>
                    )}
                    {faceAmount && (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (amount) {
                            setPrice(cleanNumber(amount));
                            localStorage.setItem(
                              "quoteData",
                              JSON.stringify({
                                ...formData,
                                newAmount: cleanNumber(amount),
                                calculation: cleanNumber(amount),
                              })
                            );
                            sessionStorage.setItem(
                              "quoteData",
                              JSON.stringify({
                                ...formData,
                                newAmount: cleanNumber(amount),
                                calculation: cleanNumber(amount),
                              })
                            );
                            navigate("/term-life-insurance-quote", {
                              state: {
                                coverage: cleanNumber(amount),
                                formData: {},
                              },
                            });
                          }
                        }}
                        className="mt-5"
                      >
                        <div className="flex flex-row xs:flex-col xs:gap-0 gap-10 items-center">
                          <div className="font-medium text-lg mb-2 w-full text-darkBrown">
                            Life Insurance Coverage Amount
                          </div>
                          <input
                            ref={inputRef}
                            id="autoFocusId"
                            type="text"
                            className={COVERAGE_INPUT_CLASSES}
                            placeholder={formatCurrency(0)}
                            value={amount}
                            onChange={handleChangeFaceValue}
                            required
                          />
                        </div>
                        <button
                          onPointerDown={mouseDown}
                          onPointerUp={mouseUp}
                          onPointerLeave={mouseUp}
                          onMouseDown={mouseDown}
                          onMouseUp={mouseUp}
                          onMouseLeave={mouseUp}
                          className="bg-lightBrown rounded-full xs:text-base font-medium py-3 pl-8 pr-7 xs:px-4 xs:mb-2 shadow-[4px_4px_0px_#3C2C28] border-2 border-darkBrown duration-75 tracking-wide xs:w-full"
                          type="submit"
                        >
                          <div className="flex flex-row gap-2 items-center">
                            Ready for a Quote?
                            <ChevronRightIcon className="w-4 h-4" />
                          </div>
                        </button>
                      </form>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
