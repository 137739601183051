import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavBar } from "../Components/NavBar";
import Footer from "../Components/Footer";
import noah from "../media/img/Noah.jpg";
import adam from "../media/img/Adam.jpg";
import im3 from "../media/img/thumb.png";
import im4 from "../media/img/dollar.png";
import im5 from "../media/img/light.png";
import ReactPlayer from "react-player";

const AboutUsNew = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="flex text-darkBrown flex-col bg-lightBrown ">
        <Helmet>
          <title>About Us | ExplainLife</title>
          <meta
            name="description"
            content="ExplainLife Insurance is transforming the way people buy term life insurance online. Founded by Noah Kaminer & Adam Kaminer."
          />
          <meta property="og:title" content="About Us | ExplainLife" />
          <meta
            property="og:description"
            content="ExplainLife Insurance is transforming the way people buy term life insurance online. Founded by Noah Kaminer & Adam Kaminer."
          />
        </Helmet>
        <NavBar />
        <div className="h-[480px] bg-lightBrown"></div>
        <div className="bg-explainWhite">
          <div className="mt-[-400px] xs:mt-[-300px]">
            <h3 className="font-archivo font-medium text-center mt-8 xs:mt-0 text-lg">
              About Us
            </h3>
            <div className="flex text-darkBrown items-center justify-center mt-8">
              <h1 className="text-4xl max-w-[400px] text-center font-judson_italic font-medium text-darkBrown ">
                Tailoring Life Insurance to Your Family's Needs
              </h1>
            </div>
            <div className="max-w-[1200px] w-full px-16 xs:px-5 mx-auto mt-10 pb-14">
              <div
                className="video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full md:w-full"
                onClick={() => {
                  if (isPlaying) {
                    setIsPlaying(false);
                  }
                }}
              >
                <ReactPlayer
                  url="https://explain-bucket.s3.amazonaws.com/explainBannerVideos/About+Us.mp4"
                  playing={isPlaying}
                  className="react-player"
                  playsinline
                />
                <button
                  className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                    isPlaying ? "invisible" : "visible"
                  }`}
                  onClick={() => setIsPlaying(!isPlaying)}
                ></button>
              </div>
            </div>
          </div>
          <div className="flex justify-center md:flex-col md:gap-10 gap-32 w-full max-w-[1500px] mx-auto text-xl mb-14">
            <div className="w-[35%] lg:w-full flex pl-32 lg:pl-20 md:pl-16 md:px-20 xxs:px-10 justify-end">
              <p>
                ExplainLife is transforming the way people buy life insurance
                online. Say goodbye to generic policies and confusing terms.
                Instead, welcome a straightforward, user-friendly platform
                designed to craft life insurance plans around your family's
                distinct needs.
              </p>
            </div>
            <div className="w-[35%] lg:w-full flex pr-32 lg:pr-20 md:pr-16 md:px-20 xxs:px-10 justify-start">
              <p>
                We're aligning clients with the ideal life insurance plans
                tailored to their family's unique circumstances. We deeply
                understand the significance of securing your family's future,
                taking immense pride in our precise and personalized service.
              </p>
            </div>
          </div>
          <div className="bg-mintGreen py-14 w-full h-full">
            <h2 className="text-4xl text-center font-judson_italic font-medium text-darkBrown ">
              Our Founders
            </h2>
            <div className="flex md:flex-col items-center mx-auto lg:mx-auto max-w-[1024px] lg:max-w-[830px] md:max-w-[768px] justify-between mt-10">
              <div className="w-[400px] xxs:w-[90vw] mx-auto lg:h-full   items-center justify-center">
                <div className="h-[658px] md:w-full md:h-full border-2 text-center border-darkBrown/[.3] rounded-xl p-5 mx-auto ">
                  <img
                    src={noah}
                    alt=""
                    className="w-[366px] h-[366px] xxs:object-cover rounded-lg"
                  />
                  <h3 className="text-2xl font-semibold mt-5">Noah Kaminer</h3>
                  <h3 className="text-2xl font-semibold">Co-Founder and CEO</h3>
                  <p className="mt-5">
                    Noah, a graduate from UC Berkeley and a software engineer,
                    has a fervor for crafting simple, user-friendly consumer
                    products. It's this passion that led him to establish
                    ExplainLife, an intuitive platform changing how we approach
                    life insurance.
                  </p>
                </div>
              </div>
              <div className="w-[400px] xxs:w-[90vw] mx-auto lg:h-full md:mt-5 items-center justify-center">
                <div className="h-[658px] md:h-full  border-2 text-center border-darkBrown/[.3] rounded-xl p-5 mx-auto">
                  <img
                    src={adam}
                    alt=""
                    className="w-[366px] h-[366px] xxs:object-cover rounded-lg"
                  />
                  <h3 className="text-2xl font-semibold mt-5">Adam Kaminer</h3>
                  <h3 className="text-2xl font-semibold">
                    Co-Founder and President
                  </h3>
                  <p className="mt-5">
                    Adam has over 30 years of experience in helping families
                    protect against unforeseen circumstances with life
                    insurance, Adam is a well-known name in the industry. You
                    may recognize him from his other successful businesses,
                    Kaminer Financial Group and KEH Insurance Agency.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex text-darkBrown items-center justify-center mt-12">
            <h2 className="text-4xl mb-8 font-judson_italic font-medium text-darkBrown ">
              What sets us apart?
            </h2>
          </div>
          <div className="flex md:flex-col items-center max-w-[1200px] gap-4 w-full px-16 xs:px-5 mx-auto pb-12 mb-12">
            <div className="w-1/3 md:w-full h-[260px] items-center justify-center text-center">
              <div className="border-2 border-darkBrown/[.2] rounded-lg w-auto h-full">
                <div className="flex-col h-full items-center justify-center text-center">
                  <img
                    src={im3}
                    alt="thumb"
                    className="w-[50px] h-[50px] mx-auto mt-10 mb-10"
                  />
                  <p className="px-5 mt-5 mb-5 font-judson_italic text-xl">
                    No personal details required until you&apos;re ready to
                    apply.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-1/3 md:w-full h-[260px] items-center justify-center">
              <div className="border-2 border-darkBrown/[.2] rounded-lg w-auto  h-full  ">
                <div className="flex-col h-full items-center justify-center text-center">
                  <img
                    src={im4}
                    alt="dollar"
                    className="w-[50px] h-[50px] mx-auto  mt-10 mb-10"
                  />
                  <p className="px-5 mt-5 mb-5 font-judson_italic text-xl">
                    We match life insurance plans to your family, not the other
                    way around.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-1/3 md:w-full h-[260px] items-center justify-center">
              <div className="border-2 border-darkBrown/[.2] rounded-lg w-auto  h-full">
                <div className="flex-col h-full items-center justify-center text-center">
                  <img
                    src={im5}
                    alt="light"
                    className="w-[50px] h-[50px] mx-auto  mt-10 mb-10"
                  />
                  <p className="px-5 mt-5 mb-10 font-judson_italic text-xl">
                    No pushy agents, no spam - just peace of mind.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutUsNew;
