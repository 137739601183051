import { Dialog, Transition } from "@headlessui/react";
import { ArrowPathIcon, XMarkIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import classNames from "classnames";
import { Fragment, useContext, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BaseURL } from "../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import { CalculatorFormContext } from "../../Pages/Calculator/context";
import { cleanNumber, formatCurrency } from "../../utils/utils";
import { NavButton } from "../Button";

const COVERAGE_INPUT_CLASSES = classNames(
  "w-1/2",
  "xs:w-full",
  "font-medium",
  "text-lg",
  "outline-none",
  "h-7",
  "text-darkBrown",
  "px-2",
  "py-5",
  "outline-none",
  "mb-4",
  "ml-1",
  "focus:ring-0",
  "border-b-2",
  "border-darkBrown",
  "bg-transparent",
  "tracking-wider",
  "rounded-none"
);

export const BirthCheckModal = (props: any) => {
  const { setFormData1 } = useContext(CalculatorFormContext);
  const {
    show,
    setShow,
    sessionId,
    setResponse,
    setActive,
    setPreview,
    setRecommendPremium,
    setIncomeRight,
    setExpenseRight,
    setSavingRight,
    setPrice,
    setNewData,
    setSelectedState,
  } = props;
  const pathName = window.location.pathname;

  const [newData1, setNewData1] = useState({
    birthdate: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = () => {
    const dateInput: any = document.getElementById("birthDateCheck");
    let inputText = dateInput?.value;
    inputText = inputText.replace(/\D/g, "");
    if (inputText.length > 2) {
      inputText = inputText.slice(0, 2) + "-" + inputText.slice(2);
    }
    if (inputText.length > 5) {
      inputText = inputText.slice(0, 5) + "-" + inputText.slice(5);
    }
    setNewData1({ ...newData1, birthdate: inputText });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .get(BaseURL + "/user/get/session/" + sessionId)
      .then(async (res) => {
        const data: any = res?.data?.data;
        if (data?.birthdate === newData1?.birthdate) {
          SuccessToast("success");
          sessionStorage.setItem("birthdateCheck", JSON.stringify(true));
          if (data?.getQuoteFormData) {
            if (
              data?.getQuoteFormData?.newData &&
              pathName === "/term-life-insurance-quote"
            ) {
              setNewData(data?.getQuoteFormData?.newData);
              setSelectedState(data?.getQuoteFormData?.selectedState);
            }
          }
          if (data?.quoteData) {
            localStorage.setItem("quoteData", JSON.stringify(data?.quoteData));
            setFormData1(data?.quoteData);
            if (pathName === "/calculation-summary") {
              let body = {
                inflationRate: cleanNumber(data?.quoteData["inflationRate"]),
                interestRate: cleanNumber(data?.quoteData["interestRate"]),
                insuredIncome: cleanNumber(data?.quoteData["insuredIncome"]),
                beforeTaxYearlySavings: cleanNumber(
                  data?.quoteData["beforeTaxYearlySavings"]
                ),
                afterTaxYearlySavings: cleanNumber(
                  data?.quoteData["afterTaxYearlySavings"]
                ),
                mortgage: cleanNumber(data?.quoteData["mortgage"]),
                spouseIncome: cleanNumber(data?.quoteData["spouseIncome"]),
                taxRate: cleanNumber(data?.quoteData["taxRate"]),
                spouseCoverageAge: cleanNumber(
                  data?.quoteData["spouseCoverageAge"]
                ),
                spouseAge: cleanNumber(data?.quoteData["spouseAge"]),
                spouseIncomeYears: cleanNumber(
                  data?.quoteData["spouseIncomeYears"]
                ),
                debt: cleanNumber(data?.quoteData["debt"]),
                numInState: cleanNumber(data?.quoteData["numInState"]),
                inStateCost: cleanNumber(data?.quoteData["inStateCost"]),
                numPrivate: cleanNumber(data?.quoteData["numPrivate"]),
                privateCost: cleanNumber(data?.quoteData["privateCost"]),
                oneTime: cleanNumber(data?.quoteData["oneTime"]),
                savings: cleanNumber(data?.quoteData["savings"]),
              };
              await axios
                .post(BaseURL + "/user/api/data/summary", body)
                .then(({ data }) => {
                  setIncomeRight(formatCurrency(data?.data?.incomeNPV));
                  setExpenseRight(formatCurrency(data?.data?.expenses));
                  setSavingRight(formatCurrency(data?.data?.savings));
                  setPrice(data?.data?.calculation);
                });
            }
          }
          if (data?.usageData && data?.usageData?.length > 0) {
            localStorage.setItem("usageData", JSON.stringify(data?.usageData));
            if (pathName === "/quotes-summary") {
              setResponse(data?.usageData);
              if (data?.usageData?.length > 0) {
                data?.usageData?.forEach((v: any, i: number) => {
                  if (
                    v?.recommended ||
                    (v?.isMerge && v?.isMerge?.length > 1)
                  ) {
                    setActive(i);
                    setPreview(v);
                    setRecommendPremium(v?.premium);
                  }
                });
              }
            }
          }
          setShow(false);
        } else {
          sessionStorage.setItem("birthdateCheck", JSON.stringify(false));
          ErrorToast("Invalid Birthdate!");
        }
        setLoading(false);
      })
      .catch((err) => {
        ErrorToast(err?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      <Toaster position="top-center" />
      <Transition appear show={show} as={Fragment}>
        <Dialog onClose={() => setShow(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30 z-[1000] flex items-center justify-center z-10">
              <Dialog.Panel className="max-w-[500px] max-h-[85vh] overflow-auto transform rounded-2xl bg-mintGreen mx-5 py-5 shadow-xl transition-all">
                <button
                  type="button"
                  className="box-content rounded-none border-none absolute right-[15px] top-[15px]"
                  onClick={() => setShow(false)}
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
                <div className="px-5">
                  <div className="text-darkBrown text-center text-[24px] font-medium w-[350px] xs:w-auto xs:w-[90vw] mx-auto">
                    Verification
                  </div>
                  <p className="mt-3 pr-3 text-center text-sm	text-darkBrown">
                    Please provide your date of birth to verify and resume your
                    session.
                  </p>
                  <form
                    className="mt-3 text-center text-darkBrown"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex flex-row xs:gap-0 gap-10 items-center">
                      <div className="font-medium text-lg mb-2 w-1/2 text-darkBrown text-start">
                        Birthdate
                      </div>
                      <input
                        type="text"
                        className={COVERAGE_INPUT_CLASSES}
                        placeholder="MM-DD-YYYY"
                        id="birthDateCheck"
                        maxLength={10}
                        name="birthdate"
                        value={newData1?.birthdate}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <NavButton
                      type="submit"
                      variant="lightBrown"
                      className="w-full justify-center"
                    >
                      Submit{" "}
                      {loading && (
                        <ArrowPathIcon
                          height={29}
                          width={20}
                          className="animate-spin ml-2"
                        />
                      )}
                    </NavButton>
                  </form>
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
