import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const CaptchaModal = (props: any) => {
  const { show, setShow, setApplyNowModal } = props;
  function handleRecaptchaChange(value: any) {
    if (value) {
      setTimeout(() => {
        setShow(false);
      }, 1000);
      setTimeout(() => {
        setApplyNowModal(true);
      }, 1500);
    }
  }
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog onClose={() => setShow(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30 z-[1000] flex items-center justify-center">
              <Dialog.Panel className="max-h-[85vh] overflow-auto transition-all">
                <ReCAPTCHA
                  sitekey="6LdA15cmAAAAAKkmwFd1gGqixEIZ3DvArSKKWCQD"
                  onChange={handleRecaptchaChange}
                />
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
