import { Dialog, Transition } from '@headlessui/react';
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import classNames from 'classnames';
import { Fragment, useEffect, useRef, useState } from 'react';
import { BaseURL } from '../../helpers/API/ApiData';
import { ErrorToast } from '../../helpers/Toast';
import { calculateAge } from '../../Pages/GetQuote';
import {
  cleanNumber,
  formatCurrency,
  formatCurrencyWithDecimals,
} from '../../utils/utils';
import { NavButton } from '../Button';

const COVERAGE_INPUT_CLASSES = classNames(
  'w-56',
  'xs:w-full',
  'font-medium',
  'text-lg',
  'outline-none',
  'h-7',
  'text-darkBrown',
  'px-2',
  'py-5',
  'outline-none',
  'mb-4',
  'ml-1',
  'focus:ring-0',
  'border-b-2',
  'border-darkBrown',
  'bg-transparent',
  'tracking-wider',
  'rounded-none'
);

export const useInactiveTimer = (timeout: number, onIdle: () => void) => {
  const timerRef = useRef<any | null>(null);

  useEffect(() => {
    const handleActivity = () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(onIdle, timeout);
    };

    window.addEventListener('touchstart', handleActivity);
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    handleActivity();

    return () => {
      window.removeEventListener('touchstart', handleActivity);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [timeout, onIdle]);
};

interface InactiveProps {
  show: boolean;
  setShow: (show: boolean) => void;
  urlReturnPage: string;
  preview: any;
  fromOnePageCalculator?: boolean;
  setIsContactFilled?: (a: any) => void | undefined;
}

export const InactiveModal = ({
  show,
  setShow,
  urlReturnPage,
  preview,
  fromOnePageCalculator,
  setIsContactFilled,
}: InactiveProps) => {
  const [newData1, setNewData1] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthdate: '',
  });
  const [loading, setLoading] = useState(false);

  const getQuoteFormData: any = localStorage.getItem('getQuoteFormData');
  const getParseQuoteFormData: any = JSON.parse(getQuoteFormData);
  const localNewData: any = getParseQuoteFormData?.newData;
  const birthdate = localNewData?.birthdate;

  const handleBirthdateChange = (e: any) => {
    let inputText = e.target.value;
    inputText = inputText.replace(/[^0-9]/g, '');
    if (inputText.length > 2) {
      inputText = inputText.slice(0, 2) + '-' + inputText.slice(2);
    }
    if (inputText.length > 5) {
      inputText = inputText.slice(0, 5) + '-' + inputText.slice(5);
    }
    if (inputText.length > 10) {
      inputText = inputText.slice(0, 10);
    }
    setNewData1({ ...newData1, birthdate: inputText });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewData1({ ...newData1, [name]: value?.trimStart() });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (fromOnePageCalculator) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'Lead',
        content_id: 'Lead',
      });
    }
    const formBirthdate = birthdate || newData1?.birthdate;
    const age = calculateAge(new Date(formBirthdate));

    if (age < 18) {
      return ErrorToast('Birthdate is Not Eligible');
    }

    setLoading(true);
    let sessionData: any = {
      firstName: newData1?.firstName,
      lastName: newData1?.lastName,
      email: newData1?.email,
      birthdate: formBirthdate,
    };

    sessionStorage.setItem('PersonalDetail', JSON.stringify(sessionData));
    const keys = Object.keys(sessionStorage);
    let sessionBody: any = {
      birthdate: formBirthdate,
    };

    await keys.forEach((key: any) => {
      if (key === 'sessionId') {
        return;
      }
      let item: any = sessionStorage.getItem(key);
      sessionBody[key] = JSON.parse(item);
    });

    await axios
      .post(BaseURL + '/user/add/session', sessionBody)
      .then(async (res) => {
        sessionStorage.setItem('sessionId', res?.data?.data?._id);
        const data11: any = {
          attributes: {
            smoker: getParseQuoteFormData?.newData?.smoker,
            state: getParseQuoteFormData?.newData?.state,
            gender: getParseQuoteFormData?.newData?.gender,
            health: getParseQuoteFormData?.newData?.health,
            firstName: newData1?.firstName,
            lastName: newData1?.lastName,
            sessionId: res?.data?.data?._id,
            birthDate: formBirthdate,
            websiteUrl:
              `https://www.explainlifeinsurance.com/${urlReturnPage}?session_id=` +
              res?.data?.data?._id,
          },
          identifiers: {
            email: newData1?.email,
          },
        };
        if (preview) {
          data11['attributes'] = {
            ...data11?.attributes,
            faceAmount: preview?.isMerge
              ? formatCurrency(
                  preview?.isMerge[0]?.face_amount +
                    preview?.isMerge[1]?.face_amount
                )
              : formatCurrency(preview?.face_amount),
            termLength: preview?.isMerge
              ? `${cleanNumber(
                  preview?.isMerge[0]?.product_category || '0'
                )} Year / ${cleanNumber(
                  preview?.isMerge[1]?.product_category || '0'
                )} Year`
              : `${cleanNumber(preview?.product_category || '0')} Year`,
            monthlyPremium: formatCurrencyWithDecimals(preview?.premium || 0),
          };
        }
        await axios
          .post(BaseURL + '/user/customerio', data11)
          .then((_response) => {
            setShow(false);
            if (setIsContactFilled) {
              setIsContactFilled(true);
            }
          })
          .catch((err) => {
            ErrorToast(err?.message);
          });
      })
      .catch((err) => ErrorToast(err?.message));
    setLoading(false);
  };

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          onClose={() => {
            setShow(false);
            if (setIsContactFilled) {
              setIsContactFilled(true);
            }
            localStorage.setItem('isShowModalAfterClose', JSON.stringify(true));
          }}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/30 z-[1000] flex items-center justify-center z-10'>
              <Dialog.Panel className='max-w-[500px] max-h-[85vh] overflow-auto transform rounded-2xl bg-mintGreen mx-5 py-5 shadow-xl transition-all'>
                <button
                  type='button'
                  className='box-content rounded-none border-none absolute right-[15px] top-[15px]'
                  onClick={() => {
                    setShow(false);
                    if (setIsContactFilled) {
                      setIsContactFilled(true);
                    }
                    localStorage.setItem(
                      'isShowModalAfterClose',
                      JSON.stringify(true)
                    );
                  }}
                >
                  <XMarkIcon className='w-6 h-6' />
                </button>
                <div className='px-5'>
                  <div className='text-darkBrown text-center text-[24px] font-medium w-[350px] xs:w-auto xs:w-[90vw] mx-auto'>
                    Finish At Your Convenience
                  </div>
                  <p className='pr-3 mt-3 text-sm text-center text-darkBrown'>
                    Enter your details below and we will send you a link to
                    complete your life insurance application at a time that
                    suits you.
                  </p>
                  <form
                    className='mt-3 text-center text-darkBrown'
                    onSubmit={handleSubmit}
                  >
                    <div className='flex flex-row items-center gap-10 xs:flex-col xs:gap-0'>
                      <div className='w-full mb-2 text-lg font-medium text-darkBrown text-start'>
                        First Name
                      </div>
                      <input
                        type='text'
                        className={COVERAGE_INPUT_CLASSES}
                        placeholder='Enter First Name'
                        name='firstName'
                        value={newData1?.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='flex flex-row items-center gap-10 xs:flex-col xs:gap-0'>
                      <div className='w-full mb-2 text-lg font-medium text-darkBrown text-start'>
                        Last Name
                      </div>
                      <input
                        type='text'
                        className={COVERAGE_INPUT_CLASSES}
                        placeholder='Enter Last Name'
                        name='lastName'
                        value={newData1?.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div
                      className={`flex flex-row xs:flex-col xs:gap-0 gap-10 items-center ${
                        birthdate && 'mb-3'
                      }`}
                    >
                      <div className='w-full mb-2 text-lg font-medium text-darkBrown text-start'>
                        Email Address
                      </div>
                      <input
                        type='email'
                        className={COVERAGE_INPUT_CLASSES}
                        placeholder='Enter Email'
                        name='email'
                        value={newData1?.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    {!birthdate && (
                      <div className='flex flex-row items-center gap-10 mb-3 xs:flex-col xs:gap-0'>
                        <div className='w-full mb-2 text-lg font-medium text-darkBrown text-start'>
                          Birthdate
                        </div>
                        <input
                          type='text'
                          className={COVERAGE_INPUT_CLASSES}
                          placeholder='MM-DD-YYYY'
                          maxLength={10}
                          name='birthdate'
                          id='birthdate'
                          value={newData1?.birthdate}
                          onChange={handleBirthdateChange}
                          required
                        />
                      </div>
                    )}
                    <NavButton
                      type='submit'
                      variant='lightBrown'
                      className='justify-center w-full text-base'
                      disabled={loading}
                    >
                      Submit{' '}
                      {loading && (
                        <ArrowPathIcon
                          height={29}
                          width={20}
                          className='ml-2 animate-spin'
                        />
                      )}
                    </NavButton>
                  </form>
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
