import { useState } from "react";

const ArrData: any = [
  {
    starNum: 1,
    starInfo:
      "Individuals with serious but managed health conditions like cancer or diabetes, or individuals with both parents passing away before the age of 70",
  },
  {
    starNum: 2,
    starInfo:
      "Individuals in fair health. They might have a few health conditions, could be overweight or have a family history of health problems. Occasional non-cigarette usage might be acceptable.",
  },
  {
    starNum: 3,
    starInfo:
      "Individuals who are in good health but might be facing a few minor health issues or might be slightly overweight. They may also have a less than ideal family health history. No recent tobacco usage is often a criterion.",
  },
  {
    starNum: 4,
    starInfo:
      "Individuals in very good health but with minor health issues. No tobacco usage for the past three years is often a criterion.",
  },
  {
    starNum: 5,
    starInfo:
      "Individuals in excellent health with an ideal height-to-weight ratio, and a family history clear of serious illnesses. No tobacco usage for the past five years is often a criterion. Controlled blood pressure and cholesterol may still qualify.",
  },
];

export default function StarRatingCustom({ rating, onRatingChange }: any) {
  const [isHover, setIsHover] = useState(0);

  const handleMouseEnter = (ind: any) => {
    setIsHover(ind + 1);
  };

  const handleMouseLeave = () => {
    setIsHover(0);
  };

  return (
    <div>
      <ul className="flex">
        {ArrData.map((v: any, i: any) => (
          <li
            onMouseEnter={() => handleMouseEnter(i)}
            onMouseLeave={handleMouseLeave}
            key={i}
            className={`star relative cursor-pointer ${
              isHover
                ? isHover >= v?.starNum
                  ? "hoverStarDark"
                  : "hoverStarLight"
                : rating && rating >= v?.starNum
                ? "hoverStarDark"
                : "hoverStarLight"
            }`}
            onClick={() => onRatingChange(v?.starNum)}
          >
            <svg
              viewBox="0 0 24 24"
              className="widget-svg transition-all duration-300"
              width={25}
            >
              <path
                className="star"
                d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
              ></path>
            </svg>
            {isHover === v?.starNum && (
              <div className="absolute bottom-[30px] left-[-150px] lg:left-[-270px] xs:left-[-190px] rounded-lg p-3 w-[300px] xs:w-[220px] text-sm text-left bg-mintGreen">
                {v?.starInfo}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
