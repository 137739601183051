import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";

export const NegativeQuoteModal = (props: any) => {
  const { show, setShow } = props;
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog onClose={() => setShow(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30 z-[1000] flex items-center justify-center z-10">
              <Dialog.Panel className="max-w-[500px] max-h-[85vh] overflow-auto transform rounded-2xl bg-mintGreen mx-5 py-5 shadow-xl transition-all">
                <button
                  type="button"
                  className="box-content rounded-none border-none absolute right-[15px] top-[15px]"
                  onClick={() => setShow(false)}
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
                <div className="px-5">
                  <h2 className="text-darkBrown text-center text-[24px] font-medium w-[350px] xs:w-auto xs:w-[90vw] mx-auto">
                    Uh Oh!
                  </h2>
                  <div className="mt-3 pr-3 text-center text-base	text-darkBrown">
                    <p>
                      It appears that our calculations indicate that you might
                      not be a good candidate for term life insurance. Please
                      verify your answers are accurate, and if you re-calculate
                      and still receive this notification, please reach out to
                      our team of experts directly at{" "}
                    </p>
                    <a
                      href="tel:(856) 529-5155"
                      className="flex w-full gap-2 font-semibold justify-center"
                    >
                      (856) 529-5155.
                    </a>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
