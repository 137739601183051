import { data } from "./data";
import Slider from "react-slick";

export const LogoCarousel = () => {
  const settings = {
    centerPadding: "0px",
    slidesToShow: 5,
    arrows: false,
    dots: false,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 3000,
    speed: 2000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {data.resources.map((resource, index) => {
          return (
            <div key={index} className="text-center relative snap-start">
              <div className="aspect-square cursor-default bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0 grid place-items-center mx-auto w-[50%] xs:w-[75%]">
                <img src={resource.imageUrl} className="grayscale hover:grayscale-0 opacity-50 hover:opacity-100 duration-500" alt="logo" />
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
};
