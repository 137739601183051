import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../Components/Footer";
import { NavBar } from "../Components/NavBar";

export const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-lightBrown">
      <Helmet>
        <title>Terms & Conditions | ExplainLife</title>
        <meta
          name="description"
          content="Terms and conditions for using  ExplainLife website. Copyrights, warranties, liability, privacy and security for explainlifeinsurance.com."
        />
        <meta property="og:title" content="Terms & Conditions | ExplainLife" />
        <meta
          property="og:description"
          content="Terms and conditions for using  ExplainLife website. Copyrights, warranties, liability, privacy and security for explainlifeinsurance.com."
        />
      </Helmet>
      <NavBar />
      <iframe
        src="https://app.termly.io/document/terms-of-service/419a15c4-a44c-464e-b078-5239de0e6d53"
        style={{
          width: "100%",
          marginTop: "87px",
          height: "calc(100vh - 87px)",
        }} // Subtract the heights of your NavBar and Footer
        title="Privacy Policy"
      />
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};
