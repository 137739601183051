import { useEffect, useState } from 'react';
import { AiOutlineCaretRight } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { mouseDown, mouseUp } from '../Components/Button';
import { NavBar } from '../Components/NavBar';
import lincoln from '../media/img/Lincoln.png';
import {
  cleanNumber,
  formatCurrency,
  formatCurrencyWithDecimals,
} from '../utils/utils';
import { CoverageExplanationModal } from '../Components/Modals/CoverageExplanationModal';
import { ApplyNowModal } from '../Components/Modals/ApplyNowModal';
import { RecommendationExplanationModal } from '../Components/Modals/RecommendationExplanationModal';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { BirthCheckModal } from '../Components/Modals/BirthCheckModal';
import {
  InactiveModal,
  useInactiveTimer,
} from '../Components/Modals/InactiveModal';
import { EmailThanksModal } from '../Components/Modals/EmailThanksModal';
import axios from 'axios';
import { BaseURL } from '../helpers/API/ApiData';
import { ErrorToast } from '../helpers/Toast';
import { CaptchaModal } from '../Components/Modals/CaptchaModal';

export default function QuoteSummary() {
  const navigate = useNavigate();
  const [active, setActive] = useState<any>(0);
  const [response, setResponse] = useState<any>([]);
  const [recResponse, setRecResponse] = useState<any>({});
  const [preview, setPreview] = useState<any>({});
  const [modal, setModal] = useState(false);
  const [captchaModal, setCaptchaModal] = useState(false);
  const [applyNowModal, setApplyNowModal] = useState(false);
  const [recommendationExplanationModal, setRecommendationExplanationModal] =
    useState(false);
  const [back9Url, setBack9Url] = useState('');
  const [emailThanksModal, setEmailThanksModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [recommendPremium, setRecommendPremium] = useState(0);
  const [birthCheckModal, setBirthCheckModal] = useState(false);
  const [isSplitApply, setIsSplitApply] = useState(false);
  const LocalUsageData: any = localStorage.getItem('usageData');
  const parseLocalData: any = JSON.parse(LocalUsageData);
  const sessionId = new URLSearchParams(window.location.search).get(
    'session_id'
  );
  const session_personalDetail = sessionStorage.getItem('PersonalDetail');
  const session_fromSession = sessionStorage.getItem('fromSession');
  const session_birthdateCheck: any = sessionStorage.getItem('birthdateCheck');
  const a: any = localStorage.getItem('isShowModalAfterClose');
  const localIsShowModalAfterClose = JSON.parse(a);
  useInactiveTimer(10000, () => {
    if (
      !birthCheckModal &&
      !sessionId &&
      !session_personalDetail &&
      !session_fromSession &&
      !back9Url &&
      !localIsShowModalAfterClose &&
      !captchaModal &&
      !applyNowModal
    ) {
      return setEmailModal(true);
    }
  });

  const goBack = () => {
    if (sessionId) {
      navigate('/term-life-insurance-quote?session_id=' + sessionId);
    } else {
      navigate('/term-life-insurance-quote');
    }
  };

  const applyNowBtn = async () => {
    // setApplyNowModal(true);
    setCaptchaModal(true);
  };

  const getUsageSessionData = async () => {
    await axios
      .get(BaseURL + '/user/get/session/' + sessionId)
      .then((res) => {
        const data: any = res?.data?.data;
        if (data?.usageData && data?.usageData?.length > 0) {
          setResponse(data?.usageData);
          if (data?.usageData?.length > 0) {
            data?.usageData?.forEach((v: any, i: number) => {
              if (v?.recommended || (v?.isMerge && v?.isMerge?.length > 1)) {
                setActive(i);
                setPreview(v);
                setRecommendPremium(v?.premium);
                setRecResponse(v);
              }
            });
          }
        }
      })
      .catch((err) => {
        ErrorToast(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (sessionId) {
      localStorage.clear();
      sessionStorage.setItem('sessionId', sessionId);
      sessionStorage.setItem('fromSession', JSON.stringify(true));
      if (!JSON.parse(session_birthdateCheck)) {
        return setBirthCheckModal(true);
      } else {
        getUsageSessionData();
      }
    } else if (parseLocalData) {
      setResponse(parseLocalData);
      if (parseLocalData?.length > 0) {
        parseLocalData?.forEach((v: any, i: number) => {
          if (v?.recommended || (v?.isMerge && v?.isMerge?.length > 1)) {
            setActive(i);
            setPreview(v);
            setRecommendPremium(v?.premium);
            setRecResponse(v);
          }
        });
      }
    } else {
      navigate('/term-life-insurance-quote');
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ViewContent',
      content_id: 'QuoteSummary',
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Your Term Life Insurance Quote | ExplainLife</title>
        <meta
          name='description'
          content='We are pleased to present your term life insurance quote based on your individual circumstances and desired coverage.'
        />
        <meta
          property='og:title'
          content='Your Term Life Insurance Quote | ExplainLife'
        />
        <meta
          property='og:description'
          content='We are pleased to present your term life insurance quote based on your individual circumstances and desired coverage.'
        />
      </Helmet>
      <NavBar />
      <div
        className='flex flex-col items-center justify-center w-full min-h-screen pt-10 duration-500 opacity-100 bg-lightBrown'
        id='quoteSummaryAnimation'
      >
        <nav
          aria-label='Pagination'
          className='fixed bottom-0 right-0 z-50 flex flex-col gap-2 mb-6 ml-auto mr-6 sm:mr-2'
        >
          <button
            onClick={goBack}
            className='flex items-center justify-center w-8 h-8 text-white rounded-md bg-darkBrown'
          >
            <ChevronUpIcon className='w-4 h-4 text-lightBrown' />
          </button>
          <button
            className='flex items-center justify-center w-8 h-8 text-white bg-gray-300 rounded-md'
            disabled={true}
          >
            <ChevronDownIcon className='w-4 h-4' />
          </button>
        </nav>
        <div className='flex flex-row lg:flex-col mb-10 gap-24 xs:gap-10 mt-16 lg:w-[90vw] xs:w-[95vw] w-[950px] justify-between'>
          <div className='flex flex-col justify-center w-full'>
            <div
              className='text-darkBrown/[.5] font-medium mb-3 cursor-pointer inline-block'
              onClick={() => {
                document
                  .getElementById('quoteSummaryAnimation')
                  ?.classList.remove('opacity-100');
                document
                  .getElementById('quoteSummaryAnimation')
                  ?.classList.add('opacity-0');
                setTimeout(() => {
                  if (sessionId) {
                    navigate('/calculation-summary?session_id=' + sessionId);
                  } else {
                    navigate('/calculation-summary');
                  }
                }, 500);
              }}
            >
              How much life insurance do I need ? ={' '}
              {response.length > 0 &&
                formatCurrency(
                  response[0]?.isMerge
                    ? response[0]?.isMerge[0]?.face_amount
                    : response[0]?.face_amount || 0
                )}
            </div>
            <div className='text-darkBrown font-medium text-2xl flex ml-[-50px] mb-2'>
              <span className='mr-5 xs:'>
                <AiOutlineCaretRight fontSize={30} />
              </span>
              How much will it cost ?
            </div>
            <div className='mb-3 text-sm text-darkBrown'>
              <span className='font-semibold'>Note</span>: Please select your
              desired plan
            </div>
            <table className='w-full tracking-wide'>
              <thead>
                <tr className='border-b-8 border-lightBrown'>
                  <th className='font-semibold'>Term Length</th>
                  <th className='font-semibold'>My Coverage</th>
                  <th className='font-semibold'>Premium</th>
                </tr>
              </thead>
              <tbody className='font-medium text-center text-darkBrown'>
                {response?.length > 0 &&
                  response?.map((item: any, index: number) => {
                    if (item?.isMerge && item?.isMerge?.length > 1) {
                      return (
                        <>
                          <tr key={index}>
                            <td
                              colSpan={3}
                              onClick={() => {
                                setPreview(item);
                                setActive(index);
                              }}
                            >
                              <div
                                className={`bg-[#DCD6D3] text-darkBrown cursor-pointer rounded-t-lg text-xs xs:py-[6px] text-start text-normal py-1 p-3`}
                              >
                                <b>My Recommendation: </b>Two Overlapping
                                Policies Totaling{' '}
                                {formatCurrency(
                                  response[0]?.isMerge
                                    ? response[0]?.isMerge[0]?.face_amount
                                    : response[0]?.face_amount || 0
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr
                            className='relative border-b-8 cursor-pointer border-lightBrown quoteHoverMain'
                            onClick={() => {
                              setPreview(item);
                              setActive(index);
                            }}
                          >
                            <td>
                              <div className='relative'>
                                <div
                                  className={`pb-[5px] pl-[5px] bg-[#DCD6D3] rounded-bl-lg`}
                                >
                                  <div
                                    className={`${
                                      active === index
                                        ? 'bg-darkBrown text-lightBrown'
                                        : 'bg-mintGreen'
                                    } hoverInnerChangeClass transition-all border-[#DCD6D3] py-2 rounded-tl-lg border-b-4`}
                                  >
                                    {item?.isMerge[0]?.product_category?.split(
                                      ' '
                                    )[0] || '0'}{' '}
                                    years
                                  </div>
                                  <div
                                    className={`${
                                      active === index
                                        ? 'bg-darkBrown text-lightBrown'
                                        : 'bg-mintGreen'
                                    } hoverInnerChangeClass transition-all rounded-bl-lg py-2`}
                                  >
                                    {item?.isMerge[1]?.product_category?.split(
                                      ' '
                                    )[0] || '0'}{' '}
                                    years
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className={`bg-[#DCD6D3] pb-[5px]`}>
                                <div
                                  className={`${
                                    active === index
                                      ? 'bg-darkBrown text-lightBrown'
                                      : 'bg-mintGreen'
                                  } hoverInnerChangeClass transition-all border-[#DCD6D3] pb-2 pt-2 border-b-4 border-r-4`}
                                >
                                  {formatCurrency(
                                    item?.isMerge[0]?.face_amount || '0'
                                  )}
                                </div>
                                <div
                                  className={`${
                                    active === index
                                      ? 'bg-darkBrown text-lightBrown'
                                      : 'bg-mintGreen'
                                  } hoverInnerChangeClass transition-all border-[#DCD6D3] py-2 border-r-4`}
                                >
                                  {formatCurrency(
                                    item?.isMerge[1]?.face_amount || '0'
                                  )}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`bg-[#DCD6D3] pb-[5px] pr-[5px] rounded-r-lg`}
                              >
                                <div
                                  className={`${
                                    active === index
                                      ? 'bg-darkBrown text-lightBrown relative'
                                      : 'bg-mintGreen'
                                  } hoverInnerChangeClass transition-all rounded-r-lg px-3 xs:px-1 py-[30px]`}
                                >
                                  {formatCurrencyWithDecimals(
                                    item?.premium || '0'
                                  )}
                                  /month*
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    } else if (item && !item?.isMerge) {
                      return (
                        <>
                          {item?.recommended && (
                            <tr key={index}>
                              <td
                                colSpan={3}
                                onClick={() => {
                                  setPreview(item);
                                  setActive(index);
                                }}
                              >
                                <div
                                  className={`bg-[#DCD6D3] text-darkBrown cursor-pointer rounded-t-lg text-sm xs:py-[6px] text-start text-normal py-1 p-3`}
                                >
                                  <b>My Recommendation </b>
                                </div>
                              </td>
                            </tr>
                          )}
                          <tr
                            className='relative border-b-8 cursor-pointer border-lightBrown quoteHoverMain'
                            onClick={() => {
                              setPreview(item);
                              setActive(index);
                            }}
                          >
                            <td>
                              <div className='relative'>
                                <div
                                  className={`${
                                    item?.recommended &&
                                    'pb-[5px] pl-[5px] bg-[#DCD6D3] rounded-bl-lg'
                                  }`}
                                >
                                  <div
                                    className={`${
                                      active === index
                                        ? 'bg-darkBrown text-white font-medium'
                                        : item?.recommended
                                        ? 'bg-mintGreen'
                                        : 'bg-darkBrown/[.1]'
                                    } hoverInnerChangeClass transition-all rounded-l-lg py-5`}
                                  >
                                    {item?.product_category.split(' ')[0]} years
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`${
                                  item?.recommended && 'bg-[#DCD6D3] pb-[5px]'
                                }`}
                              >
                                <div
                                  className={`${
                                    active === index
                                      ? 'bg-darkBrown text-white font-medium'
                                      : item?.recommended
                                      ? 'bg-mintGreen'
                                      : 'bg-darkBrown/[.1]'
                                  } hoverInnerChangeClass transition-all py-5`}
                                >
                                  {formatCurrency(item?.face_amount || '0')}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`${
                                  item?.recommended &&
                                  'bg-[#DCD6D3] pb-[5px] pe-[5px] rounded-br-lg'
                                }`}
                              >
                                <div
                                  className={`${
                                    active === index
                                      ? 'bg-darkBrown text-white font-medium'
                                      : item?.recommended
                                      ? 'bg-mintGreen'
                                      : 'bg-darkBrown/[.1]'
                                  } hoverInnerChangeClass transition-all px-3 xs:px-1 rounded-r-lg py-5`}
                                >
                                  {formatCurrencyWithDecimals(
                                    item?.premium || '0'
                                  )}
                                  /month
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    }
                    return null;
                  })}
              </tbody>
            </table>
            {!recResponse?.recommended && (
              <div className='flex pt-1 pl-4 text-xs text-darkBrown'>
                * For the first{' '}
                {recResponse?.isMerge && recResponse?.isMerge?.length > 1
                  ? cleanNumber(
                      recResponse?.isMerge[0]?.product_category || '0'
                    )
                  : cleanNumber(recResponse?.product_category || '0')}{' '}
                years, the monthly premium will be{' '}
                {formatCurrencyWithDecimals(recResponse?.premium || 0)} and then
                it will decrease to{' '}
                {recResponse?.isMerge && recResponse?.isMerge?.length > 1
                  ? formatCurrencyWithDecimals(
                      recResponse?.isMerge[1]?.premium || 0
                    )
                  : '1/3 policy premium'}{' '}
                for the remaining{' '}
                {recResponse?.isMerge && recResponse?.isMerge?.length > 1
                  ? cleanNumber(
                      recResponse?.isMerge[1]?.product_category || '0'
                    ) -
                    cleanNumber(
                      recResponse?.isMerge[0]?.product_category || '0'
                    )
                  : cleanNumber(recResponse?.product_category || '0')}{' '}
                years
              </div>
            )}
          </div>
          {preview?.isMerge ? (
            <div className='flex items-center w-full'>
              <div>
                <div className='mb-5 text-2xl font-medium'>
                  {formatCurrencyWithDecimals(preview?.premium || 0)} per month*
                </div>
                <div className='mb-5 text-sm'>
                  <p>
                    We recommend a <b>custom-tailored, two-policy approach</b>{' '}
                    to your coverage:
                  </p>
                  <ul className='pl-5 mb-3 list-disc'>
                    <li className='mt-2'>
                      A{' '}
                      <b>
                        {cleanNumber(preview?.isMerge[0]?.product_category)}
                        -year policy
                      </b>
                      : During the most financially demanding years, this
                      provides ample coverage for raising a family while also
                      growing your savings.
                    </li>
                    <li className='mt-2'>
                      A{' '}
                      <b>
                        {cleanNumber(preview?.isMerge[1]?.product_category)}
                        -year policy
                      </b>
                      : Begins in parallel with your{' '}
                      {cleanNumber(preview?.isMerge[0]?.product_category)}-year
                      policy. As your savings increase and financial obligations
                      decrease, this policy continues to provide coverage.
                    </li>
                  </ul>
                  <p>
                    This strategy ensures{' '}
                    <b>
                      overlapping coverage during the initial{' '}
                      {cleanNumber(preview?.isMerge[0]?.product_category)} years
                    </b>
                    , adapting to your evolving financial landscape and
                    providing peace of mind at each stage.
                  </p>
                </div>
                <div className='flex justify-between gap-3 mb-4 xs:flex-col'>
                  <div className='w-full'>
                    <button
                      className='w-full px-8 py-3 text-center text-white rounded-full cursor-pointer bg-darkBrown xs:px-4'
                      onClick={() => setRecommendationExplanationModal(true)}
                    >
                      Explain{' '}
                      <i className='text-xl font-bold font-judson_italic'>
                        my{' '}
                      </i>
                      recommendation
                    </button>
                  </div>
                  <div className='w-full'>
                    <button
                      className='w-full h-full py-3 text-center text-white rounded-full cursor-pointer bg-darkBrown xs:text-base'
                      onClick={() => setModal(true)}
                    >
                      Explain{' '}
                      <i className='text-xl font-bold font-judson_italic'>
                        my{' '}
                      </i>
                      coverage
                    </button>
                  </div>
                </div>
                <button
                  onPointerDown={mouseDown}
                  onPointerUp={mouseUp}
                  onPointerLeave={mouseUp}
                  onMouseDown={mouseDown}
                  onMouseUp={mouseUp}
                  onMouseLeave={mouseUp}
                  className='inline-block w-full text-lg bg-mintGreen py-[8px] rounded-full font-medium shadow-[4px_4px_0px_#3C2C28] border-2 border-darkBrown ease-in duration-75'
                  onClick={() => {
                    applyNowBtn();
                    if (preview?.isMerge) {
                      setIsSplitApply(true);
                    }
                  }}
                >
                  <span className='text-black'>Apply now</span>
                </button>
                <div className='flex flex-row items-center gap-1 pl-5 mt-8 lg:mb-8 xs:pl-0 xs:flex-col xs:gap-0'>
                  <div>
                    <div className='font-medium md:text-center'>
                      Provided by
                    </div>
                    <div>
                      <img
                        src={preview?.isMerge[0]?.carrier?.avatar || lincoln}
                        className='w-[120px] object-cover'
                        alt=''
                      />
                    </div>
                  </div>
                  <div>
                    <ul className='pl-[30px] xs:pl-0 xs:text-center xs:mt-3 list-disc xs:list-none'>
                      <li>
                        AM Best Rating:{' '}
                        <strong>
                          {preview?.isMerge[0]?.carrier?.am_best_rating || '-'}
                        </strong>
                      </li>
                      <li>
                        Physical Exam Required:{' '}
                        <strong>
                          {preview?.isMerge[0]?.exam_details?.required ===
                            true &&
                          preview?.isMerge[1]?.exam_details?.required === true
                            ? 'Yes'
                            : 'Possibly'}
                        </strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex items-center w-full'>
              <div className='inline-block mt-14 lg:mt-0'>
                <div className='mb-5 text-2xl font-medium'>
                  {formatCurrencyWithDecimals(preview?.premium || 0)} per month
                </div>
                {preview?.premium < recommendPremium ? (
                  <p className='mb-5 text-sm'>
                    This plan does <b>not meet your family's needs</b>. For an
                    additional{' '}
                    <b>
                      {response.length > 0 &&
                        formatCurrencyWithDecimals(
                          recommendPremium - preview?.premium || 0
                        )}{' '}
                      per month
                    </b>
                    , you can secure enough coverage to protect your family.
                    While we understand the desire to save money today, our
                    custom-tailored, life insurance recommendation strikes the{' '}
                    <b>perfect balance</b>, catering to your family's present
                    and future needs. This small increase in monthly payments
                    today will provide <b>peace of mind</b> and ensure financial
                    resilience for your loved ones in the future.
                  </p>
                ) : preview?.premium > recommendPremium ? (
                  <p className='mb-5 text-sm'>
                    You may not <b>need to spend this much!</b> By choosing our
                    custom-tailored, recommended life insurance plan, you could{' '}
                    <b>
                      save{' '}
                      {response.length > 0 &&
                        formatCurrencyWithDecimals(
                          preview?.premium - recommendPremium || 0
                        )}{' '}
                      per month
                    </b>
                    . While we understand the desire to over-insure, our
                    recommendation strikes the <b>perfect balance</b>, catering
                    to your family's present and future necessities, without the
                    risk of over-insurance.
                  </p>
                ) : (
                  <p className='mb-5 text-sm'>
                    We{' '}
                    <b>
                      suggest a{' '}
                      {response?.length > 0 &&
                        cleanNumber(preview?.product_category || 0)}
                      -year policy
                    </b>{' '}
                    because we expect that most financially challenging years
                    are behind you and now you have the opportunity to{' '}
                    <b>bolster your savings</b>. While longer-term policies are
                    available, we're confident that this plan{' '}
                    <b>hits the sweet spot</b>, offering substantial protection
                    for your family without the risk of over-insurance.
                  </p>
                )}
                <div className='flex justify-between gap-3 mb-4 xs:flex-col'>
                  <div className='w-full'>
                    <button
                      className='w-full px-8 py-3 text-center text-white rounded-full cursor-pointer bg-darkBrown xs:px-4'
                      onClick={() => setRecommendationExplanationModal(true)}
                    >
                      Explain{' '}
                      <i className='text-xl font-bold font-judson_italic'>
                        my{' '}
                      </i>
                      recommendation
                    </button>
                  </div>
                  <div className='w-full'>
                    <button
                      className='w-full h-full py-3 text-center text-white rounded-full cursor-pointer bg-darkBrown xs:text-base'
                      onClick={() => setModal(true)}
                    >
                      Explain{' '}
                      <i className='text-xl font-bold font-judson_italic'>
                        my{' '}
                      </i>
                      coverage
                    </button>
                  </div>
                </div>
                <button
                  onPointerDown={mouseDown}
                  onPointerUp={mouseUp}
                  onPointerLeave={mouseUp}
                  onMouseDown={mouseDown}
                  onMouseUp={mouseUp}
                  onMouseLeave={mouseUp}
                  className='inline-block w-full text-lg bg-mintGreen py-[8px] rounded-full font-medium shadow-[4px_4px_0px_#3C2C28] border-2 border-darkBrown ease-in duration-75'
                  onClick={() => {
                    applyNowBtn();
                    setIsSplitApply(false);
                  }}
                >
                  <span className='text-black'>Apply now</span>
                </button>
                <div className='flex flex-row items-center gap-1 pl-5 my-8 xs:pl-0 xs:flex-col'>
                  <div>
                    <div className='font-medium md:text-center'>
                      Provided by
                    </div>
                    <div>
                      <img
                        src={preview?.carrier?.avatar || lincoln}
                        className='w-[120px] object-cover'
                        alt='avatar'
                      />
                    </div>
                  </div>
                  <div>
                    <ul className='pl-[30px] xs:pl-0 xs:text-center xs:mt-3 list-disc xs:list-none'>
                      <li>
                        AM Best Rating:{' '}
                        <strong>
                          {preview?.carrier?.am_best_rating || '-'}
                        </strong>
                      </li>
                      <li>
                        Physical Exam Required:{' '}
                        <strong>
                          {preview?.exam_details?.required ? 'Yes' : 'Possibly'}
                        </strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <CoverageExplanationModal
        show={modal}
        setShow={setModal}
        response={response}
      />
      <RecommendationExplanationModal
        show={recommendationExplanationModal}
        setShow={setRecommendationExplanationModal}
        recResponse={recResponse}
      />
      <CaptchaModal
        show={captchaModal}
        setShow={setCaptchaModal}
        setApplyNowModal={setApplyNowModal}
      />
      <ApplyNowModal
        show={applyNowModal}
        setShow={setApplyNowModal}
        preview={preview}
        setBack9Url={setBack9Url}
        isSplitApply={isSplitApply}
      />
      <InactiveModal
        show={emailModal}
        preview={preview}
        setShow={setEmailModal}
        urlReturnPage='quotes-summary'
      />
      <EmailThanksModal show={emailThanksModal} setShow={setEmailThanksModal} />
      {sessionId && (
        <BirthCheckModal
          show={birthCheckModal}
          setShow={setBirthCheckModal}
          sessionId={sessionId}
          setResponse={setResponse}
          setActive={setActive}
          setPreview={setPreview}
          setRecommendPremium={setRecommendPremium}
        />
      )}
    </>
  );
}
