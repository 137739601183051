import axios from 'axios';
import { Fragment, useContext, useEffect, useState } from 'react';
import { BaseURL } from '../helpers/API/ApiData';
import { ErrorToast } from '../helpers/Toast';
import { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { NavBar } from '../Components/NavBar';
import { mouseDown, mouseUp } from '../Components/Button';
import { AiOutlineCaretRight, AiOutlineMinusCircle } from 'react-icons/ai';
import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  ArrowPathIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid';
import { formatCurrency } from '../utils/utils';
import { SmallInfoTip } from '../Components/InfoTip';
import { EnterCoverageModal } from '../Components/Modals/EnterCoverageModal';
import { NoQuoteModal } from '../Components/Modals/NoQuoteModal';
import { NegativeQuoteModal } from '../Components/Modals/NegativeQuoteModal';
import { CalculatorFormContext } from './Calculator/context';
import StarRatingCustom from '../Components/StarRatingCustom';
import {
  InactiveModal,
  useInactiveTimer,
} from '../Components/Modals/InactiveModal';
import { EmailThanksModal } from '../Components/Modals/EmailThanksModal';
import { BirthCheckModal } from '../Components/Modals/BirthCheckModal';

export const calculateAge = (birthdate: Date): number => {
  const birthYear = birthdate.getFullYear();
  const birthMonth = birthdate.getMonth();
  const birthDay = birthdate.getDate();
  const today: Date = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth();
  const todayDay = today.getDate();
  let age = todayYear - birthYear;

  if (
    todayMonth < birthMonth ||
    (todayMonth === birthMonth && todayDay < birthDay)
  ) {
    age--;
  }

  return age;
};

export default function GetQuote() {
  let dateInput: any = document.getElementById('birthdate');
  let getQuoteFormData: any = localStorage.getItem('getQuoteFormData');
  const a: any = localStorage.getItem('isShowModalAfterClose');
  const localIsShowModalAfterClose = JSON.parse(a);
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [getFormQuoteDataState, setGetFormQuoteDataState] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [noQuoteModal, setNoQuoteModal] = useState(false);
  const [negativeQuoteModal, setNegativeQuoteModal] = useState(false);
  const [faceAmount, setFaceAmount] = useState(false);
  const [stateData, setStateData] = useState<any>([]);
  const [isUpdateValue, setIsUpdateValue] = useState(false);
  const [isState, setIsState] = useState(true);
  const [selectedState, setSelectedState] = useState<any>({
    stateName: formData?.stateName || 'Select',
  });

  const [newData, setNewData] = useState<any>({
    gender: 'Male',
    birthdate: '',
    state: '',
    health: 0,
    smoker: 'Never',
  });
  const [inputFields, setInputFields] = useState<any>([
    {
      category: '',
      frequency: '',
      last_use_date: '',
    },
  ]);
  const [emailThanksModal, setEmailThanksModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const smokeType: any = [
    'Cigarettes',
    'eCigarettes',
    'Cigars',
    'Pipe',
    'Chew',
    'Cannabis',
  ];
  const smokeFrequency: any = [
    { label: '1-2 times/year', value: 2 },
    { label: 'Monthly', value: 12 },
    { label: 'Weekly', value: 52 },
    { label: 'Daily', value: 365 },
  ];

  const [birthCheckModal, setBirthCheckModal] = useState(false);
  const session_personalDetail = sessionStorage.getItem('PersonalDetail');
  const session_fromSession = sessionStorage.getItem('fromSession');
  const sessionId = new URLSearchParams(window.location.search).get(
    'session_id'
  );
  const session_birthdateCheck: any = sessionStorage.getItem('birthdateCheck');
  const local_isShowModal: any = localStorage.getItem('isShowModal');

  useInactiveTimer(10000, () => {
    if (
      !birthCheckModal &&
      !sessionId &&
      !session_personalDetail &&
      !session_fromSession &&
      !JSON.parse(local_isShowModal) &&
      !localIsShowModalAfterClose
    ) {
      return setEmailModal(true);
    }
  });

  const getState = async () => {
    await axios
      .get(BaseURL + '/user/get/state')
      .then((res: any) => {
        setStateData(res?.data?.data);
      })
      .catch((err: any) => ErrorToast(err?.message));
  };

  const handleBirthDateChange = () => {
    let inputText = dateInput.value;
    inputText = inputText.replace(/\D/g, '');
    if (inputText.length > 2) {
      inputText = inputText.slice(0, 2) + '-' + inputText.slice(2);
    }
    if (inputText.length > 5) {
      inputText = inputText.slice(0, 5) + '-' + inputText.slice(5);
    }
    dateInput.value = inputText;
    setNewData({ ...newData, birthdate: inputText });
    setIsUpdateValue(true);
  };

  const addFields = () => {
    let newField = {
      category: '',
      frequency: '',
      last_use_date: '',
    };
    setInputFields([...inputFields, newField]);
  };

  const removeInputField = (index: number) => {
    const data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const handleSubmit = async () => {
    let faceNum: any = newData?.face_amounts
      ? newData?.face_amounts?.replace(/[$,]/g, '')
      : '0';
    faceNum = Number(faceNum);
    let recommend20Face: number = 0;
    let recommend30Face: number = 0;
    let date2;
    let formatChange: any = '';
    let changedDate: any = '';
    let arr: any = [];
    let emptyType = false;
    let emptyFreq = false;
    let emptyLastUseDate = false;

    if (!faceNum) {
      setModal(true);
      setFaceAmount(false);
      return;
    } else if (!dateInput.value) {
      return ErrorToast('Birthdate is required');
    } else if (newData?.health === 0) {
      return ErrorToast('Health is required');
    } else if (!formData?.stateName) {
      return ErrorToast('State is Required');
    }
    if (dateInput.value.length < 10) {
      return ErrorToast('Birthdate is invalid');
    } else {
      formatChange = dateInput.value.split('-');
      changedDate = `${formatChange[2]}-${formatChange[0]}-${formatChange[1]}`;
      date2 = new Date(changedDate);
      const age = calculateAge(date2);
      if (age < 18) {
        return ErrorToast('Birthdate is Not Eligible');
      }
    }
    if (faceNum < 0) {
      return setNegativeQuoteModal(true);
    }
    if (inputFields?.length > 0 && newData?.smoker !== 'Never') {
      if (newData?.smoker === 'Currently') {
        await inputFields?.map((v: any, i: any) => {
          if (!v?.category) {
            emptyType = true;
          }
          if (!v?.frequency) {
            emptyFreq = true;
          }
          arr.push({ ...v, last_use_date: '', status: newData?.smoker });
          return false;
        });
      } else {
        await inputFields?.map((v: any, i: any) => {
          let last_use_date: any;
          if (!v?.category) {
            emptyType = true;
          }
          if (!v?.frequency) {
            emptyFreq = true;
          }
          if (!v?.last_use_date) {
            emptyLastUseDate = true;
          } else {
            const formatChange: any = v?.last_use_date.split('-');
            last_use_date = `${formatChange[2]}-${formatChange[0]}-${formatChange[1]}`;
          }
          arr.push({ ...v, status: newData?.smoker, last_use_date });
          return false;
        });
      }
    }
    if (emptyType) {
      return ErrorToast('Smoke Type is required');
    } else if (emptyFreq) {
      return ErrorToast('Smoke Frequency is required');
    } else if (emptyLastUseDate) {
      return ErrorToast('When did you quit?');
    }
    if (getQuoteFormData && !isUpdateValue) {
      if (sessionId) {
        return navigate('/quotes-summary?session_id=' + sessionId);
      } else {
        return navigate('/quotes-summary');
      }
    }
    // all validation Passed
    localStorage.setItem('isShowModal', JSON.stringify(true));
    setLoading(true);
    // let ipAddress: any = "";
    let body: any = {
      quotes: {
        face_amounts: [faceNum],
        birthdate: changedDate,
        gender: newData?.gender,
        state: formData?.abbreviation,
        stateName: formData?.stateName,
        health: Number(newData?.health),
        smoker: newData?.smoker,
        usages: arr,
        mode: 12,
        isEdit: false,
        product_categories: [
          '10 Year Term',
          '15 Year Term',
          '20 Year Term',
          '25 Year Term',
          '30 Year Term',
          '35 Year Term',
        ],
      },
    };
    const a: any = localStorage.getItem('isEditQuote');
    const ParseIsEditQuote: any = JSON.parse(a);
    if (ParseIsEditQuote) {
      body.quotes.isEdit = true;
    }
    localStorage.setItem(
      'getQuoteFormData',
      JSON.stringify({
        newData: {
          ...newData,
          birthdate: dateInput.value,
          state: formData?.abbreviation,
        },
        selectedState: { stateName: selectedState.stateName },
        inputFields: inputFields,
      })
    );
    sessionStorage.setItem(
      'getQuoteFormData',
      JSON.stringify({
        newData: {
          ...newData,
          birthdate: dateInput.value,
          state: formData?.abbreviation,
        },
        selectedState: { stateName: selectedState.stateName },
        inputFields: inputFields,
      })
    );
    const keys = Object.keys(sessionStorage);
    let sessionBody: any = {};
    const age: number = calculateAge(date2);
    if (age < 50) {
      recommend20Face = Math.ceil((faceNum * 2) / 3 / 100000) * 100000;
      recommend30Face = Math.ceil((faceNum * 1) / 3 / 100000) * 100000;
      if (recommend20Face + recommend30Face !== faceNum) {
        recommend20Face -= 100000;
      }
      body.quotes.face_amounts = [
        ...body.quotes.face_amounts,
        recommend20Face,
        recommend30Face,
      ];
    }

    await axios
      .post(BaseURL + '/user/api/data/usages', body)
      .then(async (res: any) => {
        localStorage.setItem('usageData', JSON.stringify(res?.data?.data));
        sessionStorage.setItem('usageData', JSON.stringify(res?.data?.data));
        localStorage.setItem('isEditQuote', JSON.stringify(true));
        await keys.forEach((key: any) => {
          if (key === 'sessionId') {
            return;
          }
          let item: any = sessionStorage.getItem(key);
          sessionBody[key] = JSON.parse(item);
        });
        sessionBody['birthdate'] = formatChange?.join('-');
        await axios
          .post(BaseURL + '/user/add/session', sessionBody)
          .then((res) => {
            sessionStorage.setItem('sessionId', res?.data?.data?._id);
            localStorage.setItem('isShowModal', JSON.stringify(false));
          })
          .catch((err) => ErrorToast(err?.message));
        setLoading(false);
        if (sessionId) {
          navigate('/quotes-summary?session_id=' + sessionId);
        } else {
          navigate('/quotes-summary');
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setNoQuoteModal(true);
      });
  };

  let LocalQuoteData: any = localStorage.getItem('quoteData');
  const parseLocalData: any = JSON.parse(LocalQuoteData);
  const goBack = () => {
    if (LocalQuoteData || sessionId) {
      if (parseLocalData?.calculation || sessionId) {
        if (sessionId) {
          navigate(
            '/simple-term-life-insurance-calculator?session_id=' + sessionId
          );
        } else {
          navigate('/simple-term-life-insurance-calculator');
        }
      } else {
        navigate('/term-life-insurance-calculator');
      }
    } else {
      navigate('/term-life-insurance-calculator');
    }
  };
  const getQuoteSessionData = async () => {
    await axios
      .get(BaseURL + '/user/get/session/' + sessionId)
      .then((res) => {
        const data: any = res?.data?.data;
        if (data?.getQuoteFormData?.newData) {
          setNewData(data?.getQuoteFormData?.newData);
          handleFormChange({
            target: {
              name: 'stateName',
              value: data?.getQuoteFormData?.selectedState?.stateName,
            },
          });
          handleFormChange({
            target: {
              name: 'abbreviation',
              value: data?.getQuoteFormData?.newData?.state,
            },
          });
          setSelectedState(data?.getQuoteFormData?.selectedState);
          setIsState(false);
        }
      })
      .catch((err) => {
        ErrorToast(err?.response?.data?.message);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById('getQuoteAnimation')
        ?.classList.remove('opacity-0');
      document
        .getElementById('getQuoteAnimation')
        ?.classList.add('opacity-100');
    }, 100);
    let rounded = 0;

    if (sessionId) {
      localStorage.clear();
      sessionStorage.setItem('sessionId', sessionId);
      sessionStorage.setItem('fromSession', JSON.stringify(true));
      getState();
      if (!JSON.parse(session_birthdateCheck)) {
        return setBirthCheckModal(true);
      } else {
        getQuoteSessionData();
      }
    } else {
      if (parseLocalData) {
        rounded = Math.ceil(parseLocalData?.calculation / 100000) * 100000;
      }
      setNewData({ ...newData, face_amounts: formatCurrency(rounded) || '$0' });
      getState();

      if (location.state) {
        if (location.state.coverage && location?.state?.formData) {
          localStorage.setItem(
            'quoteData',
            JSON.stringify(location.state.formData)
          );
          sessionStorage.setItem(
            'quoteData',
            JSON.stringify(location.state.formData)
          );
        }
      }
      if (!formData?.stateName) {
        setIsState(false);
      }

      if (getQuoteFormData) {
        let getQuoteFormParseData = JSON.parse(getQuoteFormData);
        setGetFormQuoteDataState(getQuoteFormParseData);
        let newDataIn: any = getQuoteFormParseData?.newData;
        if (location?.state?.coverage) {
          newDataIn.face_amounts = formatCurrency(
            Math.ceil(parseLocalData?.calculation / 100000) * 100000
          );
        }
        setNewData(newDataIn);
        if (getQuoteFormParseData?.selectedState) {
          setSelectedState(getQuoteFormParseData?.selectedState);
          handleFormChange({
            target: {
              name: 'stateName',
              value: getQuoteFormParseData?.selectedState?.stateName,
            },
          });
        }
        if (!getQuoteFormParseData?.newData?.face_amounts) {
          setModal(true);
        }
        setInputFields(getQuoteFormParseData?.inputFields);
        if (location?.state?.coverage) {
          setIsUpdateValue(true);
        }
      } else {
        if (location?.state?.getQuoteBtnAmt) {
          return setNewData({
            ...newData,
            face_amounts: location?.state?.getQuoteBtnAmt,
          });
        }
        if (!rounded) {
          setModal(true);
        }
      }
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ViewContent',
      content_id: 'GetQuote',
    });
  }, []);

  const nameData: any = localStorage.getItem('InsuredSpouseName');
  const InsuredSpouseName = JSON.parse(nameData);

  return (
    <>
      <Helmet>
        <title>Term Life Insurance Quote | ExplainLife</title>
        <meta
          name='description'
          content='Term life insurance is a lot more affordable than you think. Find out what it costs and how to avoid paying for more than you need. Get your quote today.'
        />
        <meta
          property='og:title'
          content='Term Life Insurance Quote | ExplainLife'
        />
        <meta
          property='og:description'
          content='Term life insurance is a lot more affordable than you think. Find out what it costs and how to avoid paying for more than you need. Get your quote today.'
        />
      </Helmet>
      <Toaster position='top-center' />
      <NavBar />
      <div
        className='relative flex duration-500 opacity-0 lg:block'
        id='getQuoteAnimation'
      >
        <nav
          aria-label='Pagination'
          className='fixed bottom-0 right-0 flex flex-col gap-2 mb-6 ml-auto mr-6 sm:mr-2'
        >
          <button
            onClick={goBack}
            className='flex items-center justify-center w-8 h-8 text-white rounded-md bg-darkBrown'
          >
            <ChevronUpIcon className='w-4 h-4' />
          </button>
          <button
            className='flex items-center justify-center w-8 h-8 text-white bg-gray-300 rounded-md'
            disabled={true}
          >
            <ChevronDownIcon className='w-4 h-4' />
          </button>
        </nav>
        <div className='flex flex-col items-center justify-center w-full min-h-screen pt-5 bg-lightBrown'>
          <div className='mt-[80px]'>
            <div className='w-[600px] sm:w-[480px] xs:w-[330px]'>
              <div
                className='text-darkBrown/[.5] font-medium mb-3 cursor-pointer inline-block'
                onClick={() => {
                  document
                    .getElementById('getQuoteAnimation')
                    ?.classList.remove('opacity-100');
                  document
                    .getElementById('getQuoteAnimation')
                    ?.classList.add('opacity-0');
                  setTimeout(() => {
                    if (sessionId) {
                      navigate(
                        '/simple-term-life-insurance-calculator?session_id=' +
                          sessionId
                      );
                    } else {
                      navigate('/simple-term-life-insurance-calculator');
                    }
                  }, 500);
                }}
              >
                <h2>
                  How much life insurance do I need ? = {newData?.face_amounts}
                </h2>
              </div>
              <div className='text-darkBrown text-2xl font-medium flex ml-[-50px] mb-10'>
                <span className='mr-5'>
                  <AiOutlineCaretRight fontSize={30} />
                </span>
                <h2>How much will it cost ?</h2>
              </div>
              <h3 className='font-medium mb-3 text-[15px]'>
                {InsuredSpouseName?.insuredName
                  ? InsuredSpouseName?.insuredName + "'s"
                  : "Insured Person's"}{' '}
                Personal Information
              </h3>
              <div className='flex justify-between rounded-lg border border-darkBrown/[.2] px-3 mb-3 text-darkBrown'>
                <div className='py-2'>Sex</div>
                <div className='flex'>
                  <div
                    className={`${
                      newData?.gender === 'Male'
                        ? 'border-b-4 border-darkBrown text-darkBrown'
                        : 'text-darkBrown/[.5]'
                    } flex items-center mx-2 cursor-pointer font-medium`}
                    onClick={() => {
                      setNewData({ ...newData, gender: 'Male' });
                      setIsUpdateValue(true);
                    }}
                  >
                    Male
                  </div>
                  <div
                    className={`${
                      newData?.gender === 'Female'
                        ? 'border-b-4 border-darkBrown text-darkBrown'
                        : 'text-darkBrown/[.5]'
                    } flex items-center mx-2 cursor-pointer font-medium`}
                    onClick={() => {
                      setNewData({ ...newData, gender: 'Female' });
                      setIsUpdateValue(true);
                    }}
                  >
                    Female
                  </div>
                </div>
              </div>
              <div className='flex justify-between rounded-lg border border-darkBrown/[.2] py-2 px-3 mb-3 text-darkBrown'>
                <label htmlFor='birthdate'>Birthdate</label>
                <div>
                  <input
                    type='text'
                    id='birthdate'
                    name='birthdate'
                    maxLength={10}
                    placeholder='mm-dd-yyyy'
                    autoComplete='off'
                    autoCorrect='off'
                    onChange={handleBirthDateChange}
                    value={newData?.birthdate}
                    className='w-[110px] bg-transparent outline-none border-none font-medium placeholder-darkBrown/[.5]'
                  />
                </div>
              </div>
              {!isState && (
                <div className='flex justify-between rounded-lg border border-darkBrown/[.2] py-2 px-3 mb-3 text-darkBrown'>
                  <div>State</div>
                  <div>
                    <div className='relative'>
                      <Listbox
                        value={selectedState}
                        onChange={(e: any) => {
                          setSelectedState(e);
                          handleFormChange({
                            target: { name: 'stateName', value: e?.stateName },
                          });
                          handleFormChange({
                            target: {
                              name: 'abbreviation',
                              value: e?.abbreviation,
                            },
                          });
                          setIsUpdateValue(true);
                        }}
                      >
                        <Listbox.Button className='relative cursor-pointer rounded-lg bg-transparent w-[150px] flex justify-between focus:outline-none focus-visible:border-none xs:text-sm'>
                          <span className='block font-semibold truncate font-archivo'>
                            {selectedState?.stateName}
                          </span>
                          <span className='inset-y-0 right-0 flex items-center ml-1 pointer-events-none'>
                            <ChevronUpDownIcon
                              className='w-5 h-5 text-darkBrown'
                              aria-hidden='true'
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave='transition ease-in duration-100'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                        >
                          <Listbox.Options className='absolute right-0 z-50 mt-1 h-[246px] xs:h-[190px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none xs:text-sm'>
                            {stateData?.length > 0 &&
                              stateData?.map((person: any, ind: any) => (
                                <Listbox.Option
                                  key={person?._id}
                                  value={person}
                                  className={({ active }) =>
                                    `relative cursor-pointer hover:font-bolder select-none py-2 pl-10 pr-4 ${
                                      active
                                        ? 'bg-lightBrown text-darkBrown'
                                        : ' text-darkBrown/[1.5]'
                                    }`
                                  }
                                >
                                  <span
                                    className={`block truncate font-archivo ${
                                      selectedState.stateName ===
                                        person.stateName && 'font-medium'
                                    }`}
                                  >
                                    {person?.stateName || '-'}
                                  </span>
                                  {selectedState.stateName ===
                                    person.stateName && (
                                    <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-logoBlue'>
                                      <CheckIcon
                                        className='w-5 h-5'
                                        aria-hidden='true'
                                      />
                                    </span>
                                  )}
                                </Listbox.Option>
                              ))}
                          </Listbox.Options>
                        </Transition>
                      </Listbox>
                    </div>
                  </div>
                </div>
              )}
              <div className='flex justify-between rounded-lg border border-darkBrown/[.2] py-2 px-3 mb-3 text-darkBrown'>
                <div className='flex flex-row items-center gap-2'>
                  <div>Health Rating</div>
                  <SmallInfoTip
                    info='Rate your overall health on a scale of 1 to 5. 1 is the least healthy and 5 is the most healthy.'
                    className='bg-lightBrown'
                  />
                </div>
                <div className=''>
                  <StarRatingCustom
                    rating={newData?.health}
                    onRatingChange={(newRating: number) => {
                      setNewData({ ...newData, health: newRating });
                      setIsUpdateValue(true);
                    }}
                  />
                </div>
              </div>
              <div className='flex justify-between rounded-lg border border-darkBrown/[.2] px-3 mb-3 text-darkBrown'>
                <div className='py-2'>Smoker</div>
                <div className='flex'>
                  <div
                    className={`${
                      newData?.smoker === 'Never'
                        ? 'border-b-4 border-darkBrown text-darkBrown'
                        : 'text-darkBrown/[.5]'
                    } flex items-center mx-2 xs:mx-1 cursor-pointer font-medium`}
                    onClick={() => {
                      setNewData({ ...newData, smoker: 'Never' });
                      setIsUpdateValue(true);
                    }}
                  >
                    Never
                  </div>
                  <div
                    className={`${
                      newData?.smoker === 'Currently'
                        ? 'border-b-4 border-darkBrown text-darkBrown'
                        : 'text-darkBrown/[.5]'
                    } flex items-center mx-2 xs:mx-1 cursor-pointer font-medium`}
                    onClick={() => {
                      setNewData({ ...newData, smoker: 'Currently' });
                      setIsUpdateValue(true);
                    }}
                  >
                    Currently
                  </div>
                  <div
                    className={`${
                      newData?.smoker === 'Previously'
                        ? 'border-b-4 border-darkBrown text-darkBrown'
                        : 'text-darkBrown/[.5]'
                    } flex items-center mx-2 xs:mx-1 cursor-pointer font-medium`}
                    onClick={() => {
                      setNewData({ ...newData, smoker: 'Previously' });
                      setIsUpdateValue(true);
                    }}
                  >
                    Previously
                  </div>
                </div>
              </div>
              {newData?.smoker !== 'Never' && (
                <div className='mt-14'>
                  {inputFields.map((input: any, index: any) => {
                    let frequencyEmptyCheck: any = '';
                    if (getFormQuoteDataState?.inputFields?.length > 0) {
                      const a: any = document.getElementById(
                        `last_use_date-${index}`
                      );
                      if (
                        a &&
                        getFormQuoteDataState?.inputFields[index]?.last_use_date
                      ) {
                        a.value =
                          getFormQuoteDataState?.inputFields[
                            index
                          ]?.last_use_date;
                      }
                    }
                    return (
                      <div key={index}>
                        <div className='font-medium mb-2 text-[15px] flex justify-between'>
                          <div>Usage {index + 1}</div>
                          {inputFields.length > 1 && (
                            <div>
                              <AiOutlineMinusCircle
                                className='h-[20px] w-[20px] cursor-pointer mr-2'
                                fontSize={20}
                                onClick={() => removeInputField(index)}
                              />
                            </div>
                          )}
                        </div>
                        <div className='flex justify-between rounded-lg border border-darkBrown/[.2] py-2 px-3 mb-3 text-darkBrown'>
                          <div>Type</div>
                          <div className='relative'>
                            <Listbox
                              value={inputFields}
                              onChange={(val: any) => {
                                const data = [...inputFields];
                                data[index]['category'] = val;
                                setInputFields(data);
                                setIsUpdateValue(true);
                              }}
                            >
                              <Listbox.Button className='relative flex justify-between cursor-pointer rounded-lg bg-transparent w-[142px] focus:outline-none focus-visible:border-none xs:text-sm'>
                                <span className='block font-semibold truncate font-archivo'>
                                  {inputFields[index]?.category || 'Select'}
                                </span>
                                <span className='inset-y-0 right-0 flex items-center ml-1 pointer-events-none'>
                                  <ChevronUpDownIcon
                                    className='w-5 h-5 text-gray-400'
                                    aria-hidden='true'
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave='transition ease-in duration-100'
                                leaveFrom='opacity-100'
                                leaveTo='opacity-0'
                              >
                                <Listbox.Options className='absolute right-0 z-50 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                                  {smokeType?.map((person: any, ind: any) => (
                                    <Listbox.Option
                                      key={person}
                                      value={person}
                                      className={({ active }) =>
                                        `relative cursor-pointer hover:font-bolder select-none py-2 pl-10 pr-6 ${
                                          active
                                            ? 'bg-lightBrown text-black'
                                            : 'text-gray-900'
                                        }`
                                      }
                                    >
                                      <span
                                        className={`block truncate font-archivo ${
                                          inputFields[index]?.category ===
                                            person && 'font-medium'
                                        }`}
                                      >
                                        {person}
                                      </span>
                                      {inputFields[index]?.category ===
                                        person && (
                                        <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-logoBlue'>
                                          <CheckIcon
                                            className='w-5 h-5'
                                            aria-hidden='true'
                                          />
                                        </span>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </Listbox>
                          </div>
                        </div>
                        <div className='flex justify-between rounded-lg border border-darkBrown/[.2] py-2 px-3 mb-3 text-darkBrown'>
                          <div className='fs-6'>Frequency</div>
                          <div className='relative'>
                            <Listbox
                              value={inputFields}
                              onChange={(val: any) => {
                                const data = [...inputFields];
                                data[index]['frequency'] = val?.value;
                                setInputFields(data);
                                setIsUpdateValue(true);
                              }}
                            >
                              <Listbox.Button className='relative flex justify-between cursor-pointer rounded-lg bg-transparent w-[142px] focus:outline-none focus-visible:border-none xs:text-sm'>
                                <span className='block font-semibold truncate font-archivo'>
                                  {smokeFrequency?.map((v: any) => {
                                    if (
                                      inputFields[index]?.frequency === v?.value
                                    ) {
                                      frequencyEmptyCheck = v?.label;
                                      return v?.label;
                                    } else {
                                      return false;
                                    }
                                  })}
                                  {!frequencyEmptyCheck && 'Select'}
                                </span>
                                <span className='inset-y-0 right-0 flex items-center ml-1 pointer-events-none'>
                                  <ChevronUpDownIcon
                                    className='w-5 h-5 text-gray-400'
                                    aria-hidden='true'
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave='transition ease-in duration-100'
                                leaveFrom='opacity-100'
                                leaveTo='opacity-0'
                              >
                                <Listbox.Options className='absolute right-0 z-50 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                                  {smokeFrequency?.map((person: any) => (
                                    <Listbox.Option
                                      key={person?.value}
                                      value={person}
                                      className={({ active }) =>
                                        `relative cursor-pointer hover:font-bolder select-none py-2 pl-10 pr-4 ${
                                          active
                                            ? 'bg-lightBrown text-black'
                                            : 'text-gray-900'
                                        }`
                                      }
                                    >
                                      <span
                                        className={`block truncate font-archivo ${
                                          inputFields[index]?.frequency ===
                                            person?.value && 'font-medium'
                                        }`}
                                      >
                                        {person?.label}
                                      </span>
                                      {inputFields[index]?.frequency ===
                                        person?.value && (
                                        <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-logoBlue'>
                                          <CheckIcon
                                            className='w-5 h-5'
                                            aria-hidden='true'
                                          />
                                        </span>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </Listbox>
                          </div>
                        </div>
                        {newData?.smoker === 'Previously' && (
                          <div className='flex justify-between rounded-lg border border-darkBrown/[.2] py-2 px-3 mb-3 text-darkBrown'>
                            <label htmlFor={`last_use_date-${index}`}>
                              When did you quit?
                            </label>
                            <div className=''>
                              <input
                                type='text'
                                placeholder='mm-dd-yyyy'
                                name='last_use_date'
                                id={`last_use_date-${index}`}
                                maxLength={10}
                                autoComplete='off'
                                autoCorrect='off'
                                className='w-[142px] bg-transparent outline-none border-none font-medium'
                                onChange={() => {
                                  let data1: any = [...inputFields];
                                  let dateFormInput: any =
                                    document.getElementById(
                                      `last_use_date-${index}`
                                    );
                                  let inputText = dateFormInput.value;
                                  inputText = inputText.replace(/\D/g, '');
                                  if (inputText.length > 2) {
                                    inputText =
                                      inputText.slice(0, 2) +
                                      '-' +
                                      inputText.slice(2);
                                  }
                                  if (inputText.length > 5) {
                                    inputText =
                                      inputText.slice(0, 5) +
                                      '-' +
                                      inputText.slice(5);
                                  }
                                  data1[index]['last_use_date'] = inputText;
                                  dateFormInput.value = inputText;
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {inputFields?.length < 6 && (
                    <div className=''>
                      <button
                        className='font-medium text-[15px]'
                        onClick={addFields}
                      >
                        + Add Usage
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className='w-full mt-10 mb-10 xs:mb-20'>
              <button
                onPointerDown={mouseDown}
                onPointerUp={mouseUp}
                onPointerLeave={mouseUp}
                onMouseDown={mouseDown}
                onMouseUp={mouseUp}
                onMouseLeave={mouseUp}
                className='flex justify-center w-full text-lg bg-mintGreen py-[8px] rounded-full font-medium shadow-[4px_4px_0px_#3C2C28] border-2 border-darkBrown ease-in duration-75'
                disabled={loading}
                onClick={handleSubmit}
              >
                <div className='flex flex-row items-center gap-1'>
                  Get a quote
                  {loading ? (
                    <ArrowPathIcon
                      height={29}
                      width={20}
                      className='animate-spin'
                    />
                  ) : (
                    <ChevronRightIcon className='w-4 h-4' />
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <EnterCoverageModal
        show={modal}
        setShow={setModal}
        faceAmount={faceAmount}
        setFaceAmount={setFaceAmount}
        newData={newData}
        setNewData={setNewData}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        stateData={stateData}
      />
      <NoQuoteModal show={noQuoteModal} setShow={setNoQuoteModal} />
      <NegativeQuoteModal
        show={negativeQuoteModal}
        setShow={setNegativeQuoteModal}
      />
      <InactiveModal
        show={emailModal}
        setShow={setEmailModal}
        preview={{}}
        urlReturnPage='calculation-summary'
      />
      <EmailThanksModal show={emailThanksModal} setShow={setEmailThanksModal} />
      {sessionId && (
        <BirthCheckModal
          show={birthCheckModal}
          setShow={setBirthCheckModal}
          sessionId={sessionId}
          setNewData={setNewData}
          setSelectedState={setSelectedState}
        />
      )}
    </>
  );
}
