import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import * as Tooltip from "@radix-ui/react-tooltip";
import classNames from "classnames";
import { useState } from "react";

export const OutlineInfoTip = (props: { info: string; className?: string }) => {
  const [open, setOpen] = useState(false);

  return (
    <Tooltip.Provider>
      <Tooltip.Root open={open} onOpenChange={setOpen}>
        <Tooltip.Trigger asChild>
          <button
            className={classNames(
              "h-6 w-6 bg-explainWhite rounded-full",
              props.className
            )}
            onClick={() => setOpen(!open)}
          >
            <QuestionMarkCircleIcon />
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="rounded-md bg-mintGreen text-sm xs:text-xs py-2.5 px-3.5 mx-2 text-darkBrown select-none max-w-full max-w-xs z-50"
            sideOffset={5}
          >
            {props.info}
            <Tooltip.Arrow className="fill-mintGreen" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

// Add bg color to className whenever used
export const SmallInfoTip = (props: { info: string; className?: string }) => {
  const [open, setOpen] = useState(false);

  return (
    <Tooltip.Provider>
      <Tooltip.Root open={open} onOpenChange={setOpen}>
        <Tooltip.Trigger asChild>
          <button
            className={classNames(
              "h-4 w-4 rounded-full text-darkBrown",
              props.className
            )}
            onClick={() => setOpen(!open)}
          >
            <QuestionMarkCircleIcon />
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="rounded-md bg-mintGreen text-sm xs:text-xs py-2.5 px-3.5 text-darkBrown select-none max-w-full max-w-xs z-50"
            sideOffset={5}
          >
            {props.info}
            <Tooltip.Arrow className="fill-mintGreen" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
