import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { Fragment, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { cleanNumber, formatCurrency } from "../../utils/utils";
import { mouseDown, mouseUp } from "../Button";

const COVERAGE_INPUT_CLASSES = classNames(
  "text-center",
  "w-56",
  "xs:w-full",
  "font-medium",
  "text-xl",
  "outline-none",
  "h-7",
  "text-darkBrown",
  "px-2",
  "py-5",
  "outline-none",
  "mb-4",
  "ml-1",
  "focus:ring-0",
  "border-b-2",
  "border-darkBrown",
  "bg-transparent",
  "tracking-wider",
  "rounded-none"
);

export const EnterCoverageModal = (props: any) => {
  const {
    show,
    setShow,
    setFaceAmount,
    faceAmount,
    newData,
    setNewData,
    isGetQuoteBtn,
  } = props;

  const navigate = useNavigate();

  const goBack = () => {
    let LocalQuoteData: any = localStorage.getItem("quoteData");
    if (LocalQuoteData) {
      const parseLocalData: any = JSON.parse(LocalQuoteData);
      if (parseLocalData?.calculation) {
        navigate("/simple-term-life-insurance-calculator");
      } else {
        navigate("/simple-term-life-insurance-calculator");
      }
    } else {
      navigate("/simple-term-life-insurance-calculator");
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (faceAmount && window.innerWidth > 1023) {
      inputRef.current?.focus();
    }
  }, [faceAmount]);

  const handleChangeFaceValue = (event: any) => {
    const newFaceValue: any = formatCurrency(cleanNumber(event.target.value));
    setNewData({ ...newData, face_amount_modal: newFaceValue });
  };

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog onClose={() => setShow(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30 z-[1000] flex items-center justify-center">
              <Dialog.Panel className="max-w-[640px] max-h-[85vh] overflow-auto transform rounded-2xl bg-mintGreen md:mx-5 py-5 shadow-xl transition-all">
                <button
                  type="button"
                  className="box-content rounded-none border-none absolute right-[15px] top-[15px]"
                  onClick={() => setShow(false)}
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
                <div className="px-6 flex flex-col">
                  <h2 className="text-darkBrown text-center text-[24px] xs:text-[22px] xs:mt-3 font-medium w-[450px] xs:w-auto xs:w-[90vw] mx-auto">
                    {isGetQuoteBtn
                      ? "Know how much life insurance you need?"
                      : "Wait a second. You've gone too far!"}
                  </h2>
                  <div>
                    <p className="text-darkBrown text-center mt-5 xs:text-sm">
                      We recommend using our life insurance needs calculator,
                      but understand that some people are more educated than
                      others on life insurance, so please feel free to skip that
                      part of our process and input the amount of coverage you
                      would like.
                    </p>
                    {!faceAmount && (
                      <div className="mt-5 flex xs:flex-col gap-5 text-lg xs:text-base">
                        <button
                          className="bg-darkBrown text-center text-white rounded-full py-3 px-8 xs:px-4 cursor-pointer w-full xs:text-sm"
                          onClick={() => {
                            setShow(false);
                            goBack();
                          }}
                        >
                          Calculate How Much Coverage I Need
                        </button>
                        <button
                          onPointerDown={mouseDown}
                          onPointerUp={mouseUp}
                          onPointerLeave={mouseUp}
                          onMouseDown={mouseDown}
                          onMouseUp={mouseUp}
                          onMouseLeave={mouseUp}
                          className="bg-lightBrown w-full rounded-full font-medium py-3 px-8 xs:px-4 shadow-[4px_4px_0px_#3C2C28] border-2 border-darkBrown duration-75 tracking-wide xs:text-sm"
                          onClick={() => setFaceAmount(!faceAmount)}
                        >
                          Enter Life Insurance Coverage Amount
                        </button>
                      </div>
                    )}
                    {faceAmount ? (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (newData?.face_amount_modal) {
                            setNewData({
                              ...newData,
                              face_amounts: newData?.face_amount_modal,
                            });
                            if (isGetQuoteBtn) {
                              navigate("/term-life-insurance-quote", {
                                state: {
                                  getQuoteBtnAmt: newData?.face_amount_modal,
                                },
                              });
                            }
                            setShow(false);
                          }
                        }}
                        className="mt-5"
                      >
                        <div className="flex flex-row xs:flex-col xs:gap-0 gap-10 items-center">
                          <h3 className="font-medium text-lg mb-2 w-full text-darkBrown">
                            Life Insurance Coverage Amount
                          </h3>
                          <input
                            ref={inputRef}
                            id="autoFocusId"
                            type="text"
                            className={COVERAGE_INPUT_CLASSES}
                            placeholder={formatCurrency(0)}
                            value={newData?.face_amount_modal || ""}
                            onChange={handleChangeFaceValue}
                            required
                          />
                        </div>
                        <button
                          onPointerDown={mouseDown}
                          onPointerUp={mouseUp}
                          onPointerLeave={mouseUp}
                          onMouseDown={mouseDown}
                          onMouseUp={mouseUp}
                          onMouseLeave={mouseUp}
                          type="submit"
                          className="bg-lightBrown rounded-full xs:text-base font-medium py-3 px-8 xs:px-4 xs:mb-2 shadow-[4px_4px_0px_#3C2C28] border-2 border-darkBrown duration-75 tracking-wide xs:w-full"
                        >
                          Submit
                        </button>
                      </form>
                    ) : null}
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
