import { ArrowPathIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import classNames from "classnames";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { NavButton } from "../Components/Button";
import Footer from "../Components/Footer";
import { NavBar } from "../Components/NavBar";
import { BaseURL } from "../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../helpers/Toast";

const INPUT_CLASSES = classNames(
  "w-full",
  "font-medium",
  "text-lg",
  "outline-none",
  "h-7",
  "text-darkBrown",
  "px-2",
  "py-5",
  "outline-none",
  "focus:ring-0",
  "border-b-2",
  "border-darkBrown",
  "bg-transparent",
  "tracking-wider",
  "rounded-none"
);
const Referral = () => {
  const [newData, setNewData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    refer_firstName: "",
    refer_lastName: "",
    refer_email: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value?.trimStart() });
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    let body = {
      name: newData?.firstName + " " + newData?.lastName,
      email: newData?.email,
      refer_name: newData?.refer_firstName + " " + newData?.refer_lastName,
      refer_email: newData?.refer_email,
    };
    await axios
      .post(BaseURL + "/user/add/referdata", body)
      .then((res: any) => {
        setLoading(false);
        SuccessToast(res?.data?.message);
        setNewData({
          firstName: "",
          lastName: "",
          email: "",
          refer_firstName: "",
          refer_lastName: "",
          refer_email: "",
        });
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <title>Referral | ExplainLife</title>
        <meta
          name="description"
          content="Refer a friend to ExplainLife to help them find the right insurance plan for their family's needs. ExplainLife has advice and planning tools they can count on!"
        />
        <meta property="og:title" content={"Referral | ExplainLife"} />
        <meta
          property="og:description"
          content="Refer a friend to ExplainLife to help them find the right insurance plan for their family's needs. ExplainLife has advice and planning tools they can count on!"
        />
      </Helmet>
      <div className="flex text-darkBrown flex-col bg-explainWhite">
        <NavBar />
        <div className="bg-lightBrown pt-20">
          <div className="max-w-[1500px] mx-auto w-full px-16 mt-10 pb-14 md:px-5">
            <h1 className="text-5xl font-judson_italic font-medium text-darkBrown ">
              Refer a friend
            </h1>
            <p className="mt-5 ">
              Thank you for being a valued client of ExplainLife and for sharing
              in our commitment to securing financial peace of mind for all.
            </p>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="max-w-[1500px] mx-auto w-full px-16 mt-20 pb-14 lg:px-5"
        >
          <div className="flex md:flex-col w-full gap-5">
            <div className="rightSide w-1/2 md:w-full border border-darkBrown/[.5] rounded-lg shadow-lg">
              <h2 className="text-3xl p-5 font-bold font-judson_italic border-b border-darkBrown/[.5]">
                Personal Details
              </h2>
              <div className="p-5">
                <div className="flex mb-5 xs:flex-col gap-5">
                  <div className="w-1/2 xs:w-full">
                    <h3 className="text-xl w-full font-normal whitespace-nowrap">
                      First Name
                    </h3>
                    <div className="w-full">
                      <input
                        type="text"
                        className={classNames(INPUT_CLASSES)}
                        placeholder="Enter First Name"
                        name="firstName"
                        value={newData?.firstName}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                        autoCorrect="off"
                      />
                    </div>
                  </div>
                  <div className="w-1/2 xs:w-full">
                    <h3 className="text-xl w-full font-normal whitespace-nowrap">
                      Last Name
                    </h3>
                    <div className="w-full">
                      <input
                        type="text"
                        className={classNames(INPUT_CLASSES)}
                        placeholder="Enter Last Name"
                        name="lastName"
                        value={newData?.lastName}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                        autoCorrect="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3 mt-3">
                  <h3 className="text-xl w-full font-normal whitespace-nowrap">
                    Email
                  </h3>
                  <div className="w-full">
                    <input
                      type="email"
                      className={classNames(INPUT_CLASSES)}
                      placeholder="Enter Email Address"
                      name="email"
                      value={newData?.email}
                      onChange={handleChange}
                      required
                      autoComplete="off"
                      autoCorrect="off"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="rightSide w-1/2 md:w-full border border-darkBrown/[.5] rounded-lg shadow-lg">
              <h2 className="text-3xl p-5 font-bold font-judson_italic border-b border-darkBrown/[.5]">
                Referral Details
              </h2>
              <div className="p-5">
                <div className="flex mb-5 xs:flex-col gap-5">
                  <div className="w-1/2 xs:w-full">
                    <h3 className="text-xl w-full font-normal whitespace-nowrap">
                      First Name
                    </h3>
                    <div className="w-full">
                      <input
                        type="text"
                        className={classNames(INPUT_CLASSES)}
                        placeholder="Enter First Name"
                        name="refer_firstName"
                        value={newData?.refer_firstName}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                        autoCorrect="off"
                      />
                    </div>
                  </div>
                  <div className="w-1/2 xs:w-full">
                    <h3 className="text-xl w-full font-normal whitespace-nowrap">
                      Last Name
                    </h3>
                    <div className="w-full">
                      <input
                        type="text"
                        className={classNames(INPUT_CLASSES)}
                        placeholder="Enter Last Name"
                        name="refer_lastName"
                        value={newData?.refer_lastName}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                        autoCorrect="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <h3 className="text-xl w-full font-normal whitespace-nowrap">
                    Email
                  </h3>
                  <div className="w-full">
                    <input
                      type="email"
                      className={classNames(INPUT_CLASSES)}
                      placeholder="Enter Email Address"
                      name="refer_email"
                      value={newData?.refer_email}
                      onChange={handleChange}
                      required
                      autoComplete="off"
                      autoCorrect="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-5">
            <NavButton
              variant="primary"
              type="submit"
              style={{ paddingLeft: "50px", paddingRight: "50px" }}
              disabled={loading}
            >
              Submit{" "}
              {loading && (
                <ArrowPathIcon
                  height={20}
                  width={20}
                  className="animate-spin"
                />
              )}
            </NavButton>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Referral;
