export interface Testimonial {
    name: string;
    location: string;
    blurb: string;
}

export const data: Testimonial[] = [
    {
        name: 'Sarah M',
        location: 'California',
        blurb: `This website is a lifesaver! It made figuring out life insurance 
        super easy, with clear explanations and personalized advice. I can't 
        recommend it enough for keeping your family's future safe!`
    },
    {
        name: 'James P',
        location: 'Florida',
        blurb: `I'm telling you, this website is amazing. It's so user-friendly, 
        and they guide you every step of the way. Now I can rest easy knowing 
        my family is taken care of.`
    },
    {
        name: 'Laura G',
        location: 'Texas',
        blurb: `As a busy mom, this website was a game-changer. It made everything 
        quick and easy, and I loved the helpful tips along the way. I finally feel 
        confident about my family's life insurance.`
    },
    {
        name: 'Mark T',
        location: 'New York',
        blurb: `This website is the real deal. It's super easy to use, and they 
        break everything down so you know what's what. I found the perfect policy 
        without any hassle.`
    },
    {
        name: 'Karen S',
        location: 'Texas',
        blurb: `Life insurance used to freak me out, but this website changed 
        everything. They walk you through it all, making it super easy to understand. 
        Now I know my family's future is secure.`
    },
]
