import classNames from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

const NAV_BUTTON_CLASSES = classNames(
  "flex",
  "items-center",
  "pl-4",
  "pr-3",
  "py-3",
  "xs:py-2",
  "rounded-full",
  "h-full",
  "text-sm",
  "xs:text-xs",
  "font-medium",
  "shadow-[4px_4px_0px_#3C2C28]",
  "border-2",
  "border-darkBrown",
  "duration-75"
);

const FORM_BUTTON_CLASSES = classNames(
  "bg-mintGreen",
  "w-36",
  "h-14",
  "rounded-full",
  "font-medium",
  "shadow-[4px_4px_0px_#3C2C28]",
  "border-2",
  "border-darkBrown",
  "duration-75",
  "tracking-wide"
);

export type NavButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  variant: "primary" | "secondary" | "lightBrown";
};

export type FormButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const mouseDown = (e: any) => {
  e.currentTarget.classList.add("translate-x-1");
  e.currentTarget.classList.add("translate-y-1");
  e.currentTarget.classList.remove("shadow-[4px_4px_0px_#3C2C28]");
};

export const mouseUp = (e: any) => {
  e.currentTarget.classList.remove("translate-x-1");
  e.currentTarget.classList.remove("translate-y-1");
  e.currentTarget.classList.add("shadow-[4px_4px_0px_#3C2C28]");
};

export const NavButton = (props: NavButtonProps) => {
  const { children, type = "button", variant = "primary", className, ...otherProps } = props;
  const classes = classNames(className, NAV_BUTTON_CLASSES, variant === "primary" ? "bg-mintGreen" : variant === "lightBrown" && "bg-middleBrown");

  return (
    <button
      onPointerDown={mouseDown}
      onPointerUp={mouseUp}
      onPointerLeave={mouseUp}
      onMouseDown={mouseDown}
      onMouseUp={mouseUp}
      onMouseLeave={mouseUp}
      type={type}
      className={classNames(classes)}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export const FormButton = (props: FormButtonProps) => {
  const { children, type = "button", className, ...otherProps } = props;
  const classes = classNames(className, FORM_BUTTON_CLASSES);

  return (
    <button
      onPointerDown={mouseDown}
      onPointerUp={mouseUp}
      onPointerLeave={mouseUp}
      onMouseDown={mouseDown}
      onMouseUp={mouseUp}
      onMouseLeave={mouseUp}
      type={type}
      className={classes}
      {...otherProps}
    >
      {children}
    </button>
  );
};
