import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import beneficiaryLogo from "../../media/img/beneficiary.png";
import mortgageLogo from "../../media/img/mortgage.png";
import collegeLogo from "../../media/img/college.png";
import fundsLogo from "../../media/img/funds.png";
import { cleanNumber, formatCurrency } from "../../utils/utils";

export const RecommendationExplanationModal = (props: any) => {
  const { show, setShow, recResponse } = props;

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog onClose={() => setShow(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30 z-[1000] flex items-center justify-center z-10">
              <Dialog.Panel className="max-w-[768px] max-h-[80vh] overflow-auto transform rounded-2xl bg-mintGreen md:mx-5 py-7 shadow-xl transition-all">
                <button
                  type="button"
                  className="box-content rounded-none border-none absolute right-[15px] top-[15px]"
                  onClick={() => setShow(false)}
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
                <div className="px-14 xs:px-10 flex flex-col text-darkBrown">
                  <h2 className="text-darkBrown text-center text-[24px] font-semibold w-[450px] xs:w-auto xs:w-[90vw] mx-auto">
                    Recommended Coverage
                  </h2>
                  {recResponse?.isMerge && (
                    <div className="text-center mt-3">
                      During the first{" "}
                      {cleanNumber(
                        recResponse?.isMerge[0]?.product_category || "0"
                      )}{" "}
                      years, you'll benefit from two combined plans, giving you
                      a robust coverage amount of{" "}
                      {formatCurrency(
                        recResponse?.isMerge[0]?.face_amount +
                          recResponse?.isMerge[1]?.face_amount || 0
                      )}{" "}
                      . After these initial{" "}
                      {cleanNumber(
                        recResponse?.isMerge[0]?.product_category || "0"
                      )}{" "}
                      years, your coverage will adjust to one plan, providing
                      you with a decreased but substantial coverage of{" "}
                      {formatCurrency(
                        recResponse?.isMerge[1]?.face_amount || 0
                      )}{" "}
                      for the next{" "}
                      {cleanNumber(
                        recResponse?.isMerge[1]?.product_category || "0"
                      ) -
                        cleanNumber(
                          recResponse?.isMerge[0]?.product_category || "0"
                        )}{" "}
                      years.
                    </div>
                  )}
                  <div className="mt-3 flex xs:flex-col justify-center gap-5">
                    {recResponse?.isMerge ? (
                      <>
                        <div className="bg-lightBrown text-center w-full rounded-lg font-medium py-3 px-5 xs:px-4 shadow-[3px_3px_0px_#3C2C28] border-2 border-darkBrown duration-75 tracking-wide">
                          {formatCurrency(
                            recResponse?.isMerge[0]?.face_amount || 0
                          )}{" "}
                          for{" "}
                          {cleanNumber(
                            recResponse?.isMerge[0]?.product_category || "0"
                          )}{" "}
                          Years
                        </div>
                        <div className="bg-lightBrown text-center w-full rounded-lg font-medium py-3 px-5 xs:px-4 shadow-[3px_3px_0px_#3C2C28] border-2 border-darkBrown duration-75 tracking-wide">
                          {formatCurrency(
                            recResponse?.isMerge[1]?.face_amount || 0
                          )}{" "}
                          for{" "}
                          {cleanNumber(
                            recResponse?.isMerge[1]?.product_category || "0"
                          )}{" "}
                          Years
                        </div>
                      </>
                    ) : (
                      <div className="bg-lightBrown text-center rounded-lg font-medium py-3 px-5 xs:px-4 shadow-[3px_3px_0px_#3C2C28] border-2 border-darkBrown duration-75 tracking-wide">
                        {formatCurrency(recResponse?.face_amount || 0)} for{" "}
                        {cleanNumber(recResponse?.product_category || "0")}{" "}
                        Years
                      </div>
                    )}
                  </div>
                  <hr className="my-6 text-darkBrown border-darkBrown/[.5]" />
                  {recResponse?.isMerge && (
                    <>
                      <div className="text-center text-darkBrown">
                        <h3 className="text-[24px] font-semibold w-[450px] xs:w-auto xs:w-[90vw] mx-auto">
                          Why do I need 2 policies?
                        </h3>
                        <p className="mt-3">
                          Based on your age and other personal inputs provided,
                          your expenses over the next{" "}
                          {cleanNumber(
                            recResponse?.isMerge[0]?.product_category || "0"
                          )}{" "}
                          years are significantly higher than your expenses will
                          be{" "}
                          {cleanNumber(
                            recResponse?.isMerge[0]?.product_category || "0"
                          )}
                          -
                          {cleanNumber(
                            recResponse?.isMerge[1]?.product_category || "0"
                          )}{" "}
                          years from now.
                        </p>
                      </div>
                      <hr className="my-6 text-darkBrown border-darkBrown/[.5]" />
                    </>
                  )}
                  <div className="text-center text-darkBrown">
                    <h3 className="text-[24px] font-semibold w-[450px] xs:w-auto xs:w-[90vw] mx-auto">
                      Rationale for Next{" "}
                      {recResponse?.isMerge
                        ? cleanNumber(
                            recResponse?.isMerge[0]?.product_category || "0"
                          )
                        : cleanNumber(
                            recResponse?.product_category || "0"
                          )}{" "}
                      Years
                    </h3>
                    <div className="flex md:flex-col items-center justify-center gap-4 my-5">
                      <div className="flex xs:flex-col gap-4">
                        <div className="w-[155px] h-[100px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[3px_3px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                          <img
                            src={mortgageLogo}
                            height={25}
                            alt="mortgageLogo"
                          />
                          <div className="mt-1 text-[14px] font-medium text-center">
                            Mortgage Payments
                          </div>
                        </div>
                        <div className="w-[155px] h-[100px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[3px_3px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                          <img
                            src={beneficiaryLogo}
                            height={25}
                            alt="beneficiaryLogo"
                          />
                          <div className="mt-1 text-[14px] font-medium text-center">
                            Raising Children
                          </div>
                        </div>
                      </div>
                      <div className="flex xs:flex-col gap-4">
                        <div className="w-[155px] h-[100px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[3px_3px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                          <img
                            src={collegeLogo}
                            height={25}
                            alt="collegeLogo"
                          />
                          <div className="mt-1 text-[14px] font-medium text-center">
                            College Savings
                          </div>
                        </div>
                        <div className="w-[155px] h-[100px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[3px_3px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                          <img src={fundsLogo} height={25} alt="fundsLogo" />
                          <div className="mt-1 text-[14px] font-medium text-center">
                            Income Replacement
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {recResponse?.isMerge && (
                    <div className="text-center text-darkBrown mt-1">
                      <h3 className="text-[24px] font-semibold w-[450px] xs:w-auto xs:w-[90vw] mx-auto">
                        Rationale for Years{" "}
                        {cleanNumber(
                          recResponse?.isMerge[0]?.product_category || "0"
                        )}
                        -
                        {cleanNumber(
                          recResponse?.isMerge[1]?.product_category || "0"
                        )}
                      </h3>
                      <div className="flex xs:flex-col items-center gap-4 my-5 justify-center">
                        <div className="w-[155px] h-[100px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[3px_3px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                          <img
                            src={mortgageLogo}
                            height={25}
                            alt="mortgageLogo"
                          />
                          <div className="mt-1 text-[14px] font-medium text-center">
                            Mortgage Payments
                          </div>
                        </div>
                        <div className="w-[155px] h-[100px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[3px_3px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                          <img src={fundsLogo} height={25} alt="fundsLogo" />
                          <div className="mt-1 text-[14px] font-medium text-center">
                            Income Replacement
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
