export const data = {
  resources: [
    {
      imageUrl: "assets/BannerLogo.png",
    },
    {
      imageUrl: "assets/AIGLogo.png",
    },
    {
      imageUrl: "assets/JohnHancockLogo.png",
    },
    {
      imageUrl: "assets/NationwideLogo.png",
    },
    {
      imageUrl: "assets/PrudentialLogo.png",
    },
    {
      imageUrl: "assets/LincolnLogo.png",
    },
    {
      imageUrl: "assets/ProtectiveLogo.png",
    },
    {
      imageUrl: "assets/PacificLifeLogo.png",
    },
    {
      imageUrl: "assets/NorthAmericanLogo.png",
    },
  ],
};
