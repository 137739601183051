import { useState, useEffect } from "react";
import logo from "../media/img/explainLifeLogo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  Bars3Icon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { NavButton } from "./Button";
import { EnterCoverageModal } from "./Modals/EnterCoverageModal";

const NAVBAR_CLASSES = classNames(
  "top-0",
  "fixed",
  "px-16",
  "h-16",
  "w-full",
  "bg-transparent",
  "md:px-0",
  "z-50",
  "duration-300"
);

const LOGO_CLASSES = classNames(
  "text-darkBrown",
  "cursor-pointer",
  "text-xl",
  "flex",
  "items-center",
  "font-medium",
  "decoration-none",
  "object-contain",
  "w-48",
  "h-16"
);

const NAV_LINK_CLASSES = classNames(
  "text-darkBrown",
  "font-archivo",
  "font-medium",
  "flex",
  "items-center",
  "px-4",
  "h-full",
  "cursor-pointer",
  "text-sm"
);

const NAV_ITEM_CLASSES = classNames(
  "h-16",
  "hover:border-b-4",
  "border-solid",
  "border-darkBrown"
);

const TOGGLE_MENU_CLASSES = classNames(
  "block",
  "flex-col",
  "absolute",
  "top-0",
  "md:top-[-12px]",
  "right-0",
  "bg-white",
  "p-7",
  "h-[100vh]",
  "w-[70%]",
  "z-40"
);
const TOGGLE_MENU_CLASSES1 = classNames(
  "block",
  "flex-col",
  "absolute",
  "top-[-102vh]",
  "right-0",
  "bg-white",
  "p-7",
  "h-[100vh]",
  "w-[70%]",
  "z-40"
);

interface LinkProps1 {
  item: Route;
  setShowMobileMenu: (value: boolean) => void;
}

interface Route {
  name: string;
  href: string;
}

const otherRoutes: Route[] = [
  {
    name: "About Us",
    href: "/about-us",
  },
  {
    name: "FAQ",
    href: "/faqs",
  },
  {
    name: "Blog",
    href: "/blog",
  },
  {
    name: "Get In Touch",
    href: "/get-in-touch",
  },
];

const NavItem = ({ item, setShowMobileMenu }: LinkProps1) => {
  const location = useLocation();
  const isActive = location.pathname === item.href;

  return (
    <div
      id={item.name}
      className={`${NAV_ITEM_CLASSES} ${isActive && "border-b-4"}`}
    >
      <Link
        to={item.href}
        className={NAV_LINK_CLASSES}
        onClick={() => {
          isActive && setShowMobileMenu(false);
        }}
        aria-current={isActive ? "page" : undefined}
      >
        {item.name}
      </Link>
    </div>
  );
};

export const NavBar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [modal, setModal] = useState(false);
  const [faceAmount, setFaceAmount] = useState(0);
  const [newData, setNewData] = useState("");
  const LocalQuoteData = localStorage.getItem("quoteData");
  const LocalUsageData = localStorage.getItem("usageData");
  const sessionId = new URLSearchParams(window.location.search).get(
    "session_id"
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navBarTop");
      if (navbar) {
        const shouldChangeBackground = window.pageYOffset > 30;
        navbar.style.backgroundColor = shouldChangeBackground
          ? "#FCF8F6"
          : "transparent";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onCalculateNeeds = () => {
    if (LocalQuoteData) {
      if (sessionId) {
        return navigate("/simple-term-life-insurance-calculator?session_id=" + sessionId);
      }
      // else {
      //   return navigate("/calculation-summary");
      // }
    }
    return navigate("/simple-term-life-insurance-calculator");
  };

  const onGetQuote = () => {
    if (LocalUsageData) {
      return navigate("/quotes-summary");
    } else {
      return setModal(true);
      // navigate("/term-life-insurance-quote");
    }
  };

  return (
    <nav
      className={`${NAVBAR_CLASSES} ${location.pathname === "/term-life-insurance-calculator"
        ? "mt-3"
        : "pt-3 pb-[75px]"
        }`}
      id="navBarTop"
    >
      <div className="flex flex-row items-center justify-between px-4 max-w-[1500px] m-auto relative">
        <Link to="/" className={LOGO_CLASSES}>
          <img src={logo} className="w-10/12 md:w-auto" alt="explainLife" />
        </Link>

        {/* The following divs will be wrapped in a flex container for screens smaller than md */}
        <div className="flex flex-row">
          <div className="lg:hidden flex">
            {otherRoutes.map((route: Route, _i) => (
              <NavItem
                item={route}
                key={_i}
                setShowMobileMenu={setShowMobileMenu}
              />
            ))}
          </div>
          <div
            className={`flex duration-300 ${showMobileMenu ? TOGGLE_MENU_CLASSES : TOGGLE_MENU_CLASSES1
              }`}
          >
            <div className="">
              <NavItem
                item={{ name: "Home", href: "/" }}
                setShowMobileMenu={setShowMobileMenu}
              />
            </div>
            <div className="">
              {LocalQuoteData ? (
                <NavItem
                  item={{
                    name: "How much life insurance do I need?1",
                    href: "/simple-term-life-insurance-calculator",
                  }}
                  setShowMobileMenu={setShowMobileMenu}
                />
              ) : (
                <NavItem
                  item={{
                    name: "How much life insurance do I need?",
                    href: "/simple-term-life-insurance-calculator",
                  }}
                  setShowMobileMenu={setShowMobileMenu}
                />
              )}
              {LocalUsageData ? (
                <NavItem
                  item={{
                    name: "Get a quote",
                    href: "/quotes-summary",
                  }}
                  setShowMobileMenu={setShowMobileMenu}
                />
              ) : (
                <NavItem
                  item={{
                    name: "Get a quote",
                    href: "/term-life-insurance-quote",
                  }}
                  setShowMobileMenu={setShowMobileMenu}
                />
              )}
            </div>
            {otherRoutes.map((route: Route, _i) => (
              <NavItem
                item={route}
                key={_i}
                setShowMobileMenu={setShowMobileMenu}
              />
            ))}
          </div>

          <button
            className="hidden lg:block z-50"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            aria-label={showMobileMenu ? "Close menu" : "Open menu"}
          >
            {showMobileMenu ? (
              <XMarkIcon className="w-8 h-8 text-darkBrown font-extrabold" />
            ) : (
              <Bars3Icon className="w-8 h-8 text-darkBrown font-extrabold" />
            )}
          </button>
        </div>
        <div className="flex gap-3 items-center ml-6 lg:hidden">
          {location.pathname !== "/calculation-summary" &&
            location.pathname !== "/term-life-insurance-calculator" &&
            location.pathname !== "/simple-term-life-insurance-calculator" &&
            location.pathname !== "/term-life-insurance-quote" &&
            location.pathname !== "/quotes-summary" && (
              <NavButton variant="primary" onClick={onCalculateNeeds}>
                <div className="flex flex-row gap-2 items-center">
                  How much life insurance do I need?
                  <ChevronRightIcon className="w-4 h-4" />
                </div>
              </NavButton>
            )}
          {location.pathname !== "/term-life-insurance-quote" &&
            location.pathname !== "/quotes-summary" && (
              <NavButton variant="secondary" onClick={onGetQuote}>
                <div className="flex flex-row gap-1 items-center">
                  Get a quote
                  <ChevronRightIcon className="w-4 h-4" />
                </div>
              </NavButton>
            )}
        </div>
      </div>
      <EnterCoverageModal
        show={modal}
        setShow={setModal}
        faceAmount={faceAmount}
        setFaceAmount={setFaceAmount}
        newData={newData}
        setNewData={setNewData}
        isGetQuoteBtn={true}
      />
    </nav>
  );
};
