import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import { NavBar } from "../Components/NavBar";
import { BaseURL } from "../helpers/API/ApiData";
import { ErrorToast } from "../helpers/Toast";
export const capitalizeFirstLetter = (str: string) => {
  return str
    .replaceAll("-", " ")
    .replaceAll("_", " ")
    .replace(/\b\w/g, function (c: any) {
      return c.toUpperCase();
    });
};
const BlogCategory = () => {
  const navigate = useNavigate();
  const { categoryName } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    setLoading(true);
    await axios
      .post(BaseURL + "/user/get/blog", {
        search: categoryName?.replaceAll("-", " "),
      })
      .then((res) => {
        if (res?.data?.data?.getBlog?.length === 0) {
          navigate("/blog");
        }
        setData(res?.data?.data?.getBlog);
        setLoading(false);
      })
      .catch((err) => {
        ErrorToast(err?.response?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const handleBlogPostClick = (blogInfo: any) => {
    const formattedTitle = blogInfo?.title.replaceAll(" ", "-").toLowerCase();
    navigate("/blog/posts/" + blogInfo?._id + "/" + formattedTitle);
  };

  return (
    <>
      <Helmet>
        <title>
          {categoryName
            ? capitalizeFirstLetter(categoryName)
            : "Life Insurance Blog"}{" "}
          | ExplainLife
        </title>
        <meta
          name="description"
          content="ExplainLife is here to help you navigate life! From term life insurance to general life insurance tips, ExplainLife has advice you can count on!"
        />
        <meta
          property="og:title"
          content={
            `${
              categoryName && capitalizeFirstLetter(categoryName)
            } | ExplainLife` || "Life Insurance Blog | ExplainLife"
          }
        />
        <meta
          property="og:description"
          content="ExplainLife is here to help you navigate life! From term life insurance to general life insurance tips, ExplainLife has advice you can count on!"
        />
      </Helmet>
      <NavBar />
      <div className={`${loading ? "opacity-0" : "opacity-100"} duration-700`}>
        <div className="flex text-darkBrown flex-col bg-explainWhite">
          <div className="max-w-[1500px] mx-auto w-full px-16 mt-10 pb-14 md:px-5">
            <div className="mt-20">
              <h1 className="text-6xl lg:text-5xl xs:text-4xl font-judson_italic font-medium text-darkBrown ">
                {categoryName ? capitalizeFirstLetter(categoryName) : ""}
              </h1>
              {/* <p className="mt-5 w-1/2 lg:w-full text-3xl font-judson_italic font-medium text-darkBrown ">
              Everything you ever wanted to know about how to buy a home.
              Whether you're buying a home on the market, or building a brand
              new house, learn how to finance a new home, find the perfect
              place, and make a competitive offer.
            </p> */}
            </div>
          </div>
        </div>
        <div className="w-full bg-explainWhite pt-2">
          <div className="max-w-[1500px] mx-auto w-full pb-14 px-16 md:px-5">
            <div className="w-full lg:mt-5">
              {data?.map((blogDetails: any, ind: number) => {
                return (
                  <>
                    <div
                      className="flex xs:flex-col bg-lightBrown rounded-xl my-5"
                      key={ind}
                    >
                      <div className="p-5 w-[225px] h-[175px] xs:w-auto">
                        <img
                          src={blogDetails?.picture}
                          alt=""
                          className="w-full h-full cursor-pointer rounded-xl object-cover"
                          onClick={() => handleBlogPostClick(blogDetails)}
                        />
                      </div>
                      <div className="p-5 flex-1 border-darkBrown/[.3] ">
                        <h2 className="text-base font-judson_italic border w-fit px-3 border-darkBrown/[.3] ">
                          {blogDetails?.author}
                        </h2>
                        <h2
                          className="text-xl font-judson_italic cursor-pointer font-bold text-darkBrown mt-2"
                          onClick={() => handleBlogPostClick(blogDetails)}
                        >
                          {blogDetails?.title}
                        </h2>
                        <p>{blogDetails?.shortDescription}</p>
                      </div>
                    </div>
                    <hr />
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BlogCategory;
