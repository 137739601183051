import { NavBar } from "../Components/NavBar";
import phone from "../media/img/call.png";
import sale from "../media/img/chart.png";
import support from "../media/img/Music.png";
import lock from "../media/img/loc.png";
import web from "../media/img/website.png";
import Footer from "../Components/Footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const GetInTouch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="flex text-darkBrown flex-col bg-lightBrown ">
        <Helmet>
          <title>Frequently Asked Questions | ExplainLife</title>
          <meta
            name="description"
            content="Find the answers to our most frequently asked questions when it comes to term life insurance. Can't find the answer you're looking for? Call our friendly team."
          />
          <meta
            property="og:title"
            content="Frequently Asked Questions | ExplainLife"
          />
          <meta
            property="og:description"
            content="Find the answers to our most frequently asked questions when it comes to term life insurance. Can't find the answer you're looking for? Call our friendly team."
          />
        </Helmet>
        <NavBar />
        <div className="h-[450px] bg-explainWhite">
          <div className="flex text-darkBrown items-center justify-center mt-32">
            <h1 className="text-4xl  font-judson_italic font-medium text-darkBrown ">
              Get in Touch
            </h1>
          </div>
        </div>
        <div className="bg-lightBrown mb-52">
          <div className="mt-[-230px]">
            <div className="max-w-[1200px] w-full px-16 xs:px-5 mx-auto pb-14">
              <div className="flex md:flex-col h-full rounded-xl px-16 lg:px-5 py-[50px] md:py-[25px] bg-mintGreen mt-10">
                <div className="w-1/2 md:w-full flex flex-col gap-8 md:gap-5">
                  <div className="flex items-center gap-3 lg:gap-1">
                    <div className="px-4 lg:px-0 lg:mr-2 py-2 rounded-full">
                      <img
                        src={phone}
                        className="w-[60px] xs:w-[50px]"
                        alt=""
                      />
                    </div>
                    <div className="grid  gap-2">
                      <h5 className="text-2xl font-judson_italic font-medium text-darkBrown">
                        Phone
                      </h5>
                      <a href="tel:(856) 529-5155">
                        <h2 className="underline decoration-solid xxs:tracking-normal lg:text-sm  tracking-wide font-bold">
                          856-529-5155
                        </h2>
                      </a>
                    </div>
                  </div>
                  <div className="flex items-center gap-3 lg:gap-1">
                    <div className="px-4 lg:px-0 lg:mr-2 py-2 rounded-full">
                      <img src={sale} className="w-[60px] xs:w-[50px]" alt="" />
                    </div>
                    <div className="grid gap-2">
                      <h4 className="text-2xl font-judson_italic font-medium text-darkBrown ">
                        Sales
                      </h4>
                      <a
                        href="mailto:sales@explainlifeinsurance.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex gap-2 md:justify-center font-archivo "
                      >
                        <h2 className="underline  decoration-solid lg:text-sm  tracking-wide xxs:tracking-normal  font-bold">
                          sales@explainlifeinsurance.com
                        </h2>
                      </a>
                    </div>
                  </div>
                  <div className="flex items-center gap-3 lg:gap-1">
                    <div className=" px-4 lg:px-0 lg:mr-2 py-2 rounded-full">
                      <img
                        src={support}
                        className="w-[60px] xs:w-[50px]"
                        alt=""
                      />
                    </div>
                    <div className="grid gap-2">
                      <h4 className="text-2xl font-judson_italic font-medium text-darkBrown ">
                        Customer Support
                      </h4>
                      <a
                        href="mailto:support@explainlifeinsurance.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex gap-2 md:justify-center font-archivo"
                      >
                        <h2 className="underline decoration-solid lg:text-sm  tracking-wide xxs:tracking-normal  font-bold">
                          support@explainlifeinsurance.com
                        </h2>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 md:w-full md:mt-5 flex flex-col gap-8 md:gap-5">
                  <div className="flex items-center gap-3 lg:gap-1">
                    <div className="px-4 lg:px-0 lg:mr-2 py-2 rounded-full">
                      <img src={web} className="w-[60px] xs:w-[50px]" alt="" />
                    </div>
                    <div className="grid gap-2">
                      <h4 className="text-2xl font-judson_italic font-medium text-darkBrown ">
                        Website Support
                      </h4>
                      <a
                        href="mailto:noah@explainlifeinsurance.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex gap-2 md:justify-center font-archivo"
                      >
                        <h2 className="underline decoration-solid lg:text-sm  tracking-wide xxs:tracking-normal  font-bold">
                          noah@explainlifeinsurance.com
                        </h2>
                      </a>
                    </div>
                  </div>
                  <div className="flex items-center gap-3 lg:gap-1">
                    <div className="px-4 lg:px-0 lg:mr-2 py-2 rounded-full">
                      <img src={lock} className="w-[60px] xs:w-[50px]" alt="" />
                    </div>
                    <div className="grid gap-2">
                      <h4 className="text-2xl font-judson_italic font-medium text-darkBrown ">
                        Privacy
                      </h4>
                      <a
                        href="mailto:privacy@explainlifeinsurance.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex gap-2 md:justify-center font-archivo"
                      >
                        <h2 className="underline decoration-solid lg:text-sm  tracking-wide xs:tracking-normal font-bold">
                          privacy@explainlifeinsurance.com
                        </h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default GetInTouch;
