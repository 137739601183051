import { Dialog, Transition } from '@headlessui/react';
import { ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseURL } from '../../helpers/API/ApiData';
import { ErrorToast } from '../../helpers/Toast';
import {
  cleanNumber,
  formatCurrency,
  formatCurrencyWithDecimals,
} from '../../utils/utils';
import { mouseDown, mouseUp } from '../Button';

const INPUT_CLASSES = classNames(
  'w-full',
  'font-medium',
  'text-xl',
  'outline-none',
  'h-7',
  'text-darkBrown',
  'px-2',
  'py-5',
  'outline-none',
  'mb-4',
  'ml-2',
  'xs:ml-0',
  'focus:ring-0',
  'border-b-2',
  'border-darkBrown',
  'bg-transparent',
  'tracking-wider',
  'rounded-none'
);

export const ApplyNowModal = (props: any) => {
  const { show, setShow, preview, setBack9Url, isSplitApply } = props;
  const navigate = useNavigate();
  const [newData, setNewData] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });
  const [isSchedule, setIsSchedule] = useState<any>(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value?.trimStart() });
  };

  let getQuoteFormData: any = localStorage.getItem('getQuoteFormData');
  let getParseQuoteFormData: any = JSON.parse(getQuoteFormData);
  let getUsageData: any = localStorage.getItem('usageData');
  let getParseUsageData: any = JSON.parse(getUsageData);
  let getPersonalDetail: any = sessionStorage.getItem('PersonalDetail');
  let getParsePersonalDetail: any = JSON.parse(getPersonalDetail);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let url = new URL(
      'https://cdn.quoteandapply.io/widget.js?prefill&npn=611574&selected_type=term&step=contact'
    );
    let params = new URLSearchParams(url.search);
    let localNewData: any = getParseQuoteFormData?.newData;
    let email1: any = getParsePersonalDetail?.email || newData?.email;

    params.append('first_name', newData?.first_name);
    params.append('last_name', newData?.last_name);
    params.append('gender', localNewData?.gender);
    params.append('smoker', localNewData?.smoker);

    if (localNewData?.smoker === 'Currently') {
      const smoking_statuses: any = [];
      await getParseQuoteFormData?.inputFields?.forEach((v: any) => {
        smoking_statuses.push({
          frequency: v?.frequency,
          category: v?.category,
        });
      });
      params.append('smoking_statuses', JSON.stringify(smoking_statuses));
    }
    if (localNewData?.smoker === 'Previously') {
      const smoking_statuses: any = [];
      await getParseQuoteFormData?.inputFields?.forEach((v: any) => {
        const parts = v?.last_use_date.split('-');
        const last_use_date = `${parts[2]}-${parts[0]}-${parts[1]}`;
        smoking_statuses.push({
          frequency: v?.frequency,
          category: v?.category,
          last_use_date,
        });
      });
      params.append('smoking_statuses', JSON.stringify(smoking_statuses));
    }
    const parts = localNewData.birthdate.split('-');
    const formattedDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    params.append('birthdate', formattedDate);

    params.append('email', email1);
    params.append('health', localNewData?.health);
    params.append('state', getParseUsageData[1]?.state);
    params.append('application_state', getParseUsageData[1]?.state);
    params.append(
      'product_id',
      preview?.product?.id || preview?.isMerge[0]?.product?.id
    );
    params.append(
      'term_duration',
      preview?.product_category?.split(' ')[0] ||
        preview?.isMerge[0]?.product_category?.split(' ')[0]
    );
    params.append(
      'carriers',
      JSON.stringify([preview?.carrier?.id || preview?.isMerge[0]?.carrier?.id])
    );
    params.append(
      'death_benefit',
      `${cleanNumber(localNewData?.face_amounts)}`
    );
    url.search = params.toString();
    setBack9Url(url.href);
    if (isSplitApply && localNewData) {
      let body = {
        splitYear: {
          firstYear: preview?.isMerge[0]?.product_category,
          firstAmt: preview?.isMerge[0]?.face_amount,
          firstPremium: preview?.isMerge[0]?.premium,
          secondYear: preview?.isMerge[1]?.product_category,
          secondAmt: preview?.isMerge[1]?.face_amount,
          secondPremium: preview?.isMerge[1]?.premium,
        },
        premium: preview?.premium,
        firstName: newData?.first_name,
        lastName: newData?.last_name,
        gender: localNewData?.gender,
        birthDate: localNewData?.birthdate,
        health: localNewData?.health,
        state: getParseUsageData[1]?.state,
        smoker: localNewData?.smoker,
        email: email1,
      };
      await axios
        .post(BaseURL + '/user/add/splitplan', body)
        .catch((err) => ErrorToast(err?.message));
    }
    const bodyIo: any = {
      faceAmount: preview?.isMerge
        ? formatCurrency(
            preview?.isMerge[0]?.face_amount + preview?.isMerge[1]?.face_amount
          )
        : formatCurrency(preview?.face_amount),
      termLength: preview?.isMerge
        ? `${cleanNumber(
            preview?.isMerge[0]?.product_category || '0'
          )} Year / ${cleanNumber(
            preview?.isMerge[1]?.product_category || '0'
          )} Year`
        : `${cleanNumber(preview?.product_category || '0')} Year`,
      monthlyPremium: formatCurrencyWithDecimals(preview?.premium || 0),
      birthDate: localNewData?.birthdate,
      smoker: localNewData?.smoker,
      state: localNewData?.state,
      gender: localNewData?.gender,
      health: localNewData?.health,
      email: email1,
      websiteUrl:
        window.location.host +
        window.location.pathname +
        `?session_id=${sessionStorage.getItem('sessionId')}`,
    };
    if (isSchedule) {
      bodyIo.isSchedule = true;
      await axios
        .post(BaseURL + '/user/marketingio', bodyIo)
        .then(() => {
          window.open('https://calendly.com/adamk-s74');
        })
        .catch((err) => ErrorToast(err?.message));
    } else if (!isSchedule) {
      sessionStorage.setItem('back9Url', JSON.stringify(url.href));
      navigate('/term-life-insurance-application');
    }
    setShow(false);
  };

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog onClose={() => setShow(false)}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/30 z-[1000] flex items-center justify-center'>
              <Dialog.Panel className='w-[640px] max-h-[85vh] overflow-auto transform rounded-2xl bg-mintGreen mx-5 py-5 shadow-xl transition-all'>
                <button
                  type='button'
                  className='box-content rounded-none border-none absolute right-[15px] top-[15px]'
                  onClick={() => setShow(false)}
                >
                  <XMarkIcon className='w-6 h-6' />
                </button>
                <form
                  onSubmit={handleSubmit}
                  className='px-6 flex flex-col'
                  id='applyNowForm'
                >
                  <h2 className='text-darkBrown text-center text-[24px] xs:text-[22px] xs:mt-3 font-medium w-[450px] xs:w-full mx-auto'>
                    Apply Now
                  </h2>
                  {preview?.isMerge && (
                    <p className='mt-3 pr-3 text-center text-base xs:text-sm text-darkBrown'>
                      The next screen will display a different monthly premium
                      than selected. At ExplainLife, our experts will refine the
                      details once your application is submitted.
                    </p>
                  )}
                  <div className='flex gap-3 xs:gap-1 xs:flex-col justify-between my-5'>
                    <input
                      type='text'
                      maxLength={20}
                      className={classNames(INPUT_CLASSES)}
                      placeholder='Enter First Name'
                      name='first_name'
                      value={newData?.first_name}
                      onChange={handleChange}
                      required
                    />
                    <input
                      type='text'
                      maxLength={20}
                      className={classNames(INPUT_CLASSES)}
                      placeholder='Enter Last Name'
                      name='last_name'
                      value={newData?.last_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  {!getParsePersonalDetail?.email && (
                    <input
                      type='text'
                      maxLength={30}
                      className={`${classNames(INPUT_CLASSES)} mt-[-18px]`}
                      placeholder='Enter Email Address'
                      name='email'
                      value={newData?.email}
                      onChange={handleChange}
                      required
                    />
                  )}
                  <div>
                    <p className='mt-3 mb-3 pr-3 text-center text-base xs:text-sm text-darkBrown'>
                      If you would like assistance filling out the application,
                      please schedule a call with Adam Kaminer{' '}
                      <button onClick={() => setIsSchedule(true)}>
                        <a
                          href='https://calendly.com/adamk-s74'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <span className='cursor-pointer underline pl-0.5 font-bold'>
                            here
                          </span>
                        </a>
                      </button>
                      {'.'}
                    </p>
                  </div>
                  <div className='text-center'>
                    <button
                      onPointerDown={mouseDown}
                      onPointerUp={mouseUp}
                      onPointerLeave={mouseUp}
                      onMouseDown={mouseDown}
                      onMouseUp={mouseUp}
                      onMouseLeave={mouseUp}
                      onClick={() => setIsSchedule(false)}
                      className='bg-lightBrown w-fit rounded-full font-medium py-3 pl-8 pr-7 shadow-[4px_4px_0px_#3C2C28] border-2 border-darkBrown duration-75 tracking-wide xs:text-sm'
                    >
                      <div className='flex flex-row gap-2 items-center text-lg'>
                        Next
                        <ChevronRightIcon className='w-4 h-4' />
                      </div>
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
