import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavBar } from "../../Components/NavBar";
import { Faq, faqs } from "./data";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import Footer from "../../Components/Footer";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const DISCLOSURE_BUTTON_CLASSES = classNames(
  "flex",
  "w-full",
  "justify-between",
  "items-center",
  "rounded-lg",
  "px-4",
  "py-2",
  "text-left",
  "text-sm",
  "font-medium",
  "text-darkBrown",
  "focus:outline-none",
  "focus-visible:ring",
  "focus-visible:ring-darkBrown",
  "focus-visible:text-lightBrown"
);

const MyDisclosure = ({ question, answer }: Faq) => {
  return (
    <Disclosure>
      {({ open }) => (
        <div className="border-b-[1px] py-6 border-darkBrown">
          <Disclosure.Button className={DISCLOSURE_BUTTON_CLASSES}>
            <h4 className="font-medium text-base text-darkBrown mr-2">
              {question}
            </h4>
            <span>
              {open ? (
                <AiOutlineMinusCircle
                  className="h-[20px] w-[20px]"
                  fontSize={20}
                />
              ) : (
                <AiOutlinePlusCircle
                  className="h-[20px] w-[20px]"
                  fontSize={20}
                />
              )}
            </span>
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-2 text-sm text-darkBrown">
            {answer.map((paragraph, index) => (
              <p
                key={index}
                className={classNames("font-archivo", index > 0 ? "mt-4" : "")}
              >
                {paragraph}
              </p>
            ))}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};

export const FAQ = () => {
  const [searching, setSearching] = useState("");
  const [searchList, setSearchList] = useState(faqs);

  const handleSearch = (e: any) => {
    setSearching(e.target.value);
    const a = faqs.filter((item, _i) => {
      if (
        item?.question
          ?.toString()
          ?.toLowerCase()
          ?.includes(e.target.value?.toString()?.toLowerCase())
      ) {
        return item;
      } else {
        return false;
      }
    });
    setSearchList(a);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col bg-lightBrown min-h-screen pt-14">
      <Helmet>
        <title>Frequently Asked Questions | ExplainLife</title>
        <meta
          name="description"
          content="Find the answers to our most frequently asked questions when it comes to term life insurance. Can't find the answer you're looking for? Call our friendly team."
        />
        <meta
          property="og:title"
          content="Frequently Asked Questions | ExplainLife"
        />
        <meta
          property="og:description"
          content="Find the answers to our most frequently asked questions when it comes to term life insurance. Can't find the answer you're looking for? Call our friendly team."
        />
      </Helmet>
      <NavBar />
      <div className="flex items-center justify-center bg-lightBrown">
        <div className="text-darkBrown">
          <h3 className="font-archivo font-medium text-center mt-12 text-lg">
            FAQ
          </h3>
          <h1 className="font-judson_italic font-medium flex justify-center text-4xl text-center mt-5">
            Frequently asked questions
          </h1>
          <h3 className="font-medium text-darkBrown text-center mt-5">
            Have questions? We're here to help.
          </h3>
          <div className="flex justify-center mt-5">
            <div className="flex flex-row gap-4 p-2 items-center w-[300px] bg-lightBrown border border-darkBrown/[.17] rounded-lg focus:outline-none mt-5">
              <div className="w-5 h-5">
                <MagnifyingGlassIcon />
              </div>
              <input
                type="text"
                className="bg-lightBrown pr-2 placeholder-darkBrown/[.5] focus:outline-none font-archivo"
                placeholder="Search"
                value={searching}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="flex flex-col gap-0 mx-auto md:w-full w-[675px] rounded-2xl p-4 my-10">
            {searchList.map((faq, _i) => (
              <MyDisclosure
                question={faq.question}
                answer={faq.answer}
                key={_i}
              />
            ))}
          </div>
          <div className="mx-3">
            <div className="bg-mintGreen w-[1000px] lg:w-full mx-auto text-center text-darkBrown rounded-2xl py-12 mb-12">
              <div className="w-full px-5">
                <h2 className="text-2xl font-medium">Still have questions?</h2>
                <p className="text-darkBrown font-archivo mt-4">
                  Can't find the answer you're looking for? Please call our
                  friendly team of experts.
                </p>
                <a
                  href="tel:(856) 529-5155"
                  className="flex gap-2 text-darkBrown mt-4 justify-center font-archivo"
                >
                  <img className="w-[25px]" src="assets/call_svg.svg" alt="" />
                  (856) 529-5155
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};
