import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import ReactPlayer from "react-player";
export const MobileVideoModal = (props: any) => {
  const { show, setShow, videoSrc } = props;

  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog onClose={() => setShow(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30 z-[1000] flex items-center justify-center">
              <Dialog.Panel className="transform rounded-[20px] bg-transparent mx-10 xs:mx-5 transition-all">
                <div
                  className="video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full"
                  onClick={() => {
                    if (isPlaying) {
                      setIsPlaying(false);
                    }
                  }}
                >
                  <ReactPlayer
                    url={videoSrc}
                    playing={isPlaying}
                    className="react-player"
                    playsinline
                  />
                  <button
                    className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                      isPlaying ? "invisible" : "visible"
                    }`}
                    onClick={() => setIsPlaying(!isPlaying)}
                  ></button>
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
