import { API } from "../../config/API/api.config";
import Auth from "../Auth";
import * as authUtil from "../../utils/auth.util";
export const BaseURL = API.endpoint;
export const Bucket = "https://www.amazonaws.com/";
const axios = require("axios").default;
const defaultHeaders = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: true,
  api_key: true,
};

export const ApiPostNoAuth = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        BaseURL + type,
        userData,
        getHttpOptions({ ...defaultHeaders, isAuth: false })
      )
      .then((responseJson) => {
        resolve(responseJson);
        authUtil.setrefrencetoken();
      })
      .catch((error) => {
        reject(error.response.data || error)
      });
  });
};
export const ApiGetNoAuth = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BaseURL + type, getHttpOptions({ ...defaultHeaders, isAuth: false }))
      .then(async (responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const ApiGet_admin = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BaseURL + type, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error.response.data || error)
      });
  });
};
export const ApiGet = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BaseURL + type, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error?.response?.data?.status === 410 ||
          error?.response?.data?.status === 401 ||
          error?.response?.data?.status === 403
        ) {
          localStorage.clear();
          window.location = "/auth/login"
        }
        reject(error.response.data || error)
      });
  });
};
export const ApiPost = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + type, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error?.response?.data?.status === 410 ||
          error?.response?.data?.status === 401 ||
          error?.response?.data?.status === 403
        ) {
          localStorage.clear();
          window.location = "/auth/login"
        }
        reject(error.response.data || error)
      });
  });
};
export const ApiPut = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BaseURL + type, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error?.response?.data?.status === 410 ||
          error?.response?.data?.status === 401 ||
          error?.response?.data?.status === 403
        ) {
          localStorage.clear();
          window.location = "/auth/login"
        }
        reject(error.response.data || error);
      });
  });
};
export const ApiUpload = (type, userData, AdditionalHeader) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + type, userData, {
        ...getHttpOptions(),
        ...AdditionalHeader,
      })
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error.response.data || error)
      });
  });
};
export const ApiDelete = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(BaseURL + type, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error.response.data.status === 410 ||
          error.response.data.status === 401 ||
          error?.response?.data?.status === 403
        ) {
          localStorage.clear();
          window.location = "/auth/login"
        }
        reject(error.response.data || error)
      });
  });
};
export const getHttpOptions = (options = defaultHeaders) => {
  let headers = {};

  if (options.hasOwnProperty("isAuth") && options.isAuth) {
    headers["Authorization"] = Auth.getToken();
    headers["Cache-Control"] = "no-cache";
  }

  if (options.hasOwnProperty("isJsonRequest") && options.isJsonRequest) {
    headers["Content-Type"] = "application/json";
  }

  if (options.hasOwnProperty("AdditionalParams") && options.AdditionalParams) {
    headers = { ...headers, ...options.AdditionalParams };
  }
  return { headers };
};
