export interface Faq {
  question: string;
  answer: string[];
  media?: any;
}

const faq1: Faq = {
  question: "Is life insurance necessary for everyone?",
  answer: [
    `Life insurance is not essential for everyone. Its primary purpose is 
    to provide financial protection for individuals who have dependents, 
    business interests, or outstanding debts. If you are solely responsible 
    for your own well-being, life insurance may not be a high priority. 
    However, if you have a spouse, significant other, business partner, or 
    debts, it is important to consider the potential need for life insurance 
    to ensure their financial security.`,
  ],
};

const faq2: Faq = {
  question: "What type of life insurance is best for me?",
  answer: [
    `For most people, term life insurance is an affordable and suitable option. 
    It provides protection for a specified period (1 to 40 years) and is ideal 
    for those starting to build their financial portfolio or having children. 
    However, if you have specific needs like supporting a disabled child or 
    addressing taxes due at death, a permanent policy might be more appropriate. 
    We recommend consulting with an experienced professional in our office 
    to discuss your individual needs.`,
  ],
};

const faq3: Faq = {
  question: "Do both parents need life insurance?",
  answer: [
    `Whether both parents need life insurance depends on their financial 
    responsibilities and the potential impact of their absence. If both 
    contribute financially, or one provides critical services like childcare, 
    it's worth considering insurance for both. The goal is to maintain the 
    family's lifestyle and meet future needs such as children's 
    education or mortgage payments. However, it's a personal decision 
    that should be made considering the family's unique circumstances.`,
  ],
};

const faq4: Faq = {
  question: "Which carrier should I purchase life insurance from?",
  answer: [
    `For term insurance, it's important to choose a carrier with an A or better AM 
    Best rating, as you're transferring your risk to them. All carriers featured on 
    explainLife's website meet this criterion. Factors that could influence your 
    decision include conversion options, which allow you to change your term policy 
    into a permanent one without additional medical questions. If you think you may 
    convert to a permanent policy in the future, it's best to consult with an 
    experienced agent to discuss the varying conversion options offered by 
    different carriers.`,
  ],
};

const faq5: Faq = {
  question:
    "Are the term rates guaranteed for the entire duration of the chosen term?",
  answer: [
    `Yes, for most carriers, the term rates are guaranteed for the entire duration 
    of your selected term. At explainLife, we feature carriers that provide a 
    fixed premium throughout the entire "locked in" term, ensuring your rates 
    won't change during this period.`,
  ],
};

const faq6: Faq = {
  question: "Are the carriers Licensed in your state?",
  answer: [
    `ExplainLife ensures that we only showcase carriers authorized to provide 
    coverage in your state. Depending on your location, the available carriers 
    may vary. For example, if you're purchasing coverage in New York, the list 
    of carriers may be different from those offered in the other 49 states.`,
  ],
};

const faq7: Faq = {
  question: "Will I need to have a medical exam to get this coverage?",
  answer: [
    `Some carriers offer coverage without requiring a face-to-face medical exam, including 
    blood and urine tests. The need for an exam depends on your application and how 
    recently you've seen a physician. On our quoting page, we indicate whether or not 
    an exam might be required for each carrier. Keep in mind that even without an exam, 
    most carriers will still check your credit score, pharmacy records, and DMV history 
    to assess your risk. As a result, your coverage may be denied if the combination of 
    factors falls outside their risk tolerance, even if you're in perfect health.`,
  ],
};

const faq8: Faq = {
  question:
    "What happens at the end of the guaranteed premium period for my term policy?",
  answer: [
    `At the end of the guaranteed premium period, you typically have the right to continue 
    the policy, but the premiums may become significantly higher. Carriers often assume that 
    if you don't seek coverage elsewhere, you may be a high-risk individual, and they would 
    prefer you to cancel the policy. If you're in good health, it's usually more 
    cost-effective to apply for a new policy with your current insurer or another carrier.`,

    `However, if you know you'll be uninsurable due to a medical condition, you might 
    consider converting your term policy into a permanent one if your carrier offers 
    this option. Keep in mind that the cost of a permanent policy can be 10 to 20 times 
    higher than term insurance.`,
  ],
};

const faq9: Faq = {
  question: "Is Whole Life insurance a good investment?",
  answer: [
    `The suitability of Whole Life insurance as an investment depends on individual 
    circumstances. Whole Life policies have many guaranteed features, but higher 
    premiums reflect these guarantees. After 20-30 years of ownership, some Whole 
    Life policies may project 4-5% earnings (including non-guaranteed dividends) 
    on premium payments. However, this conservative investment depends on the 
    insurance company's asset management performance.`,

    `When considering Whole Life insurance, it's important to choose a quality company 
    with an A+ or better AM Best rating and a history of meeting dividend projections. 
    Additionally, accessing your money from a Whole Life policy involves borrowing 
    from the policy and paying annual non-deductible loan interest, which can affect 
    the death benefit.`,

    `While Whole Life insurance can be a suitable investment for some, it should 
    only comprise a small portion of a diverse investment portfolio.`,
  ],
};

const faq10: Faq = {
  question:
    "What is the difference between Whole Life insurance and Guaranteed Universal Life insurance?",
  answer: [
    `Whole Life insurance is designed to build up cash value within the policy, 
    which reduces the carrier's risk. In a $100,000 Whole Life policy with $50,000 
    cash value, the carrier's true risk is only $50,000. Whole Life policies 
    generally perform better in later years, but not in the early years. However, 
    the death benefit doesn't include the cash value, unless a rider is purchased 
    for an additional premium.`,

    `On the other hand, Guaranteed Universal Life focuses on providing a death benefit 
    rather than building up cash value, resulting in lower premiums. The carrier's 
    risk isn't reduced by cash value accumulation, so if you cancel the policy, 
    you'll receive very little in return. This policy type offers a more 
    cost-effective way to secure a death benefit without focusing on cash value growth.`,
  ],
};

const faq11: Faq = {
  question: "What suggestions do you have for selecting a beneficiary?",
  answer: [
    `We recommend designating individual beneficiaries who are over 18 years old. 
    Changing beneficiaries can be done by the policy owner at any time using a 
    change of beneficiary form. If you name a beneficiary under 18, the carrier will 
    hold the proceeds until they receive documentation from the state, which will 
    appoint a custodian for the funds. The state follows a hierarchy for custodianship, 
    prioritizing parents, followed by grandparents or siblings over 18. To avoid 
    potential complications, it's best to designate adult beneficiaries.`,
  ],
};

const faq12: Faq = {
  question: "What happens when the insured person passes away?",
  answer: [
    `When the insured person dies, the total life insurance coverage amount is paid 
    to the designated beneficiary in a single lump sum. This amount is determined 
    based on the Needs Calculation Summary, which considers factors like annual income 
    needs. It's important to note that the insurance company provides this payment 
    as a one-time lump sum, not as annual payments.`,

    `To receive the specified annual income payment, the beneficiary must invest the 
    remaining lump sum (after paying off the mortgage, other debts, and setting up 
    education accounts) into an investment account at the interest rate specified 
    in the needs calculator. This approach allows the beneficiary to generate the 
    desired annual income.`,
  ],
};

const faq13: Faq = {
  question:
    "Should I include a potential inheritance in my financial planning?",
  answer: [
    `Relying on an inheritance in your financial strategy can be risky. 
    Family dynamics often shift after the loss of a loved one, sometimes leading 
    to unforeseen complications. Issues can arise, such as in-laws attempting 
    to control a surviving spouse financially or disagreements over children's 
    education costs and family activities.`,

    `For these reasons, we advise ensuring that the surviving spouse has adequate 
    resources and autonomy to make decisions for their children's best interest. 
    Financial planning should primarily focus on assets you control directly, 
    reducing dependence on a potential inheritance.`,
  ],
};

const faq14: Faq = {
  question: "How much coverage should I purchase?",
  answer: [
    `Determining the right amount of coverage depends on your unique family and 
    financial situation. During our brief interview, we ask a series of 
    questions to understand your specific circumstances. We may inquire about 
    your income, expenses, debts, savings, investments, and the number of dependents 
    you have. By analyzing this information, we can recommend an appropriate 
    coverage amount that caters to your family's needs, giving you peace of 
    mind that you're making the right decision.`,
  ],
};

const faq15: Faq = {
  question:
    "I already have a basic life insurance policy through my employer, should I maintain it?",
  answer: [
    `Employer-provided life insurance is a great benefit, but it might not provide 
    adequate coverage for your needs. These policies often cover one to two 
    years of your salary, which may not be sufficient for long-term support 
    of dependents or to cover debts and other expenses. Also, this insurance 
    is tied to your employment, so if you change jobs, you may lose coverage.`,

    `During our application process, we ask you to provide the amount of life 
    insurance you currently have. This allows us to help you replace or 
    supplement it with a new policy tailored to your needs. It's advisable 
    to consider an individual life insurance policy for more comprehensive, 
    consistent coverage, based on your personal circumstances.`,
  ],
};

export const faqs: Faq[] = [
  faq1,
  faq2,
  faq3,
  faq4,
  faq5,
  faq6,
  faq7,
  faq8,
  faq9,
  faq10,
  faq11,
  faq12,
  faq13,
  faq14,
  faq15,
];
