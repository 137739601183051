import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useContext } from "react";
import { cleanNumber, formatCurrency } from "../../utils/utils";
import CompanyLogo from "../../media/img/company.png";
import checkLogo from "../../media/img/check.png";
import beneficiaryLogo from "../../media/img/beneficiary.png";
import rightArrow from "../../media/img/rightArrow.png";
import downArrow from "../../media/img/downArrow.png";
import rtlArrow from "../../media/img/rtlArrow.png";
import lcrArrow from "../../media/img/lcrArrow.png";
import adviser from "../../media/img/adviser.png";
import mortgageLogo from "../../media/img/mortgage.png";
import collegeLogo from "../../media/img/college.png";
import fundsLogo from "../../media/img/funds.png";
import verticalArrows from "../../media/img/verticalArrows.png";
import { CalculatorFormContext } from "../../Pages/Calculator/context";

export const CoverageExplanationModal = (props: any) => {
  const { show, setShow, response } = props;
  const { formData }: any = useContext(CalculatorFormContext);
  const totalCollegeExpense =
    cleanNumber(formData?.numInState) * cleanNumber(formData?.inStateCost) +
    cleanNumber(formData?.numPrivate) * cleanNumber(formData?.privateCost);
  const totalDebts =
    cleanNumber(formData?.mortgage) + cleanNumber(formData?.debt);
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog onClose={() => setShow(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30 z-[1000] flex items-center justify-center">
              <Dialog.Panel className="max-w-[640px] max-h-[85vh] overflow-auto transform rounded-2xl bg-mintGreen md:mx-5 py-5 shadow-xl transition-all">
                <button
                  type="button"
                  className="box-content rounded-none border-none absolute right-[15px] top-[15px]"
                  onClick={() => setShow(false)}
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
                <div className="px-5 xs:pt-5 flex flex-col text-darkBrown">
                  <h2 className="text-center font-medium text-[25px] xs:px-0 px-10 mb-5">
                    What should the beneficiary do with the life insurance
                    proceeds?
                  </h2>
                  <div className="flex items-center sm:flex-col mt-3">
                    <div className="w-[195px] h-[80px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[4px_4px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                      <img src={CompanyLogo} height={25} alt="companyLogo" />
                      <div className="mt-1 text-[14px] font-medium text-center">
                        Insurance Company
                      </div>
                    </div>
                    <img
                      src={rightArrow}
                      className="sm:hidden"
                      alt="rightArrow"
                    />
                    <img
                      src={downArrow}
                      className="sm:block hidden"
                      alt="downArrow"
                    />
                    <div className="w-[195px] h-[80px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[4px_4px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                      <img src={checkLogo} height={25} alt="checkLogo" />
                      <div className="mt-1 text-[14px] font-medium text-center">
                        Life Insurance Check
                      </div>
                    </div>
                    <img
                      src={rightArrow}
                      className="sm:hidden"
                      alt="rightArrow"
                    />
                    <img
                      src={downArrow}
                      className="sm:block hidden"
                      alt="downArrow"
                    />
                    <div className="w-[195px] h-[80px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[4px_4px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                      <img
                        src={beneficiaryLogo}
                        height={25}
                        alt="beneficiaryLogo"
                      />
                      <div className="mt-1 text-[14px] font-medium text-center">
                        Beneficiary
                      </div>
                    </div>
                  </div>
                  <div className="self-end pr-[82px] lg:pr-[70px] sm:hidden">
                    <img src={rtlArrow} alt="rtlArrow" />
                  </div>
                  <div className="self-center sm:block hidden">
                    <img src={downArrow} alt="downArrow" />
                  </div>
                  <div className="self-center relative">
                    <div className="w-[195px] h-[80px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[4px_4px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                      <img src={adviser} height={25} alt="adviser" />
                      <div className="mt-1 text-[14px] font-medium text-center">
                        Investment Advisor
                      </div>
                    </div>
                    <img
                      src={verticalArrows}
                      className="sm:block hidden absolute left-[-29px] top-[35px]"
                      alt="verticalArrows"
                    />
                  </div>
                  <div className="self-center">
                    <img src={lcrArrow} className="sm:hidden" alt="lcrArrow" />
                    <img
                      src={downArrow}
                      className="sm:block opacity-0 hidden"
                      alt="downArrow"
                    />
                  </div>
                  <div className="flex items-center sm:flex-col">
                    <div className="w-[195px] h-[100px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[4px_4px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                      <img src={mortgageLogo} height={25} alt="mortgageLogo" />
                      <div className="mt-1 text-[14px] font-medium text-center">
                        Pay Off Mortgage and Debt
                      </div>
                    </div>
                    <img
                      src={rightArrow}
                      className="sm:hidden opacity-0"
                      alt="rightArrow"
                    />
                    <img
                      src={downArrow}
                      className="sm:block hidden opacity-0"
                      alt="downArrow"
                    />
                    <div className="w-[195px] h-[100px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[4px_4px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                      <img src={collegeLogo} height={25} alt="collegeLogo" />
                      <div className="mt-1 text-[14px] font-medium text-center">
                        Save for College and Life Events
                      </div>
                    </div>
                    <img
                      src={rightArrow}
                      className="sm:hidden opacity-0"
                      alt="rightArrow"
                    />
                    <img
                      src={downArrow}
                      className="sm:block hidden opacity-0"
                      alt="downArrow"
                    />
                    <div className="w-[195px] h-[100px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[4px_4px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                      <img src={fundsLogo} height={25} alt="fundsLogo" />
                      <div className="mt-1 text-[14px] font-medium text-center">
                        Invest Remaining Funds
                      </div>
                    </div>
                  </div>
                  <div className="self-end sm:self-center pr-[87px] sm:pr-0">
                    <img src={downArrow} alt="downArrow" />
                  </div>
                  <div className="self-end sm:self-center">
                    <div className="w-[185px] xs:w-[195px] h-[100px] bg-lightBrown border-darkBrown border-2 rounded-lg shadow-[4px_4px_0px_#3C2C28] flex flex-col justify-center items-center p-4">
                      <img src={checkLogo} height={25} alt="checkLogo" />
                      <div className="mt-1 text-[14px] font-medium text-center">
                        Yearly Payment for Income Replacement
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 text-darkBrown">
                    <div className="text-[20px] font-medium">
                      {formData?.insuredIncome
                        ? "Personalized Advice"
                        : "Recommended Advice"}
                    </div>
                    <ol className="list-decimal pl-4 text-xs">
                      <li>
                        Beneficiary receives life insurance check from insurance
                        company for{" "}
                        {formatCurrency(
                          response.length > 0 ? response[1]?.face_amount : "0"
                        )}
                        .
                      </li>
                      <li>
                        Consult an investment advisor at any bank or financial
                        institution.
                      </li>
                      <li>
                        {formData?.mortgage || formData?.debt
                          ? `Use ${formatCurrency(totalDebts)} to pay off
                                mortgage and debt in full.`
                          : "If applicable, pay off any mortgage and debt."}
                      </li>
                      <li>
                        {formData?.numInState &&
                        formData?.inStateCost &&
                        formData?.numPrivate &&
                        formData?.privateCost
                          ? `Put ${formatCurrency(totalCollegeExpense)} in
                                college savings account and ${formatCurrency(
                                  cleanNumber(formData?.oneTime || 0)
                                )} in a saving account for long-term life events.`
                          : "If you have children and intend on contributing to their college education, set up a college savings plan and deposit the desired amount."}
                      </li>
                      <li>
                        Invest the remaining{" "}
                        {formData?.insuredIncome
                          ? formatCurrency(
                              (response.length > 0
                                ? response[1]?.face_amount
                                : 0) -
                                ((formData?.mortgage &&
                                  cleanNumber(formData?.mortgage)) ||
                                  0) -
                                ((formData?.debt &&
                                  cleanNumber(formData?.debt)) ||
                                  0) -
                                (totalCollegeExpense || 0)
                            )
                          : "life insurance proceeds"}{" "}
                        in an investment account that earns 5% annually.
                      </li>
                      <li>
                        Each year, withdraw the annual income your family needs
                        to continue living its current lifestyle from the
                        investment account you financial planner set up. Make
                        sure to adjust for inflation.
                      </li>
                    </ol>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
