import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { CalculatorFormContextProvider } from "./Pages/Calculator/context";
import router from "./Router";

require("tw-elements");

declare global {
  interface Window {
    dataLayer: any[];
  }
}

function App() {
  const sessionId: any = new URLSearchParams(window.location.search).get(
    "session_id"
  );
  const clearLocalStorage = (e: PageTransitionEvent) => {
    if (e.persisted) {
      return;
    }
    localStorage.clear();
    if (!sessionId) {
      Object.keys(sessionStorage).forEach((key) => {
        if (key === "back9Url") {
          return;
        }
        sessionStorage.removeItem(key);
      });
    }
  };

  useEffect(() => {
    window.addEventListener("pagehide", clearLocalStorage);
    return () => {
      window.removeEventListener("pagehide", clearLocalStorage);
    };
  }, []);
  console.error = () => {};
  window.onload = function () {
    console.warn = () => {};
  };

  setInterval(() => {
    console.warn = () => {};
  }, 3000);

  return (
    <div className="App">
      <CalculatorFormContextProvider>
        <RouterProvider router={router} />
      </CalculatorFormContextProvider>
    </div>
  );
}

export default App;
