import React, { useState } from "react";
import ReactPlayer from "react-player";
// const DISCLOSURE_BUTTON_CLASSES = classNames(
//   "flex",
//   "w-full",
//   "justify-between",
//   "items-center",
//   "rounded-lg",
//   "px-4",
//   "py-2",
//   "text-left",
//   "text-sm",
//   "font-medium",
//   "text-darkBrown",
//   "focus:outline-none",
//   "focus-visible:ring",
//   "focus-visible:ring-darkBrown",
//   "focus-visible:text-lightBrown"
// );

export default function AboutInformation() {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <>
      <div className="flex gap-[75px] md:gap-[20px] max-w-[1500px] w-full px-16 md:px-10 xs:px-5 md:flex-col mx-auto py-10 md:pt-5 justify-center">
        <div className="w-1/2 md:w-full pe-5 md:pe-0 py-2 flex items-center">
          <div
            className="video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full"
            onClick={() => {
              if (isPlaying) {
                setIsPlaying(false);
              }
            }}
          >
            <ReactPlayer
              url="https://explain-bucket.s3.amazonaws.com/explainBannerVideos/About+Us.mp4"
              playing={isPlaying}
              className="react-player"
              playsinline
            />
            <button
              className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                isPlaying ? "invisible" : "visible"
              }`}
              onClick={() => setIsPlaying(!isPlaying)}
            ></button>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-6 w-1/3 md:w-full md:mt-3">
          <h2 className="font-judson_italic text-darkBrown text-4xl xs:text-3xl">
            Life Insurance Explained
          </h2>
          <div className="stepsMainDiv flex flex-col gap-5 item-center text-darkBrown w-fit md:w-full">
            <div className="flex items-center gap-5 xs:gap-2">
              <div className="bg-explainWhite inline-block px-4 py-2 rounded-full">
                1
              </div>
              <p className="mt-3">
                Life insurance is a valuable financial tool that safeguards your
                family's well-being by providing financial protection and
                security in the face of unexpected events.
              </p>
            </div>
            <div className="flex items-center gap-5 xs:gap-2">
              <div className="bg-explainWhite inline-block px-4 py-2 rounded-full">
                2
              </div>
              <p className="mt-3">
                Life insurance offers peace of mind, knowing that your loved
                ones will be supported and can maintain their lifestyle, even in
                your absence.
              </p>
            </div>
            <div className="flex items-center gap-5 xs:gap-2">
              <div className="bg-explainWhite inline-block px-4 py-2 rounded-full">
                3
              </div>
              <p className="mt-3">
                Life insurance provides a financial safety net, ensuring that
                your family can cover expenses, such as mortgage payments,
                education costs, and day-to-day living expenses.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
