import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { data, Testimonial } from "./data";

export default function TestimonialCarousel() {
  const settings = {
    slidesToShow: 3,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 1000,
    spaceBetween: 20,
    slidesToScroll: 1,
    // nextArrow: <Arrow next />,
    // prevArrow: <Arrow prev />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="mt-3 xs:px-3">
      <Slider {...settings} className="custom-slick relative">
        {data.map((testimonial: Testimonial, index: number) => (
          <div key={index} className="slider-item px-3">
            <div className="flex flex-col justify-between rounded-lg border border-darkBrown/[.3] p-5 text-darkBrown w-full h-[200px] lg:h-[230px] xs:h-[200px] overflow-auto set-size">
              <p className="text-darkBrown xs:text-sm font-archivo">{testimonial.blurb}</p>
              <div className="flex flex-row items-center">
                <h2 className="text-[18px] xs:text-base font-semibold text-darkBrown font-medium mt-[3px] font-archivo">
                  {testimonial.name + ", " + testimonial.location}
                </h2>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
