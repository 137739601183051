import {
  ArrowRightCircleIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import axios from 'axios';
import classNames from 'classnames';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { BaseURL } from '../helpers/API/ApiData';
import { ErrorToast, SuccessToast } from '../helpers/Toast';
import explainWhiteLogo from '../media/img/explainWhiteLogo.png';
import { PrivacyPolicyModal } from './Modals/PrivacyPolicyModal';

const LINK_CLASSES = classNames(
  'md:text-start',
  'hover:scale-[1.05]',
  'cursor-pointer',
  'duration-100',
  'transform-origin-center',
  'mt-3'
);

interface PrivacyPolicyProps {
  email: string;
  acceptPolicy: boolean;
}

const Footer = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState<PrivacyPolicyProps>({
    email: '',
    acceptPolicy: false,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value.trimStart() });
  };

  const handleSubscribe = async () => {
    if (!newData?.acceptPolicy) {
      ErrorToast('Please accept Privacy&Policy to submit');
      return;
    }
    setModal(false);
    setLoading(true);
    let body = {
      email: newData?.email,
    };
    await axios
      .post(BaseURL + '/user/add/subscribeio', body)
      .then(() => {
        SuccessToast('Subscribed Successfully!');
        setNewData({ email: '', acceptPolicy: false });
        setLoading(false);
      })
      .catch((err) => {
        ErrorToast(err?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <footer>
      <div className='w-full bg-darkBrown'>
        <div className='p-10 xs:px-5 pb-5'>
          <div className='flex text-explainWhite max-w-[1500px] m-auto md:flex-col md:gap-8 items-center md:items-start'>
            <Toaster position='top-center' />
            <div className='flex-1 flex items-center justify-start md:justify-center'>
              <div className=''>
                <div className='w-full mb-3'>
                  <img
                    src={explainWhiteLogo}
                    alt='explainLifeLogo'
                    className='h-[50px] xxs:h-[40px]'
                  />
                </div>
                <div>
                  <a
                    href='tel:(856) 529-5155'
                    className='flex gap-2 mb-3 font-archivo'
                  >
                    <img
                      className='w-[25px]'
                      src='assets/call_svg copy.svg'
                      alt=''
                    />
                    (856) 529-5155
                  </a>
                  <a
                    href='mailto:support@explainlifeinsurance.com'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex gap-2 md:justify-center font-archivo'
                  >
                    <img
                      className='w-[25px]'
                      src='assets/email_svg.svg'
                      alt=''
                    />
                    support@explainlifeinsurance.com
                  </a>
                </div>
              </div>
            </div>
            <div className='flex-1 lg:flex-0'>
              <div className='flex md:gap-14 xs:gap-5 justify-around'>
                <ul>
                  <li className={LINK_CLASSES}>
                    <Link to='/about-us' className='font-archivo'>
                      About Us
                    </Link>
                  </li>
                  <li className={LINK_CLASSES}>
                    <Link to='/faqs' className='font-archivo'>
                      FAQ
                    </Link>
                  </li>
                  <li className={LINK_CLASSES}>
                    <Link to='/blog' className='font-archivo'>
                      Blog
                    </Link>
                  </li>
                  <li className={LINK_CLASSES}>
                    <Link to='/get-in-touch' className='font-archivo'>
                      Get In Touch
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li className={LINK_CLASSES}>
                    <Link
                      to='/simple-term-life-insurance-calculator'
                      className='font-archivo'
                    >
                      How Much Life Insurance Do I Need?
                    </Link>
                  </li>
                  <li className={LINK_CLASSES}>
                    <Link
                      to='/term-life-insurance-quote'
                      className='font-archivo'
                    >
                      Get a quote
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='flex-1 flex justify-center'>
              <div className='flex flex-col gap-3 items-start'>
                <h2 className='text-xl md:flex font-archivo'>Subscribe</h2>
                <div className='flex flex-col justify-between items-start gap-5'>
                  <div className='relative rounded-md bg-[#2F221F] border-none shadow-sm'>
                    <form
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        setModal(true);
                        setNewData({ ...newData, acceptPolicy: false });
                      }}
                    >
                      <div className='flex items-center'>
                        <input
                          type='email'
                          name='email'
                          required
                          placeholder='Email'
                          className='rounded-md block w-full pl-3 pr-[35px] py-2 text-sm bg-transparent placeholder-[#FCF8F6]/[.2] font-semibold outline-none font-archivo'
                          value={newData?.email}
                          onChange={handleChange}
                          autoCorrect='off'
                          autoComplete='off'
                        />
                        <div className='px-2 flex'>
                          {loading ? (
                            <ArrowPathIcon className='w-5 h-5 animate-spin disabled' />
                          ) : (
                            <button type='submit'>
                              <ArrowRightCircleIcon className='w-7 h-7 cursor-pointer' />
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='flex gap-1'>
                    <a
                      href='https://www.facebook.com/profile.php?id=100092438768354'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='w-[30px] rounded-full'
                    >
                      <img
                        src='/assets/facebook.png'
                        title='Facebook'
                        alt='Facebook'
                      />
                    </a>
                    <a
                      href='https://www.instagram.com/explainlife_insurance/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='w-[30px] rounded-full'
                    >
                      <img
                        src='/assets/instagram.png'
                        title='Instagram'
                        alt='Instagram'
                      />
                    </a>
                    <a
                      href='https://www.tiktok.com/@explainlifeinsurance'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='w-[30px] rounded-full'
                    >
                      <img
                        src='/assets/TikTok.png'
                        title='TikTok'
                        alt='TikTok'
                      />
                    </a>
                    <a
                      href='https://www.linkedin.com/company/explainlife-insurance/about/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='w-[30px] rounded-full'
                    >
                      <img
                        src='/assets/linkedin.png'
                        title='TikTok'
                        alt='TikTok'
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='max-w-[1500px] m-auto text-lightBrown/[.2] border-lightBrown/[.4] border-t-[1px] mt-5'>
            <div className='pt-5 flex xs:flex-wrap items-center gap-3 text-xs font-medium text-lightBrown/[.4]'>
              <span className='xs:w-full'>
                Copyright © 2023 ExplainLife. All rights reserved.
              </span>
              <span
                className='pl-3 xs:pl-0 cursor-pointer hover:text-lightBrown'
                onClick={() => navigate('/licenses')}
              >
                Licenses
              </span>
              <span
                className='cursor-pointer hover:text-lightBrown'
                onClick={() => navigate('/privacy')}
              >
                Privacy
              </span>
              <span
                className='cursor-pointer hover:text-lightBrown'
                onClick={() => navigate('/terms')}
              >
                Terms
              </span>
            </div>
            <div className='py-5 xs:pt-3 text-lightBrown/[.4] text-xs'>
              <p>
                Adam Kaminer and Kaminer Financial Group (KFG) provide life
                insurance policies. Adam is a licensed insurance producer,
                authorized to operate in all of the states displayed, and he
                serves as an agent for multiple insurance companies. Adam
                Kaminer's CA License is #OF16572. You can access additional
                state license information{' '}
                <span
                  className='font-bold cursor-pointer underline hover:text-lightBrown'
                  onClick={() => navigate('/licenses')}
                >
                  here
                </span>
                . Please note that certain products and their specific features
                may not be accessible in all states. Additionally, some
                limitations or restrictions may apply.
              </p>
            </div>
          </div>
        </div>
      </div>
      <PrivacyPolicyModal
        show={modal}
        setShow={setModal}
        newData={newData}
        setNewData={setNewData}
        handleSubscribe={handleSubscribe}
      />
    </footer>
  );
};

export default Footer;
