import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { mouseDown, mouseUp } from "../Button";

const conditionalMouseDown = (e: any) => {
  if (!e.target.disabled) {
    mouseDown(e);
  }
};

const conditionalMouseUp = (e: any) => {
  if (!e.target.disabled) {
    mouseUp(e);
  }
};

export const PrivacyPolicyModal = (props: any) => {
  const { show, setShow, newData, setNewData, handleSubscribe } = props;

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog onClose={() => setShow(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30 z-[1000] flex items-center justify-center z-10">
              <Dialog.Panel className="max-w-[640px] max-h-[85vh] overflow-auto transform rounded-2xl bg-lightBrown lg:w-[90w] md:mx-5 py-5 shadow-xl transition-all">
                <button
                  type="button"
                  className="box-content rounded-none border-none absolute right-[15px] top-[15px]"
                  onClick={() => setShow(false)}
                >
                  <XMarkIcon className="w-6 h-6" />
                </button>
                <div className="px-20 md:px-10  flex flex-col ">
                  <div className="h-[330px] w-[500px] xs:w-[380px] sm:w-[300px] md:w-[450px] lg:w-[450px] xxs:w-[295px] text-sm xs:text-start text-justify overflow-auto mt-8 pr-3 custom-scrollbar">
                    <iframe
                      src="https://app.termly.io/document/privacy-policy/86491412-eb27-484d-9690-fb83876f921b"
                      style={{ width: "100%", height: "100%" }} // Subtract the heights of your NavBar and Footer
                      title="Privacy Policy"
                      className="custom-scrollbar"
                    />
                  </div>
                  <div className="mt-5">
                    <input
                      type="checkbox"
                      name=""
                      className="relative float-left mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-darkBrown outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:content-[''] checked:border-darkBrown checked:bg-darkBrown checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent dark:border-neutral-600 dark:checked:border-darkBrown dark:checked:bg-darkBrown"
                      id="Check"
                      checked={newData?.acceptPolicy}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          setNewData({ ...newData, acceptPolicy: true });
                        } else {
                          setNewData({ ...newData, acceptPolicy: false });
                        }
                      }}
                    />
                    <label
                      htmlFor="Check"
                      className="select-none cursor-pointer ml-3"
                    >
                      I accept Privacy Policy.
                    </label>
                  </div>
                  <div className="mt-5">
                    <button
                      onPointerDown={conditionalMouseDown}
                      onPointerUp={conditionalMouseUp}
                      onPointerLeave={conditionalMouseUp}
                      onMouseDown={conditionalMouseDown}
                      onMouseUp={conditionalMouseUp}
                      onMouseLeave={conditionalMouseUp}
                      className="bg-mintGreen w-full rounded-full font-medium  py-3 px-8 xs:px-4 shadow-[4px_4px_0px_#3C2C28] border-2 border-darkBrown duration-75 tracking-wide xs:text-sm"
                      onClick={handleSubscribe}
                      disabled={!newData?.acceptPolicy}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};
