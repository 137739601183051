export const formatCurrency = (value: number) => (
    new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
    }).format(value)
);

export const formatCurrencyWithDecimals = (value: number) => (
    new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
    }).format(value)
);

export const formatPercentWithDecimals = (value: number) => (
    new Intl.NumberFormat("en-US", {
        style: "percent",
        maximumFractionDigits: 2,
    }).format(value)
)

export const formatPercent = (value: number) => (
    new Intl.NumberFormat("en-US", {
        style: "percent",
        maximumFractionDigits: 0,
    }).format(value)
)

export const cleanNumber = (numStr: string) => {
    return Number(numStr?.replace(/[^0-9.]/g, ""));
};

export const cleanPercent = (rateStr: string) => {
    return Number(rateStr?.replace(/[^0-9.]/g, "")) / 100;
};
