import classNames from "classnames";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { CalculatorForm } from "./CalculatorForm";
import { NavBar } from "../../Components/NavBar";

const getStatusBarWidth = (statusBarWidth: number) => {
  switch (statusBarWidth) {
    case 1:
      return "w-1/12";
    case 2:
      return "w-2/12";
    case 3:
      return "w-3/12";
    case 4:
      return "w-4/12";
    case 5:
      return "w-5/12";
    case 6:
      return "w-6/12";
    case 7:
      return "w-7/12";
    case 8:
      return "w-8/12";
    case 9:
      return "w-9/12";
    case 10:
      return "w-10/12";
    case 11:
      return "w-11/12";
    case 12:
      return "w-12/12";
  }
};

const NeedsCalculator = () => {
  const [statusBarWidth, setStatusBarWidth] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col bg-explainWhite h-screen">
      <Helmet>
        <title>Term Life Insurance Calculator | ExplainLife</title>
        <meta
          name="description"
          content="Use our free term life insurance calculator to find out how much coverage you need to ensure your loved ones will be financially secure."
        />
        <meta
          property="og:title"
          content="Term Life Insurance Calculator | ExplainLife"
        />
        <meta
          property="og:description"
          content="Use our free term life insurance calculator to find out how much coverage you need to ensure your loved ones will be financially secure."
        />
      </Helmet>
      <div
        className={classNames(
          "h-2 bg-darkBrown",
          `${getStatusBarWidth(statusBarWidth)}`
        )}
      ></div>
      <NavBar />
      <div className="xs:justify-center bg-explainWhite flex flex-col items-center w-full pb-10">
        <h1 className="hidden">Term Life Insurance Calculator</h1>
        <CalculatorForm
          statusBarWidth={statusBarWidth}
          setStatusBarWidth={setStatusBarWidth}
        />
      </div>
    </div>
  );
};

export default NeedsCalculator;
