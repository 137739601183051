import { useContext, useEffect, useRef, useState } from 'react';
import { NavBar } from '../../Components/NavBar';
import Footer from '../../Components/Footer';
import classNames from 'classnames';
import { cleanNumber, formatCurrency } from '../../utils/utils';
import { NavButton } from '../../Components/Button';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import axios from 'axios';
import { BaseURL } from '../../helpers/API/ApiData';
import { ErrorToast } from '../../helpers/Toast';
import '../../../src/custom.css';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { CalculatorFormContext } from './context';
import { Helmet } from 'react-helmet';
import { InactiveModal } from '../../Components/Modals/InactiveModal';

const INPUT_LABEL_CLASSES = classNames(
  'flex',
  'items-center',
  'text-darkBrown/[.8]',
  'text-[13px]',
  'xs:text-xs'
);

const ASSUMPTION_INPUT_CLASSES = classNames(
  'font-medium',
  'text-lg',
  'text-darkBrown',
  'text-right',
  'font-archivo',
  'font-semibold',
  'px-2',
  'py-1',
  'outline-none',
  'ml-1',
  'sm:ml-0',
  'focus:ring-0',
  'bg-transparent',
  'w-[190px]'
);

const INPUT_CLASSES = classNames(
  'font-medium',
  'text-xl',
  'text-darkBrown',
  'font-archivo',
  'font-semibold',
  'px-2',
  'py-5',
  'outline-none',
  'ml-1',
  'sm:ml-0',
  'focus:ring-0',
  'bg-transparent',
  'w-[190px]'
);

const OnePageCalculator = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  const disclosureRef = useRef<any>(null);
  const [show, setShow] = useState(false);
  const [incomeRight, setIncomeRight] = useState(formatCurrency(0));
  const [collegeEducation, setCollegeEducation] = useState(formatCurrency(0));
  const [expenseRight, setExpenseRight] = useState(formatCurrency(0));
  const [savingRight, setSavingRight] = useState(formatCurrency(0));
  const [price, setPrice] = useState<any>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [isContactFilled, setIsContactFilled] = useState(false);
  const navigate = useNavigate();

  const handleBlur = async (e: any) => {
    window.dataLayer.push({
      event: 'ViewContent',
      content_id: e.target.name,
    });
    getCalculation();
  };

  const getCalculation = async () => {
    let body = {
      inflationRate: cleanNumber(formData['inflationRate']),
      interestRate: cleanNumber(formData['interestRate']),
      insuredIncome: cleanNumber(formData['insuredIncome']),
      beforeTaxYearlySavings: cleanNumber(formData['beforeTaxYearlySavings']),
      afterTaxYearlySavings: cleanNumber(formData['afterTaxYearlySavings']),
      mortgage: cleanNumber(formData['mortgage']),
      spouseIncome: cleanNumber(formData['spouseIncome']),
      taxRate: cleanNumber(formData['taxRate']),
      spouseCoverageAge: cleanNumber(formData['spouseCoverageAge']),
      spouseAge: cleanNumber(formData['spouseAge']),
      spouseIncomeYears: cleanNumber(formData['spouseIncomeYears']),
      debt: cleanNumber(formData['debt']),
      oneTime: cleanNumber(formData['oneTime']),
      savings: cleanNumber(formData['savings']),
      stateName: formData['stateName'] || '',
      isChild: formData['isChild'],
      isMarried: formData['isMarried'],
      youngChild: cleanNumber(formData['youngChild']),
      isChildEducation: formData['isChildEducation'],
      childNum: cleanNumber(formData['childNum']),
      childCost: cleanNumber(formData['childCost']),
    };
    await axios
      .post(BaseURL + '/user/api/data/summary', body)
      .then(({ data }) => {
        setIncomeRight(formatCurrency(data?.data?.incomeNPV));
        setExpenseRight(formatCurrency(data?.data?.expenses));
        setSavingRight(formatCurrency(data?.data?.savings));
        setCollegeEducation(formatCurrency(data?.data?.collageEducation));
        setPrice(data?.data?.calculation);
      })
      .catch((err: any) => {
        ErrorToast('Something went wrong in calculation');
      });
  };

  const getQuote = async () => {
    return setEmailModal(true);
  };

  const phoneNumberBtn = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Contact',
      content_id: 'Contact',
    });
  };

  const scheduleCallBtn = () => {
    window.dataLayer = window.dataLayer || [];
    console.log('Schedule');
    window.dataLayer.push({
      event: 'Schedule',
      content_id: 'Schedule',
    });
  };

  document.addEventListener('click', (event: any) => {
    if (
      disclosureRef.current &&
      !disclosureRef.current.contains(event.target)
    ) {
      if (isOpen) {
        setIsOpen(false);
      }
    }
  });

  useEffect(() => {
    if (isContactFilled) {
      localStorage.setItem(
        'quoteData',
        JSON.stringify({ ...formData, calculation: price })
      );

      sessionStorage.setItem(
        'quoteData',
        JSON.stringify({ ...formData, calculation: price })
      );
      navigate('/term-life-insurance-quote', {
        state: {
          coverage: price,
          formData: { ...formData, calculation: price },
        },
      });
    }
  }, [isContactFilled]);

  useEffect(() => {
    getCalculation();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Term Life Insurance Calculator | ExplainLife</title>
        <meta
          name='description'
          content='Use our simple term life insurance calculator to quickly calculate how much coverage you need to ensure your loved ones will be financially secure.'
        />
        <meta
          property='og:title'
          content='Term Life Insurance Calculator | ExplainLife'
        />
        <meta
          property='og:description'
          content='Use our simple term life insurance calculator to quickly calculate how much coverage you need to ensure your loved ones will be financially secure.'
        />
      </Helmet>
      <NavBar />
      <div className='bg-[#FCF8F6]  px-10 md:px-5 pt-32'>
        <div className='px-20 lg:px-0'>
          <div className='flex flex-row gap-3 text-black rounded lg:flex-col'>
            <div className=' w-3/5 lg:w-full  rounded-md bg-[#EEEAE7] py-2'>
              <div className='flex flex-row items-center justify-between w-full px-6 sm:flex-col'>
                <div className="text-[32px] font-['judson'] italic px-2">
                  Income Replacement
                </div>

                <div
                  className='text-[15px] border-t-1 rounded-[10px] flex relative justify-between items-center w-[325px] sm:w-full h-[46px] py-2 cursor-pointer'
                  ref={disclosureRef}
                >
                  <div className='z-20 py-1 px-3 rounded-lg w-full border absolute top-1  bg-[#EEEAE7] border-darkBrown/[.2]'>
                    <button
                      className='w-full bg-[#EEEAE7]'
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <div className='flex justify-between gap-20'>
                        <div className='text-lg font-medium xs:text-base '>
                          Settings
                        </div>
                        <div className='flex flex-row items-center gap-3'>
                          {isOpen ? (
                            <ChevronUpIcon className='w-5 h-5 text-darkBrown' />
                          ) : (
                            <ChevronDownIcon className='w-5 h-5 text-darkBrown' />
                          )}
                        </div>
                      </div>
                    </button>
                    <div
                      className={`overflow-hidden mt-3 ${!isOpen && 'hidden'}`}
                    >
                      <div className='flex flex-col gap-1 xs:mt-3  z-10 bg-[#EEEAE7]'>
                        <div className='grid gap-2 xs:flex-col xs:gap-1'>
                          <div className='flex flex-row gap-4 w-full justify-between items-center border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
                            <div className='flex flex-row items-center gap-2'>
                              <label
                                htmlFor='inflationRate'
                                className={INPUT_LABEL_CLASSES}
                              >
                                Income Tax Rate
                              </label>
                            </div>
                            <input
                              id='taxRate'
                              placeholder='0%'
                              name='taxRate'
                              value={formData?.taxRate}
                              onChange={handleFormChange}
                              onBlur={handleBlur}
                              className={ASSUMPTION_INPUT_CLASSES}
                              style={{ width: '80px' }}
                              autoComplete='off'
                              autoCorrect='off'
                            ></input>
                          </div>
                          <div className='flex flex-row  gap-4 w-full justify-between items-center border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
                            <div className='flex flex-row items-center gap-2'>
                              <label
                                htmlFor='taxRate'
                                className={INPUT_LABEL_CLASSES}
                              >
                                Inflation rate
                              </label>
                              {/* <SmallInfoTip
                info="We use 30% as the default combined income tax rate. If you know your combined tax rate (including federal, state, and local), please feel free to change the percentage."
                className="bg-lightBrown"
              /> */}
                            </div>
                            <input
                              id='inflationRate'
                              placeholder='0%'
                              name='inflationRate'
                              value={formData?.inflationRate}
                              onChange={handleFormChange}
                              onBlur={handleBlur}
                              className={ASSUMPTION_INPUT_CLASSES}
                              style={{ width: '80px' }}
                              autoComplete='off'
                              autoCorrect='off'
                            ></input>
                          </div>
                          <div className='flex flex-row mt-1 gap-4 w-full justify-between items-center border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
                            <label
                              htmlFor='interestRate'
                              className={INPUT_LABEL_CLASSES}
                            >
                              Average Yearly Interest Rate Earned On Savings
                            </label>
                            <input
                              id='interestRate'
                              placeholder='0%'
                              name='interestRate'
                              value={formData?.interestRate}
                              onChange={handleFormChange}
                              onBlur={handleBlur}
                              className={ASSUMPTION_INPUT_CLASSES}
                              style={{ width: '80px' }}
                              autoComplete='off'
                              autoCorrect='off'
                            ></input>
                          </div>
                          <div className='flex flex-row mt-1 gap-4 w-full justify-between items-center border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
                            <label
                              htmlFor='spouseCoverageAge'
                              className={INPUT_LABEL_CLASSES}
                            >
                              Replacement Income Provided Until Spouse Is Age
                            </label>
                            <input
                              value={formData?.spouseCoverageAge}
                              placeholder='0'
                              name='spouseCoverageAge'
                              id='spouseCoverageAge'
                              onChange={handleFormChange}
                              onBlur={handleBlur}
                              className={ASSUMPTION_INPUT_CLASSES}
                              style={{ width: '80px' }}
                              autoComplete='off'
                              autoCorrect='off'
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className='mt-2 border border-[#D9D2D0] border-t-0' />
              <div className='flex flex-row items-center justify-between w-full px-6 mt-5 mb-6 sm:flex-col md:items-start'>
                <div className='flex items-center justify-center gap-3'>
                  <div className='inline-block px-4 py-2 rounded-full bg-mintGreen'>
                    1
                  </div>
                  <p className='text-[22px] mr-2'>
                    What is your annual income?
                  </p>
                </div>
                <input
                  type='text'
                  placeholder={formatCurrency(0)}
                  name='insuredIncome'
                  onChange={handleFormChange}
                  onBlur={handleBlur}
                  autoComplete='off'
                  autoCorrect='off'
                  value={formData?.insuredIncome}
                  className={`${INPUT_CLASSES} rounded-[10px] flex justify-center items-center h-[46px] sm:w-full border border-[#D9D2D0] border-t-1 sm:mx-auto sm:mt-3 `}
                ></input>
              </div>

              <div className='flex flex-row items-center justify-between w-full px-6 mt-3 mb-6 sm:flex-col md:items-start'>
                <div className='flex items-center justify-center gap-3 sm:justify-start'>
                  <div className='inline-block px-4 py-2 rounded-full bg-mintGreen'>
                    2
                  </div>
                  <p className='text-[22px] mr-2'>Are you married?</p>
                </div>
                <div className='rounded-[10px] flex justify-center md:justify-evenly items-center sm:mx-auto w-[190px] sm:w-full  h-[46px] sm:mt-3   border border-[#D9D2D0] gap-2 border-t-1'>
                  <div className='flex items-center w-full px-3 space-x-2'>
                    <label className='container'>
                      Yes
                      <input
                        type='radio'
                        id='yes'
                        name='isMarried'
                        value='yes'
                        className='w-5 h-5 form-radio text-black-600 custom-radio'
                        checked={formData?.isMarried === true}
                        onChange={(e: any) => {
                          handleFormChange({
                            target: {
                              name: 'isMarried',
                              value: true,
                            },
                          });
                        }}
                        onBlur={handleBlur}
                      />
                      <span className='checkmark'></span>
                    </label>
                    <label className='container'>
                      No
                      <input
                        type='radio'
                        id='no'
                        name='isMarried'
                        value='no'
                        className='w-5 h-5 form-radio text-black-600'
                        checked={!formData?.isMarried}
                        onChange={(e: any) => {
                          handleFormChange({
                            target: {
                              name: 'isMarried',
                              value: false,
                            },
                          });
                        }}
                        onBlur={handleBlur}
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                </div>
              </div>
              {formData?.isMarried === true && (
                <>
                  <div className='flex flex-row items-center justify-between w-full px-6 mt-3 mb-6 sm:flex-col md:items-start'>
                    <div className='flex items-center justify-center gap-3 sm:justify-start'>
                      <div className='inline-block px-4 py-2 rounded-full bg-mintGreen'>
                        3
                      </div>
                      <p className='text-[22px] mr-2'>
                        How old is your spouse?
                      </p>
                    </div>
                    <input
                      type='number'
                      placeholder='0'
                      name='spouseAge'
                      onChange={handleFormChange}
                      onBlur={handleBlur}
                      value={formData?.spouseAge}
                      className={`${INPUT_CLASSES}  rounded-[10px] flex justify-center items-center sm:w-full h-[46px] border border-[#D9D2D0] border-t-1 sm:mt-3`}
                    ></input>
                  </div>
                  <div className='flex flex-row items-center justify-between w-full px-6 mt-3 mb-3 sm:flex-col md:items-start'>
                    <div className='flex items-center justify-center gap-3 '>
                      <div className='inline-block px-4 py-2 rounded-full bg-mintGreen'>
                        4
                      </div>
                      <p className='text-[22px] mr-2'>
                        What is your spouse's annual income?
                      </p>
                    </div>
                    <input
                      type='text'
                      name='spouseIncome'
                      onChange={handleFormChange}
                      onBlur={handleBlur}
                      placeholder={formatCurrency(0)}
                      value={formData?.spouseIncome}
                      className={`${INPUT_CLASSES} text-[15px] border rounded-[10px] flex justify-center items-center sm:mx-auto sm:w-full h-[46px] border border-[#D9D2D0] border-t-1 sm:mt-3 mb-3`}
                    ></input>
                  </div>
                  <div className='flex flex-row items-center justify-between w-full px-6 mt-3 mb-3 sm:flex-col md:items-start'>
                    <div className='flex items-center justify-center gap-3 '>
                      <div className='inline-block px-4 py-2 rounded-full bg-mintGreen'>
                        5
                      </div>
                      <p className='text-[22px] mr-2'>
                        For how many more years does the spouse of the person
                        you'd like to insure plan to work?
                      </p>
                    </div>
                    <input
                      type='text'
                      name='spouseIncomeYears'
                      onChange={handleFormChange}
                      onBlur={handleBlur}
                      placeholder={`0`}
                      value={formData?.spouseIncomeYears}
                      className={`${INPUT_CLASSES} text-[15px] border rounded-[10px] flex justify-center items-center sm:mx-auto sm:w-full h-[46px] border border-[#D9D2D0] border-t-1 sm:mt-3 mb-3`}
                    ></input>
                  </div>
                </>
              )}
              <div className='flex flex-row items-center justify-between w-full px-6 mt-3 mb-6 sm:flex-col md:items-start'>
                <div className='flex items-center justify-center gap-3 sm:justify-start'>
                  <div className='inline-block px-4 py-2 rounded-full bg-mintGreen'>
                    {formData?.isMarried === true ? 6 : 3}
                  </div>
                  <p className='text-[22px] mr-2'>Do you have children?</p>
                </div>
                <div className='  rounded-[10px] flex justify-center md:justify-evenly items-center sm:mx-auto w-[190px] sm:w-full  h-[46px] sm:mt-3   border border-[#D9D2D0] gap-2 border-t-1'>
                  <div className='flex items-center w-full px-3 space-x-2'>
                    <label className='container'>
                      Yes
                      <input
                        type='radio'
                        id='yes'
                        name='isChild'
                        value='yes'
                        className='w-5 h-5 form-radio text-black-600 custom-radio'
                        checked={formData?.isChild === true}
                        onChange={(e: any) => {
                          handleFormChange({
                            target: {
                              name: 'isChild',
                              value: true,
                            },
                          });
                        }}
                        onBlur={handleBlur}
                      />
                      <span className='checkmark'></span>
                    </label>
                    <label className='container'>
                      No
                      <input
                        type='radio'
                        id='no'
                        name='isChild'
                        value='no'
                        className='w-5 h-5 form-radio text-black-600'
                        checked={!formData?.isChild}
                        onChange={(e: any) => {
                          handleFormChange({
                            target: {
                              name: 'isChild',
                              value: false,
                            },
                          });
                        }}
                        onBlur={handleBlur}
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                </div>
              </div>
              {formData?.isChild === true && (
                <>
                  <div className='flex flex-row items-center justify-between w-full px-6 mt-3 mb-6 sm:flex-col'>
                    <div className='flex items-center justify-center gap-3 '>
                      <div className='inline-block px-4 py-2 rounded-full bg-mintGreen'>
                        {formData?.isMarried === true ? 7 : 4}
                      </div>
                      <p className='text-[22px] mr-2'>
                        How old is your youngest child?
                      </p>
                    </div>
                    <input
                      type='text'
                      placeholder={`${cleanNumber('0')}`}
                      name='youngChild'
                      onChange={handleFormChange}
                      onBlur={handleBlur}
                      value={formData?.youngChild}
                      className={`${INPUT_CLASSES} border rounded-[10px] flex justify-center items-center sm:w-full h-[46px]  border-[#D9D2D0] border-t-1 sm:mt-3 `}
                    ></input>
                  </div>
                </>
              )}
              <div className='flex flex-row items-center justify-between w-full px-6 mt-3 mb-6 sm:flex-col'>
                <div className='flex items-center justify-center gap-3 '>
                  <div className='inline-block px-4 py-2 rounded-full bg-mintGreen'>
                    {formData?.isMarried === true && formData?.isChild === true
                      ? 8
                      : formData?.isMarried === true &&
                        formData?.isChild !== true
                      ? 7
                      : formData?.isMarried !== true &&
                        formData?.isChild === true
                      ? 5
                      : 4}
                  </div>
                  <p className='text-[22px] mr-2'>
                    How much do you save per year in your 401k, 403b or IRA?
                  </p>
                </div>
                <input
                  type='text'
                  placeholder={formatCurrency(0)}
                  name='beforeTaxYearlySavings'
                  onChange={handleFormChange}
                  onBlur={handleBlur}
                  value={formData?.beforeTaxYearlySavings}
                  className={`${INPUT_CLASSES} border rounded-[10px] flex justify-center items-center sm:w-full h-[46px]  border-[#D9D2D0] border-t-1 sm:mt-3 `}
                ></input>
              </div>

              <div className='flex flex-row items-center justify-between w-full px-6 mt-3 mb-6 sm:flex-col md:items-start'>
                <div className='flex items-center justify-center gap-3 '>
                  {/* <p className="rounded-full bg-[#8EE1BD] h-[36px] w-[40px] sm:h-[35px] sm:w-[100px] xxl:h-[37px] xxl:w-[60px] text-center text-[24px]">
                    6
                  </p> */}
                  <div className='inline-block px-4 py-2 rounded-full bg-mintGreen'>
                    {formData?.isMarried === true && formData?.isChild === true
                      ? 9
                      : formData?.isMarried === true &&
                        formData?.isChild !== true
                      ? 8
                      : formData?.isMarried !== true &&
                        formData?.isChild === true
                      ? 6
                      : 5}
                  </div>
                  <p className='text-[22px] mr-2'>
                    How much do you save per year in other savings accounts
                    (Roth, college savings, etc)?
                  </p>
                </div>
                <input
                  type='text'
                  placeholder={formatCurrency(0)}
                  name='afterTaxYearlySavings'
                  onChange={handleFormChange}
                  onBlur={handleBlur}
                  value={formData?.afterTaxYearlySavings}
                  className={`${INPUT_CLASSES}  rounded-[10px] sm:w-full h-[46px] border border-[#D9D2D0] border-t-1 sm:mt-3 mb-3 sm:mx-auto`}
                ></input>
              </div>
            </div>

            <div className='w-2/5 lg:w-full rounded-md bg-[#EEEAE7]  py-2'>
              <p
                className={`text-[40px] items-center flex h-full justify-center text-center `}
              >
                + {incomeRight}
              </p>
            </div>
          </div>
        </div>

        {formData?.isChild === true && (
          <div className='px-20 mt-3 mb-3 lg:px-0'>
            <div className='flex flex-row gap-3 text-black rounded lg:flex-col'>
              <div className=' w-3/5 lg:w-full rounded-md bg-[#8EE1BD] py-2'>
                <div className="text-[32px] font-['judson'] italic px-6">
                  College Education
                </div>

                <hr className='mt-2  border border-[#ABABAB] border-t-0' />

                <div className='flex flex-row items-center justify-between w-full px-6 mt-5 mb-6 sm:flex-col '>
                  <div className='flex items-center justify-center gap-3 pr-2 md:pr-0'>
                    <div className='bg-[#ffffff] inline-block px-4 py-2 rounded-full'>
                      1
                    </div>
                    <p className='text-[22px] mr-2'>
                      Would you like to help fund college education for your
                      children?
                    </p>
                  </div>
                  <div className='rounded-[10px] flex justify-start items-center w-[190px]  sm:w-full  border border-[#ABABAB] border-t-1 h-[46px] sm:mt-3 mb-3'>
                    <div className='flex items-center px-3 space-x-3'>
                      <label className='container px-4 sm:pr-14'>
                        Yes
                        <input
                          type='radio'
                          id='yes'
                          name='isChildEducation'
                          value='yes'
                          onChange={(e: any) => {
                            handleFormChange({
                              target: {
                                name: 'isChildEducation',
                                value: true,
                              },
                            });
                          }}
                          onBlur={handleBlur}
                          className='w-5 h-5 form-radio text-black-600'
                          checked={formData?.isChildEducation === true}
                        />
                        <span className='checkmark'></span>
                      </label>
                      <label className='container'>
                        No
                        <input
                          type='radio'
                          id='no'
                          name='isChildEducation'
                          value='no'
                          className='w-5 h-5 form-radio text-black-600'
                          onChange={(e: any) => {
                            handleFormChange({
                              target: {
                                name: 'isChildEducation',
                                value: false,
                              },
                            });
                          }}
                          onBlur={handleBlur}
                          checked={!formData?.isChildEducation}
                        />
                        <span className='checkmark'></span>
                      </label>
                    </div>
                  </div>
                </div>
                {formData?.isChildEducation === true && (
                  <>
                    <div className='flex flex-row items-center justify-between w-full px-6 mt-3 mb-6 sm:flex-col'>
                      <div className='flex items-center justify-start w-10/12 gap-3 sm:w-full'>
                        <div className='bg-[#ffffff] inline-block px-4 py-2 rounded-full'>
                          2
                        </div>
                        <p className='text-[22px] mr-2'>
                          How many children will you be contributing funds for
                          college education?
                        </p>
                      </div>
                      <input
                        type='number'
                        placeholder='0'
                        name='childNum'
                        onChange={handleFormChange}
                        onBlur={handleBlur}
                        value={formData?.childNum}
                        className={`${INPUT_CLASSES}  rounded-[10px] flex justify-center items-center sm:w-full h-[45.438px] border border-[#ABABAB] bg-[#8EE1BD] border-t-1 sm:mt-3 `}
                      ></input>
                    </div>

                    <div className='flex flex-row items-center justify-between w-full px-6 mt-3 mb-6 sm:flex-col'>
                      <div className='flex items-center justify-center gap-3 '>
                        <div className='bg-[#ffffff] inline-block px-4 py-2 rounded-full'>
                          3
                        </div>
                        <p className='text-[22px] mr-2'>
                          How much are you planning to contribute for each
                          child?
                        </p>
                      </div>
                      <input
                        type='text'
                        placeholder={formatCurrency(0)}
                        name='childCost'
                        onChange={handleFormChange}
                        onBlur={handleBlur}
                        value={formData?.childCost}
                        className={`${INPUT_CLASSES}  rounded-[10px] flex justify-center items-center sm:w-full h-[45.438px] border border-[#ABABAB] border-t-1 sm:mt-3 `}
                      ></input>
                    </div>
                  </>
                )}
              </div>

              <div className='w-2/5 lg:w-full rounded-md bg-[#8EE1BD] py-2'>
                <p
                  className={`text-[40px] flex h-full items-center justify-center text-center`}
                >
                  + {collegeEducation}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className='px-20 mt-3 mb-3 lg:px-0'>
          <div className='flex flex-row gap-3 text-white rounded lg:flex-col'>
            <div className=' w-3/5 lg:w-full rounded-md bg-[#3C2C28] py-2'>
              <div className="text-[32px] font-['judson'] italic px-6">
                Expenses
              </div>

              <hr className='mt-2  border border-[#6F6F6F] border-t-0' />

              <div className='flex flex-row items-center justify-between w-full px-6 mt-5 mb-6 sm:flex-col md:items-start'>
                <div className='flex items-center justify-center gap-3 '>
                  <div className='inline-block px-4 py-2 text-black rounded-full bg-mintGreen'>
                    1
                  </div>
                  <p className='text-[22px] mr-2'>
                    What is your outstanding mortgage?
                  </p>
                </div>
                <input
                  type='text'
                  placeholder='$0'
                  name='mortgage'
                  onChange={handleFormChange}
                  onBlur={handleBlur}
                  value={formData?.mortgage}
                  className={`${INPUT_CLASSES} border rounded-[10px] flex justify-center items-center w-[150px] sm:w-full  h-[46px] text-[16px] text-white border-[#6F6F6F] py-3 sm:mt-3 `}
                ></input>
              </div>

              <div className='flex flex-row items-center justify-between w-full px-6 mt-3 mb-6 sm:flex-col'>
                <div className='flex items-center justify-center gap-3 '>
                  <div className='inline-block px-4 py-2 text-black rounded-full bg-mintGreen'>
                    2
                  </div>
                  <p className='text-[22px] mr-2'>
                    Excluding your mortgage, what is your household's
                    outstanding debt?
                  </p>
                </div>
                <input
                  type='text'
                  placeholder='$0'
                  name='debt'
                  onChange={handleFormChange}
                  onBlur={handleBlur}
                  value={formData?.debt}
                  className={`${INPUT_CLASSES} border rounded-[10px] flex justify-center items-center w-[150px] sm:w-full h-[46px] text-[16px] text-white border-[#6F6F6F] py-3 sm:mt-3 `}
                ></input>
              </div>

              <div className='flex flex-row items-center justify-between w-full px-6 mt-3 mb-6 sm:flex-col '>
                <div className='flex items-center justify-center gap-3 '>
                  <div className='inline-block px-4 py-2 text-black rounded-full bg-mintGreen'>
                    3
                  </div>
                  <p className='text-[22px] mr-2'>
                    What is the total amount you'd like to cover for future
                    one-time expenses?
                  </p>
                </div>
                <input
                  type='text'
                  placeholder='$0'
                  name='oneTime'
                  onChange={handleFormChange}
                  onBlur={handleBlur}
                  value={formData?.oneTime}
                  className={`${INPUT_CLASSES} border rounded-[10px] flex justify-center items-center w-[150px] sm:w-full  h-[46px] text-[16px] text-white border-[#6F6F6F] py-3 sm:mt-3 `}
                ></input>
              </div>
            </div>

            <div className='w-2/5 lg:w-full rounded-md bg-[#3C2C28] py-2'>
              {/* <hr className="mt-2 border border-[#6F6F6F]  border-t-0" /> */}
              <p className='text-[40px] flex h-full items-center justify-center text-center'>
                + {expenseRight}
              </p>
            </div>
          </div>
        </div>

        <div className='px-20 mt-3 mb-3 lg:px-0 '>
          <div className='flex flex-row gap-3 text-black rounded lg:flex-col '>
            <div className=' w-3/5 lg:w-full rounded-md bg-[#ffffff]  py-2 border border-black'>
              <div className='text-[32px] font-judson_italic italic px-6'>
                Savings
              </div>

              <hr className='mt-3 mb-3 border border-t-0 border-black' />

              <div className='flex flex-row items-center justify-between w-full px-6 mt-5 mb-6 sm:flex-col md:items-start gap-15'>
                <div className='flex items-center justify-center w-4/5 gap-3 md:w-full'>
                  <div className='inline-block px-4 py-2 rounded-full bg-mintGreen'>
                    1
                  </div>
                  <p className='text-[22px] mr-2'>
                    What is the total value of your household's current savings
                    and investments?
                  </p>
                </div>
                <input
                  type='text'
                  placeholder='$0'
                  onChange={handleFormChange}
                  onBlur={handleBlur}
                  name='savings'
                  value={formData?.savings}
                  className={` ${INPUT_CLASSES}  rounded-[10px] flex justify-center items-center md:mx-auto md:w-full  h-[46px] border border-[#D9D2D0] border-t-1 sm:mx-auto sm:mt-3 md:self-center`}
                ></input>
              </div>
            </div>

            <div className='w-2/5 lg:w-full rounded-md bg-[#ffffff] py-2 flex-col items-center justify-center border border-black'>
              <p className='text-[40px] flex h-full items-center justify-center text-center'>
                - {savingRight}
              </p>
            </div>
          </div>
        </div>
        <div className='px-20 mt-10 mb-3 lg:px-0'>
          <div className='text-[30px] lg:text-[30px] text-center'>
            Total Life Insurance Needed : {formatCurrency(price || 0)}
          </div>
        </div>

        <div className='flex justify-center gap-3 pb-16 mt-5 xs:justify-center xs:items-center '>
          <div className='flex justify-center'>
            <NavButton variant='primary'>
              <button
                className='flex flex-row items-center gap-2'
                onClick={() => setShow(true)}
              >
                Speak to an Agent
                <ChevronRightIcon className='w-4 h-4' />
              </button>
            </NavButton>
          </div>
          <div className='flex justify-center'>
            <NavButton variant='secondary'>
              <div
                className='flex flex-row items-center gap-1'
                onClick={getQuote}
              >
                Get a quote
                <ChevronRightIcon className='w-4 h-4' />
              </div>
            </NavButton>
          </div>
        </div>
      </div>
      <Transition appear show={show} as={Fragment}>
        <Dialog onClose={() => setShow(false)}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/30 z-[1000] flex items-center justify-center'>
              <Dialog.Panel className='max-w-[640px] max-h-[85vh] overflow-auto transform rounded-2xl bg-mintGreen md:mx-5 py-5 shadow-xl transition-all'>
                <button
                  type='button'
                  className='box-content rounded-none border-none absolute right-[15px] top-[15px]'
                  onClick={() => setShow(false)}
                >
                  <XMarkIcon className='w-6 h-6' />
                </button>
                <div className='w-[300px] flex flex-col'>
                  <div className='text-darkBrown text-center text-[24px] xs:text-[22px] xs:mt-3 font-medium w-[450px] mx-auto' />
                  <div className='flex-col items-center justify-center mx-auto mt-5 '>
                    <h2 className='mb-5 font-bold mx-auto flex justify-center items-center text-center text-[20px]'>
                      Speak to an Agent
                    </h2>
                    <a
                      href='tel:(856) 529-5155'
                      className='flex gap-2 items-center font-archivo border border-[#ABABAB] rounded-md px-5 py-3'
                      onClick={phoneNumberBtn}
                    >
                      <img
                        className='w-[25px]'
                        src='assets/call_svg.svg'
                        alt='Call us'
                      />
                      (856) 529-5155
                    </a>
                    <a
                      href='https://calendly.com/adamk-s74/life-insurance'
                      target='_blank'
                      rel='noreferrer noopener'
                      className='flex items-center gap-2 font-archivo mt-5 border border-[#ABABAB] rounded-md px-5 py-3 mb-5'
                      onClick={scheduleCallBtn}
                    >
                      <img
                        className='w-[25px]'
                        src='assets/call_schedule_calendar.svg'
                        alt=''
                      />
                      Schedule a call
                    </a>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
      <Footer />
      <InactiveModal
        fromOnePageCalculator={true}
        setIsContactFilled={setIsContactFilled}
        show={emailModal}
        setShow={setEmailModal}
        preview={{}}
        urlReturnPage='simple-term-life-insurance-calculator'
      />
    </>
  );
};

export default OnePageCalculator;
