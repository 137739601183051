import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { io, Socket } from 'socket.io-client';

export default function Application() {
  const back9Url: any = sessionStorage.getItem('back9Url');
  const eapp_id: any = new URLSearchParams(window.location.search).get(
    'eapp_id'
  );
  const eapp_uuid: any = new URLSearchParams(window.location.search).get(
    'eapp_uuid'
  );
  const ParseBack9Url = JSON.parse(back9Url);

  const containerRef = useRef<HTMLDivElement | null>(null);
  let isInitiate: boolean = false;
  let isSubmit: boolean = false;

  let socket: Socket = io('https://api.explainlifeinsurance.com/socket') || '';
  useEffect(() => {
    if (ParseBack9Url) {
      const existingScript = document.getElementById(
        'strife'
      ) as HTMLScriptElement;
      if (!existingScript || existingScript.src !== ParseBack9Url) {
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
        const script = document.createElement('script');
        script.id = 'strife';
        script.src = ParseBack9Url;
        script.dataset.strifeKey = 'zcc8iIfdVLvH-onv';
        script.dataset.strifeContainerId = 'container-id';
        document.body.appendChild(script);
      }
    } else if (eapp_id && eapp_uuid) {
      const existingScript = document.getElementById(
        'strife'
      ) as HTMLScriptElement;
      if (!existingScript || existingScript.src !== ParseBack9Url) {
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
        const script = document.createElement('script');
        script.id = 'strife';
        script.src = `https://cdn.quoteandapply.io/widget.js?prefill=&npn=611574&eapp_id=${eapp_id}&eapp_uuid=${eapp_uuid}`;
        script.dataset.strifeKey = 'zcc8iIfdVLvH-onv';
        script.dataset.strifeContainerId = 'container-id';
        document.body.appendChild(script);
      }
    }
  }, []);
  useEffect(() => {
    socket?.on('apply_event', (data: any) => {
      if (
        data?.data?.status === 'Lead' &&
        data?.data?.namedStep === 'Address - 25% Complete' &&
        !isInitiate
      ) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'InitiateCheckout',
          content_id: 'InitiateCheckout',
        });
        isInitiate = true;
      }
    });
    socket?.on('case_event', (data: any) => {
      if (data?.data?.status === 'Submitted' && !isSubmit) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'SubmitApplication',
          content_id: 'SubmitApplication',
        });
        isSubmit = true;
      }
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Term Life Insurance Application | ExplainLife</title>
        <meta
          name='description'
          content='Term life insurance is a lot more affordable than you think. Find out what it costs and how to avoid paying for more than you need. Get your quote today.'
        />
        <meta
          property='og:title'
          content='Term Life Insurance Application | ExplainLife'
        />
        <meta
          property='og:description'
          content='Term life insurance is a lot more affordable than you think. Find out what it costs and how to avoid paying for more than you need. Get your quote today.'
        />
      </Helmet>
      <div className='min-h-screen bg-darkBrown bg-cover flex items-center justify-center xl:block xl:pt-20  w-full'>
        <div
          ref={containerRef}
          id='container-id'
          className='relative z-10'
          style={{ display: 'block' }}
        ></div>
      </div>
    </>
  );
}
