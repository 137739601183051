import { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import { useContext } from "react";
import { CalculatorFormContext } from "./context";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { cleanNumber, formatCurrency } from "../../utils/utils";
import axios from "axios";
import { BaseURL } from "../../helpers/API/ApiData";
import { ErrorToast } from "../../helpers/Toast";

const COLLEGE_INPUT_CLASSES = classNames(
  "flex",
  "text-center",
  "font-medium",
  "h-7",
  "text-xl",
  "text-darkBrown",
  "font-archivo",
  "font-semibold",
  "py-5",
  "outline-none",
  "mb-4",
  "bg-explainWhite",
  "focus:ring-0",
  "border-b-2",
  "border-darkBrown",
  "rounded-none",
  "mx-auto"
);

const INPUT_CLASSES = classNames(
  "font-medium",
  "sm:w-full",
  "w-[420px]",
  "h-7",
  "text-xl",
  "text-darkBrown",
  "font-archivo",
  "font-semibold",
  "px-2",
  "py-5",
  "outline-none",
  "ml-1",
  "sm:ml-0",
  "mb-4",
  "focus:ring-0",
  "border-b-2",
  "border-darkBrown",
  "bg-explainWhite",
  "rounded-none"
);

const INPUT_LABEL_CLASSES = classNames(
  "mb-6",
  "text-2xl",
  "sm:text-lg",
  "font-medium",
  "text-black"
);

export const useAutoFocusInput = (inputId: string) => {
  useEffect(() => {
    if (window.innerWidth > 1024) {
      document.getElementById(inputId)?.focus();
    }
  }, [inputId]);
};

export const InsuredIncome = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  const a: any = localStorage.getItem("InsuredSpouseName");
  const InsuredSpouseName = JSON.parse(a);
  useAutoFocusInput("autoFocusId");

  return (
    <div>
      <h1 className={INPUT_LABEL_CLASSES}>
        {InsuredSpouseName?.insuredName
          ? `What is ${InsuredSpouseName?.insuredName}'s annual income?`
          : `What is the annual income of the person you would like to insure?`}
      </h1>
      <input
        value={formData["insuredIncome"]}
        id="autoFocusId"
        name="insuredIncome"
        onChange={handleFormChange}
        placeholder={formatCurrency(0)}
        className={INPUT_CLASSES}
        autoComplete="off"
        autoCorrect="off"
        required
      />
    </div>
  );
};

export const SpouseIncome = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  const a: any = localStorage.getItem("InsuredSpouseName");
  const InsuredSpouseName = JSON.parse(a);
  useAutoFocusInput("autoFocusId");

  return (
    <div>
      <div className="mb-6">
        <h1 className="mb-2 text-2xl sm:text-lg font-medium text-black">
          {InsuredSpouseName?.spouseName
            ? `What is ${InsuredSpouseName?.spouseName}'s annual income?`
            : `What is the annual income of the spouse of the person you'd like to insure?`}
        </h1>
        <h3 className="text-sm text-darkBrown font-archivo">
          If you do not have a spouse, please enter zero.
        </h3>
      </div>
      <input
        value={formData["spouseIncome"]}
        id="autoFocusId"
        name="spouseIncome"
        onChange={handleFormChange}
        placeholder={formatCurrency(0)}
        className={INPUT_CLASSES}
        autoComplete="off"
        autoCorrect="off"
        required
      />
    </div>
  );
};

export const SpouseIncomeYears = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  const a: any = localStorage.getItem("InsuredSpouseName");
  const InsuredSpouseName = JSON.parse(a);
  useAutoFocusInput("autoFocusId");

  return (
    <div>
      <div className="mb-6">
        <h1 className="mb-2 text-2xl sm:text-lg font-medium text-black">
          {InsuredSpouseName?.spouseName
            ? `For how many more years does ${InsuredSpouseName?.spouseName} plan to work?`
            : "For how many more years does the spouse of the person you'd like to insure plan to work?"}
        </h1>
        <h3 className="text-sm text-darkBrown font-archivo">
          {`If you don't want to depend on the ${
            InsuredSpouseName ? InsuredSpouseName?.spouseName : "spouse"
          }'s income if ${
            InsuredSpouseName?.insuredName
              ? InsuredSpouseName?.insuredName
              : "the insured person"
          } passes away, just
          enter zero.`}
        </h3>
      </div>
      <input
        value={formData["spouseIncomeYears"]}
        id="autoFocusId"
        name="spouseIncomeYears"
        onChange={handleFormChange}
        placeholder="0"
        className={INPUT_CLASSES}
        autoComplete="off"
        autoCorrect="off"
        required
      />
    </div>
  );
};

export const ReplacementIncomeYears = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  const a: any = localStorage.getItem("InsuredSpouseName");
  const InsuredSpouseName = JSON.parse(a);
  useAutoFocusInput("autoFocusId");

  return (
    <>
      <div className="w-full">
        <div className="mb-6">
          <h1 className="mb-2 text-2xl sm:text-lg font-medium text-black">
            How old is{" "}
            {InsuredSpouseName?.spouseName
              ? `${InsuredSpouseName?.spouseName}?`
              : "the spouse of the person you'd like to insure?"}
          </h1>
          <h3 className="text-sm text-darkBrown font-archivo">
            If you do not have a spouse, please enter zero.
          </h3>
        </div>
        <input
          value={formData["spouseAge"]}
          id="autoFocusId"
          name="spouseAge"
          onChange={handleFormChange}
          placeholder="Age"
          className={INPUT_CLASSES}
          autoComplete="off"
          autoCorrect="off"
          required
        />
      </div>
    </>
  );
};

export const Mortgage = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  useAutoFocusInput("autoFocusId");

  return (
    <div>
      <div className="mb-6">
        <h1 className="mb-2 text-2xl sm:text-lg font-medium text-black ">
          What is the remaining balance on your mortgage? Please provide your
          best estimate.
        </h1>
        <h3 className="text-sm text-darkBrown font-archivo">
          How much would you need to pay today to completely pay off your
          mortgage? If you rent, please enter zero.
        </h3>
      </div>
      <input
        value={formData["mortgage"]}
        id="autoFocusId"
        name="mortgage"
        onChange={handleFormChange}
        placeholder={formatCurrency(0)}
        className={INPUT_CLASSES}
        autoComplete="off"
        autoCorrect="off"
        required
      />
    </div>
  );
};

export const Debt = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  useAutoFocusInput("autoFocusId");

  return (
    <div>
      <div className="mb-6">
        <h1 className="mb-2 text-2xl sm:text-lg font-medium text-black ">
          What is the total amount of your household's non-mortgage debt?
        </h1>
        <h3 className="text-sm text-darkBrown font-archivo">
          Include debts like student loans, credit card balances, personal
          loans, car loans, and any other outstanding loans or debts.
        </h3>
      </div>
      <input
        value={formData["debt"]}
        id="autoFocusId"
        name="debt"
        onChange={handleFormChange}
        placeholder={formatCurrency(0)}
        className={INPUT_CLASSES}
        autoComplete="off"
        autoCorrect="off"
        required
      />
    </div>
  );
};

export const College = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  const [selectedState, setSelectedState] = useState<any>({
    stateName: formData["stateName"] || "Select State",
  });
  const [stateData, setStateData] = useState<any>([]);
  const [stateAmt, setStateAmt] = useState("");

  useEffect(() => {
    axios
      .get(BaseURL + "/user/get/state")
      .then((res: any) => {
        setStateData(res?.data?.data);
        if (formData?.stateName) {
          res?.data?.data?.forEach((val: any) => {
            if (val?.stateName === formData["stateName"]) {
              handleFormChange({
                target: { name: "inStateCost", value: val?.stateAmount },
              });
            }
          });
        }
      })
      .catch((err: any) => ErrorToast(err?.message));
  }, []);

  useAutoFocusInput("autoFocusId");

  return (
    <div>
      <h1 className="mb-6 xs:mb-2 text-2xl xs:text-lg font-medium text-black sm:w-full w-[480px]">
        {selectedState?.stateName === "Select State"
          ? "What state do you live in?"
          : "How many children would you like to provide college funding for?"}
      </h1>
      <div className="flex xs:flex-col items-end gap-3 xs:gap-5 w-full">
        <div className="relative w-full mb-5">
          <input
            type="text"
            className="opacity-0 absolute"
            value={stateAmt}
            required
          />
          {/* type=text and opacity-0 absolute is used for validation on next button */}
          <Listbox
            value={selectedState}
            name="stateName"
            onChange={(e: any) => {
              setSelectedState(e);
              setStateAmt(e?.stateAmount);
              handleFormChange({
                target: { name: "stateName", value: e?.stateName },
              });
              handleFormChange({
                target: {
                  name: "abbreviation",
                  value: e?.abbreviation,
                },
              });
              handleFormChange({
                target: { name: "inStateCost", value: e?.stateAmount },
              });
            }}
          >
            <Listbox.Button className="relative cursor-pointer rounded-lg bg-transparent w-full focus:outline-none focus-visible:border-none xs:text-sm">
              <span className="block truncate text-left text-xl ml-1 font-semibold font-archivo text-darkBrown border-b-2 border-darkBrown pb-2 px-2">
                {selectedState?.stateName}
              </span>
              <span className="pointer-events-none absolute top-0.5 right-2 flex items-center ml-1">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-darkBrown"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute w-full right-0 z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {stateData?.length > 0 &&
                  stateData?.map((person: any, ind: any) => (
                    <Listbox.Option
                      key={person?._id}
                      value={person}
                      className={({ active }) =>
                        `relative cursor-pointer hover:font-bolder select-none py-2 pl-10 pr-2 ${
                          active
                            ? "bg-lightBrown text-darkBrown"
                            : " text-darkBrown/[1.5]"
                        }`
                      }
                    >
                      <span
                        className={`block truncate font-archivo ${
                          selectedState.stateName === person.stateName &&
                          "font-bold"
                        }`}
                      >
                        {person?.stateName || "-"}
                      </span>
                      {selectedState.stateName === person.stateName && (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-logoBlue">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </Listbox>
        </div>
      </div>
      {selectedState?.stateName !== "Select State" && (
        <table className="w-full mb-4">
          <thead>
            <tr>
              <th></th>
              <th className="font-archivo font-medium text-base xs:text-xs text-darkBrown">
                # Children
              </th>
              <th className="font-archivo font-medium text-base xs:text-xs text-darkBrown">
                <div className="mb-2">
                  Total Cost <br /> Per Child
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="table-row">
              <td>
                <div className="font-medium text-base xs:text-sm">
                  In-State College?
                </div>
                <div className="text-darkBrown font-archivo text-xs">
                  {window.innerWidth < 500 ? "Est. " : "Estimated "}
                  {formatCurrency(cleanNumber(formData["inStateCost"]) / 4)}
                  {"/year"}
                </div>
              </td>
              <td>
                <input
                  value={formData["numInState"]}
                  id="autoFocusId"
                  name="numInState"
                  onChange={handleFormChange}
                  placeholder="0"
                  className={classNames(COLLEGE_INPUT_CLASSES, "w-12")}
                  autoComplete="off"
                  autoCorrect="off"
                  required
                />
              </td>
              <td>
                <input
                  value={formData["inStateCost"]}
                  name="inStateCost"
                  onChange={handleFormChange}
                  placeholder={formatCurrency(0)}
                  className={classNames(COLLEGE_INPUT_CLASSES, "w-32")}
                  autoComplete="off"
                  autoCorrect="off"
                  required
                />
              </td>
            </tr>
            <tr className="xs:block xs:mb-4">
              <td colSpan={3}></td>
            </tr>
            <tr className="table-row">
              <td>
                <div className="font-medium text-base xs:text-sm">
                  Private College?
                </div>
                <div className="text-darkBrown font-archivo text-xs">
                  {window.innerWidth < 500 ? "Est. " : "Estimated "}
                  {formatCurrency(cleanNumber(formData["privateCost"]) / 4)}
                  {"/year"}
                </div>
              </td>
              <td>
                <input
                  value={formData["numPrivate"]}
                  name="numPrivate"
                  onChange={handleFormChange}
                  placeholder="0"
                  className={classNames(COLLEGE_INPUT_CLASSES, "w-12 mx-auto")}
                  autoComplete="off"
                  autoCorrect="off"
                  required
                />
              </td>
              <td>
                <input
                  value={formData["privateCost"]}
                  name="privateCost"
                  onChange={handleFormChange}
                  placeholder={formatCurrency(0)}
                  className={classNames(COLLEGE_INPUT_CLASSES, "w-32 mx-auto")}
                  autoComplete="off"
                  autoCorrect="off"
                  required
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export const OneTime = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  useAutoFocusInput("autoFocusId");

  return (
    <div>
      <div className="mb-6">
        <h1 className="mb-2 text-2xl sm:text-lg font-medium text-black ">
          What is the total amount you'd like to cover for future one-time
          expenses?
        </h1>
        <h3 className="text-sm text-darkBrown font-archivo">
          Think about events such as weddings or religious ceremonies, and
          provide the total cost in today's dollars.
        </h3>
      </div>
      <input
        value={formData["oneTime"]}
        id="autoFocusId"
        name="oneTime"
        onChange={handleFormChange}
        placeholder={formatCurrency(0)}
        className={INPUT_CLASSES}
        autoComplete="off"
        autoCorrect="off"
        required
      />
    </div>
  );
};

export const Savings = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  useAutoFocusInput("autoFocusId");

  return (
    <div>
      <div className="mb-6">
        <h1 className="mb-2 text-2xl sm:text-lg font-medium text-black ">
          What is the total value of your household's current savings and
          investments?
        </h1>
        <h3 className="text-sm text-darkBrown font-archivo">
          This includes savings accounts, investment portfolios, retirement
          accounts, college savings plans, mutual funds, stocks, bonds, and any
          other investment vehicles or financial assets.
        </h3>
      </div>
      <input
        value={formData["savings"]}
        id="autoFocusId"
        name="savings"
        onChange={handleFormChange}
        placeholder={formatCurrency(0)}
        className={INPUT_CLASSES}
        autoComplete="off"
        autoCorrect="off"
        required
      />
    </div>
  );
};

export const YearlySavings = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  useAutoFocusInput("autoFocusId");

  return (
    <div>
      <h1 className={INPUT_LABEL_CLASSES}>
        On your current combined household income, how much do you currently
        save per year?
      </h1>
      <div className="flex flex-col gap-4 mb-2">
        <div className="flex flex-row gap-4 items-center justify-left">
          <div className="flex flex-col">
            <div className="">Retirement Savings:</div>
            <div className="text-xs text-darkBrown font-archivo">
              401k, 403b, IRA
            </div>
          </div>
          <input
            value={formData["beforeTaxYearlySavings"]}
            id="autoFocusId"
            name="beforeTaxYearlySavings"
            onChange={handleFormChange}
            placeholder={formatCurrency(0)}
            className={classNames(INPUT_CLASSES, "w-fit xs:w-full ml-auto")}
            autoComplete="off"
            autoCorrect="off"
            required
          />
        </div>
        <div className="flex flex-row gap-4 items-center justify-left">
          <div className="flex flex-col">
            <div className="">Additional Savings:</div>
            <div className="text-xs text-darkBrown font-archivo">
              Roth, College savings, etc
            </div>
          </div>
          <input
            value={formData["afterTaxYearlySavings"]}
            id="autoFocusId"
            name="afterTaxYearlySavings"
            onChange={handleFormChange}
            placeholder={formatCurrency(0)}
            className={classNames(
              INPUT_CLASSES,
              "w-fit xs:w-full ml-auto mb-0"
            )}
            autoComplete="off"
            autoCorrect="off"
            required
          />
        </div>
      </div>
    </div>
  );
};
