import React, { createContext, useEffect, useState } from "react";
import {
  cleanNumber,
  cleanPercent,
  formatCurrency,
  formatPercent,
  formatPercentWithDecimals,
} from "../../utils/utils";

export const CalculatorFormContext = createContext({
  formData: {
    insuredIncome: "",
    spouseAge: "",
    spouseCoverageAge: "",
    spouseIncome: "",
    spouseIncomeYears: "",
    savings: "",
    beforeTaxYearlySavings: "",
    afterTaxYearlySavings: "",
    mortgage: "",
    oneTime: "",
    debt: "",
    numInState: "",
    inStateCost: "",
    numPrivate: "",
    privateCost: "240000",
    inflationRate: "",
    interestRate: "",
    taxRate: "",
    stateName: "",
    abbreviation: "",
    isChild: false,
    isMarried: false,
    youngChild: "",
    isChildEducation: false,
    childNum: "",
    childCost: "",
  },
  setFormData1: (event: any) => { },
  handleFormChange: (event: any) => { },
});

export const CalculatorFormContextProvider = ({ children }: { children: React.ReactNode }) => {
  const localData: any = localStorage.getItem("quoteData");
  const formData1 = JSON.parse(localData);
  const [formData, setFormData] = useState<any>({
    // insuredIncome: "",
    // spouseAge: "",
    // spouseCoverageAge: "",
    // spouseIncome: "",
    // spouseIncomeYears: "",
    // savings: "",
    // beforeTaxYearlySavings: "",
    // afterTaxYearlySavings: "",
    // mortgage: "",
    // oneTime: "",
    // debt: "",
    // numInState: "",
    // inStateCost: "",
    // numPrivate: "",
    // privateCost: "$240,000",
    // inflationRate: "2.50%",
    // interestRate: "5.00%",
    // taxRate: "30%",
    // stateName: "",
    // abbreviation: "",
    insuredIncome: "",
    spouseAge: "",
    spouseCoverageAge: "",
    spouseIncome: "",
    spouseIncomeYears: "",
    savings: "",
    beforeTaxYearlySavings: "",
    afterTaxYearlySavings: "",
    mortgage: "",
    oneTime: "",
    debt: "",
    inflationRate: "2.50%",
    interestRate: "5.00%",
    taxRate: "30%",
    stateName: "",
    abbreviation: "",
    isChild: false,
    isMarried: false,
    youngChild: "",
    isChildEducation: false,
    childNum: "",
    childCost: "",
  });
  useEffect(() => {
    if (formData1) {
      setFormData({
        insuredIncome: formData1.insuredIncome || "",
        spouseAge: formData1.spouseAge || "",
        spouseCoverageAge: formData1.spouseCoverageAge || "",
        spouseIncome: formData1.spouseIncome || "",
        spouseIncomeYears: formData1.spouseIncomeYears || "",
        savings: formData1.savings || "",
        beforeTaxYearlySavings: formData1.beforeTaxYearlySavings || "",
        afterTaxYearlySavings: formData1.afterTaxYearlySavings || "",
        mortgage: formData1.mortgage || "",
        oneTime: formData1.oneTime || "",
        debt: formData1.debt || "",
        numInState: formData1.numInState || "",
        inStateCost: formData1.inStateCost || "$200,000",
        numPrivate: formData1.numPrivate || "",
        privateCost: formData1.privateCost || "$320,000",
        inflationRate: formData1.inflationRate || "2.00%",
        interestRate: formData1.interestRate || "5.00%",
        taxRate: formData1.taxRate || "30%",
        stateName: formData1.stateName || "",
        abbreviation: formData1.abbreviation || "",

        isChild: formData1.isChild || false,
        isMarried: formData1.isMarried || false,
        youngChild: formData1.youngChild || "",
        isChildEducation: formData1.isChildEducation || false,
        childNum: formData1.childNum || "",
        childCost: formData1.childCost || "",
      });
    }
  }, []);

  const handleFormChange: any = (event: any) => {
    const { name, value } = event.target;
    const nonCurrencyInputs = ["spouseAge", "spouseIncomeYears", "spouseCoverageAge", "numInState", "numPrivate", "youngChild", "childNum"];

    if (nonCurrencyInputs.includes(name)) {
      const numberInput: number = cleanNumber(value);
      if (name === "spouseAge") {
        let spouseCoverageAge = 0;
        if (value <= 35) {
          spouseCoverageAge = 70;
        } else if (value >= 36 && value <= 45) {
          spouseCoverageAge = 73;
        } else if (value >= 46 && value <= 55) {
          spouseCoverageAge = 76;
        } else if (value >= 56 && value <= 65) {
          spouseCoverageAge = 82;
        } else if (value >= 65) {
          spouseCoverageAge = 85;
        }
        setFormData((prevData: any) => ({
          ...prevData,
          [name]: numberInput.toString(),
          spouseCoverageAge: spouseCoverageAge.toString(),
        }));
      } else {
        setFormData((prevData: any) => ({
          ...prevData,
          [name]: numberInput.toString(),
        }));
      }
    } else if (name === "inflationRate" || name === "interestRate") {
      const cleanNum = cleanPercent(value);
      const formattedValue = formatPercentWithDecimals(cleanNum);

      setFormData((prevData: any) => ({ ...prevData, [name]: formattedValue }));
    } else if (name === "taxRate") {
      const cleanNum = cleanPercent(value);
      const formattedValue = formatPercent(cleanNum);

      setFormData((prevData: any) => ({ ...prevData, [name]: formattedValue }));
    } else if (name === "stateName" || name === "abbreviation") {
      setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    } else if (name === "isMarried" || name === "isChild" || name === "isChildEducation") {

      setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    } else {
      const cleanNum = cleanNumber(value);
      const formattedValue = formatCurrency(cleanNum);

      setFormData((prevData: any) => ({ ...prevData, [name]: formattedValue }));
    }
  };
  const setFormData1 = (content: object) => {
    setFormData(content);
  };

  return (
    <CalculatorFormContext.Provider value={{ formData, handleFormChange, setFormData1 }}>
      {children}
    </CalculatorFormContext.Provider>
  );
};
