import { NavBar } from '../Components/NavBar';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { NavButton } from '../Components/Button';
import Footer from '../Components/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseURL } from '../helpers/API/ApiData';
import moment from 'moment';
import { ErrorToast } from '../helpers/Toast';
import { Helmet } from 'react-helmet';

export default function BlogPost() {
  const navigate = useNavigate();
  const { blogId } = useParams();
  const [data, setData] = useState<any>({});
  const [relatedData, setRelatedData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const getRelatedBlog = async (categoryName: string) => {
    await axios
      .post(BaseURL + '/user/get/blog', {
        search: categoryName,
      })
      .then((res) => {
        setRelatedData(res?.data?.data?.getBlog);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const fetchData = async () => {
    if (blogId) {
      setLoading(true);
      await axios
        .get(BaseURL + '/user/blog/' + blogId)
        .then((res) => {
          const a: any = {
            ...res?.data?.data,
            body: res?.data?.data?.body?.replaceAll('<p></p>', '<br/>'),
          };
          setData(a);
          setLoading(false);
          getRelatedBlog(res?.data?.data?.categoryId?.categoryName);
        })
        .catch(async (err) => {
          ErrorToast(err?.response?.data?.message);
          navigate('/blog');
        });
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleRelatedBlogClick = (blogInfo: any) => {
    const formattedTitle = blogInfo?.title
      .replaceAll(' ', '-')
      .toLowerCase()
      .replace('?', '');

    window.location.href =
      '/blog/posts/' + blogInfo?._id + '/' + formattedTitle;
  };

  return (
    <>
      <Helmet>
        <title>{data?.title || 'Life Insurance Blog'} | ExplainLife</title>
        <meta name='description' content={data?.shortDescription || ''} />
        <meta
          property='og:title'
          content={
            data?.title
              ? `${data?.title} | ExplainLife`
              : 'Life Insurance Blog | ExplainLife'
          }
        />
        <meta
          property='og:description'
          content={data?.shortDescription || ''}
        />
      </Helmet>
      <NavBar />
      <div
        className={`duration-700 bg-explainWhite ${
          loading ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <div className='flex flex-col text-darkBrown'>
          <div className='max-w-[1500px] mx-auto w-full px-20 md:px-5 mt-10 pb-14 '>
            <div className='mt-20 '>
              <h1 className='mt-5 text-4xl font-medium font-judson_italic text-darkBrown'>
                {data?.title || '-'}
              </h1>
              <p className='mt-5 '>Written by: {data?.author || '-'}</p>
              <p className='mt-1 '>
                Published {moment(data?.createdAt).format('MMM DD, YYYY')}
              </p>
            </div>
            <img
              src={data?.picture}
              alt=''
              className='object-cover w-full mt-5'
              style={{ height: '600px' }}
            />
          </div>
        </div>

        {data?.shortDescription && (
          <div className='max-w-[1500px] mx-auto w-full px-20  md:px-5 pb-10'>
            <div className='w-full'>
              <div className='flex'>
                <div className='w-5 bg-mintGreen'></div>
                <p
                  className='px-10 py-5 text-xl font-medium font-judson_italic xs:text-lg xs:py-3 xs:px-5 text-darkBrown'
                  style={{ backgroundColor: '#8ee1bd80' }}
                >
                  {data?.shortDescription}
                </p>
              </div>
            </div>
          </div>
        )}

        {data?.headers?.length > 0 && (
          <div className='max-w-[1500px] mx-auto w-full px-20  md:px-5 pb-10'>
            <div className='w-1/2 md:w-full bg-lightBrown'>
              <div className='px-10 py-5'>
                <h2 className='text-4xl font-medium font-judson_italic text-darkBrown '>
                  In this article:
                </h2>
                <ul className='py-5 list-disc'>
                  {data?.headers?.map((v: any, ind: any) => {
                    let __html = v;
                    if (v === "<h2 name=''></h2>") {
                      return null;
                    }
                    return (
                      <li
                        className='my-2 underline cursor-pointer decoration-1'
                        key={ind}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html }}
                          onClick={() => {
                            const a: any = v
                              ?.split("name='")[1]
                              ?.split("'>")[0];
                            const b: any = document.getElementById(a);
                            if (b) {
                              const value: any = b.offsetTop;
                              window.scrollTo(0, value - 90);
                            }
                          }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        )}
        <div>
          <div className='max-w-[1500px] mx-auto w-full px-20 md:px-5 mt-8 normal_HTML'>
            <div dangerouslySetInnerHTML={{ __html: data?.body }} />
            <h2 className='mt-20 text-4xl font-bold font-judson_italic text-darkBrown'>
              Related articles
            </h2>
            <div className='flex gap-10 py-10 lg:flex-wrap lg:justify-center'>
              {relatedData?.length > 0 &&
                relatedData?.slice(0, 3).map((blogDetails: any, ind: any) => {
                  if (data?._id !== blogDetails?._id && ind <= 3) {
                    return (
                      <div
                        className='border border-darkBrown/[.3] w-1/3 lg:w-2/3 xs:w-full md:mx-5 rounded-3xl overflow-hidden'
                        key={ind}
                      >
                        <img
                          src={blogDetails?.picture}
                          alt=''
                          className='w-full object-cover cursor-pointer h-[250px]'
                          onClick={() => handleRelatedBlogClick(blogDetails)}
                        />
                        <h2 className='text-base font-judson_italic border w-fit px-3 border-darkBrown/[.3] mt-5 mx-5'>
                          {blogDetails?.author}
                        </h2>
                        <h2
                          className='px-5 mt-2 text-2xl font-bold cursor-pointer font-judson_italic text-darkBrown'
                          onClick={() => handleRelatedBlogClick(blogDetails)}
                        >
                          {blogDetails?.title || '-'}
                          {''}
                        </h2>
                        <h2 className='px-5 py-5 pb-5 text-xl font-judson_italic text-darkBrown'>
                          {blogDetails?.shortDescription}
                        </h2>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
          <div className='py-10 bg-lightBrown'>
            <div className=' max-w-[1500px] mx-auto w-full px-5  mt-10 pb-14 '>
              <div className='flex justify-center'>
                <h2 className='text-4xl font-bold text-center font-judson_italic text-darkBrown'>
                  Personalized life insurance for your family
                </h2>
              </div>
              <div>
                <p className='flex justify-center mt-5 text-center'>
                  With ExplainLife, secure the proper life insurance for your
                  family. Avoid pushy agents and confusing jargon.
                </p>
              </div>
              <div className='flex justify-center mt-10'>
                <NavButton
                  variant='primary'
                  onClick={() =>
                    navigate('/simple-term-life-insurance-calculator')
                  }
                >
                  <div className='flex flex-row items-center gap-2'>
                    How much life insurance do I need?
                    <ChevronRightIcon className='w-4 h-4' />
                  </div>
                </NavButton>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
