import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CalculatorFormContext } from './context';
import { mouseDown, mouseUp } from '../../Components/Button';
import { NavBar } from '../../Components/NavBar';
import { SmallInfoTip } from '../../Components/InfoTip';
import editSvg from '../../media/img/svg/edit.svg';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/solid';
import { cleanNumber, formatCurrency } from '../../utils/utils';
import { ChangeCoverageModal } from '../../Components/Modals/ChangeCoverageModal';
import { Disclosure, Transition } from '@headlessui/react';
import { BaseURL } from '../../helpers/API/ApiData';
import axios from 'axios';
import { BirthCheckModal } from '../../Components/Modals/BirthCheckModal';
import { EmailThanksModal } from '../../Components/Modals/EmailThanksModal';
import {
  InactiveModal,
  useInactiveTimer,
} from '../../Components/Modals/InactiveModal';
import { ErrorToast } from '../../helpers/Toast';
import ReactPlayer from 'react-player';

const INPUT_CLASSES = classNames(
  'focus:ring-0',
  'outline-none',
  'font-medium',
  'h-6',
  'w-20',
  'ml-auto',
  'text-right',
  'bg-lightBrown',
  'placeholder-darkBrown/[.5]',
  'xs:text-xs'
);

const INPUT_LABEL_CLASSES = classNames(
  'flex',
  'items-center',
  'text-darkBrown/[.8]',
  'text-[13px]',
  'xs:text-xs'
);

const IncomeSummary = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  const {
    insuredIncome,
    spouseIncome,
    spouseIncomeYears,
    beforeTaxYearlySavings,
    afterTaxYearlySavings,
  } = formData;

  return (
    <div className='flex flex-col gap-1'>
      <div className='flex flex-row gap-2 xs:flex-col xs:gap-0 xs:mt-1'>
        <div className='flex items-center pl-2 text-xs font-semibold text-darkBrown'>
          <h4>Insured:</h4>
        </div>
        <div className='flex flex-row gap-4 w-full border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs xs:mt-1'>
          <div className='flex flex-row items-center gap-2'>
            <label htmlFor='insuredIncome' className={INPUT_LABEL_CLASSES}>
              Annual Income
            </label>
          </div>
          <input
            value={insuredIncome}
            placeholder={formatCurrency(0)}
            name='insuredIncome'
            id='insuredIncome'
            onChange={handleFormChange}
            className={INPUT_CLASSES}
            autoComplete='off'
            autoCorrect='off'
          ></input>
        </div>
      </div>
      <div className='flex flex-row gap-2 xs:flex-col xs:gap-0 xs:mt-1'>
        <div className='flex items-center pl-2 text-xs font-semibold text-darkBrown'>
          <h4>Spouse:</h4>
        </div>
        <div className='flex flex-row justify-between w-full gap-2 xs:gap-1 xs:mt-1'>
          <div className='flex flex-row items-center md:w-full w-56 border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
            <div className='flex flex-row items-center gap-2'>
              <label htmlFor='spouseIncome' className={INPUT_LABEL_CLASSES}>
                Annual Income
              </label>
            </div>
            <input
              value={spouseIncome}
              placeholder={formatCurrency(0)}
              name='spouseIncome'
              id='spouseIncome'
              onChange={handleFormChange}
              className={INPUT_CLASSES}
              autoComplete='off'
              autoCorrect='off'
            ></input>
          </div>
          <div className='flex items-center self-center'>x</div>
          <div className='flex flex-row items-center md:w-full w-42 gap-2 border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
            <label htmlFor='spouseIncomeYears' className={INPUT_LABEL_CLASSES}>
              Remaining Work Years
            </label>
            <input
              value={spouseIncomeYears}
              placeholder='0'
              name='spouseIncomeYears'
              id='spouseIncomeYears'
              onChange={handleFormChange}
              className='font-medium h-6 w-8 ml-auto text-right outline-none focus:ring-0 bg-lightBrown placeholder-darkBrown/[.5]'
              autoComplete='off'
              autoCorrect='off'
            ></input>
          </div>
        </div>
      </div>
      <div className='flex flex-row gap-2 xs:flex-col xs:gap-1 xs:mt-1'>
        <div className='flex font-semibold items-center pl-2 text-darkBrown text-xs w-[110px]'>
          <h4>Yearly Savings:</h4>
        </div>
        <div className='flex justify-between w-full gap-2 xs:flex-col xs:gap-1'>
          <div className='flex flex-row gap-4 w-[220px] xs:w-full border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
            <label
              htmlFor='beforeTaxYearlySavings'
              className={INPUT_LABEL_CLASSES}
            >
              Before-tax
            </label>
            <input
              value={beforeTaxYearlySavings}
              placeholder={formatCurrency(0)}
              name='beforeTaxYearlySavings'
              id='beforeTaxYearlySavings'
              onChange={handleFormChange}
              className={INPUT_CLASSES}
              autoComplete='off'
              autoCorrect='off'
            ></input>
          </div>
          <div className='flex flex-row gap-4 w-[220px] xs:w-full border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
            <label
              htmlFor='afterTaxYearlySavings'
              className={INPUT_LABEL_CLASSES}
            >
              After-tax
            </label>
            <input
              value={afterTaxYearlySavings}
              placeholder={formatCurrency(0)}
              name='afterTaxYearlySavings'
              id='afterTaxYearlySavings'
              onChange={handleFormChange}
              className={INPUT_CLASSES}
              autoComplete='off'
              autoCorrect='off'
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExpensesSummary = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  const {
    mortgage,
    debt,
    numInState,
    numPrivate,
    oneTime,
    inStateCost,
    privateCost,
  } = formData;

  return (
    <div className='flex flex-col gap-1 xs:mt-3'>
      <div className='flex flex-row gap-4 w-full border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
        <div className='flex flex-row items-center gap-2'>
          <label htmlFor='mortgage' className={INPUT_LABEL_CLASSES}>
            Outstanding Mortgage
          </label>
          <SmallInfoTip
            info='The Expenses section is calculated assuming that the entirety of your mortgage will be paid off immediately. We recommend our clients do this to mitigate the burden of monthly mortgage payments.'
            className='bg-lightBrown'
          />
        </div>
        <input
          value={mortgage}
          placeholder={formatCurrency(0)}
          name='mortgage'
          id='mortgage'
          onChange={handleFormChange}
          className={INPUT_CLASSES}
          autoComplete='off'
          autoCorrect='off'
        ></input>
      </div>
      <div className='flex flex-row gap-4 w-full border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
        <div className='flex flex-row items-center gap-2'>
          <label htmlFor='debt' className={INPUT_LABEL_CLASSES}>
            Other Debt
          </label>
          <SmallInfoTip
            info='The Expenses section is calculated assuming that all of your debt will be paid off immediately. This might include credit card debt, student loans, etc.'
            className='bg-lightBrown'
          />
        </div>
        <input
          value={debt}
          placeholder={formatCurrency(0)}
          name='debt'
          id='debt'
          onChange={handleFormChange}
          className={INPUT_CLASSES}
          autoComplete='off'
          autoCorrect='off'
        ></input>
      </div>
      <div className='flex flex-row justify-between gap-4 xs:gap-1'>
        <div className='flex flex-row items-center xs:w-full w-64 border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
          <label
            htmlFor='numInState'
            className={classNames(INPUT_LABEL_CLASSES, 'xs:w-fit w-96')}
          >
            # Children Public College
          </label>
          <input
            value={numInState}
            placeholder='0'
            name='numInState'
            id='numInState'
            onChange={handleFormChange}
            className={classNames(INPUT_CLASSES, 'w-8')}
            autoComplete='off'
            autoCorrect='off'
          />
        </div>
        <div className='flex items-center self-center text-lg xs:text-base'>
          x
        </div>
        <div className='flex flex-row items-center xs:w-full w-[202px] gap-2 border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
          <label htmlFor='inStateCost' className={INPUT_LABEL_CLASSES}>
            4-Year Cost
          </label>
          <input
            value={inStateCost}
            name='inStateCost'
            id='inStateCost'
            onChange={handleFormChange}
            className='font-medium h-6 w-[75px] ml-auto text-right outline-none focus:ring-0 bg-lightBrown '
            autoComplete='off'
            autoCorrect='off'
          ></input>
        </div>
      </div>
      <div className='flex flex-row justify-between gap-4 mt-0 xs:gap-1'>
        <div className='flex flex-row items-center xs:w-full w-64 border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
          <label
            htmlFor='numPrivate'
            className={classNames(INPUT_LABEL_CLASSES, 'xs:w-fit w-96')}
          >
            # Children Private College
          </label>
          <input
            value={numPrivate}
            placeholder='0'
            name='numPrivate'
            id='numPrivate'
            onChange={handleFormChange}
            className={classNames(INPUT_CLASSES, 'w-8')}
            autoComplete='off'
            autoCorrect='off'
          />
        </div>
        <div className='flex items-center self-center text-lg xs:text-base'>
          x
        </div>
        <div className='flex flex-row items-center xs:w-full w-[202px] gap-2 border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
          <label htmlFor='privateCost' className={INPUT_LABEL_CLASSES}>
            4-Year Cost
          </label>
          <input
            value={privateCost}
            name='privateCost'
            id='privateCost'
            onChange={handleFormChange}
            className='font-medium h-6 w-[75px] ml-auto text-right outline-none focus:ring-0 bg-lightBrown'
            autoComplete='off'
            autoCorrect='off'
          ></input>
        </div>
      </div>
      <div className='flex flex-row gap-4 w-full border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
        <label htmlFor='oneTime' className={INPUT_LABEL_CLASSES}>
          One Time Expenses
        </label>
        <input
          value={oneTime}
          placeholder={formatCurrency(0)}
          name='oneTime'
          id='oneTime'
          onChange={handleFormChange}
          className={INPUT_CLASSES}
          autoComplete='off'
          autoCorrect='off'
        ></input>
      </div>
    </div>
  );
};

const SavingsSummary = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  const { savings } = formData;

  const totalSavings = cleanNumber(savings);

  return (
    <div className='flex flex-col gap-1 xs:mt-3'>
      <div className='flex flex-row gap-4 w-full border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
        <label htmlFor='savings' className={INPUT_LABEL_CLASSES}>
          Current Savings, Retirement & Investments
        </label>
        <input
          value={savings}
          placeholder={formatCurrency(0)}
          name='savings'
          id='savings'
          onChange={handleFormChange}
          className={INPUT_CLASSES}
          autoComplete='off'
          autoCorrect='off'
        ></input>
      </div>
      <div className='flex flex-row gap-8 ml-auto text-xs text-darkBrown/[.8]'>
        <h4 className='font-semibold text-darkBrown'>Total Savings</h4>
        <div className='pr-2 ml-auto font-semibold text-darkBrown'>{`- $${totalSavings.toLocaleString(
          'en-US'
        )}`}</div>
      </div>
    </div>
  );
};

const Assumptions = () => {
  const { formData, handleFormChange } = useContext(CalculatorFormContext);
  const { inflationRate, interestRate, taxRate } = formData;

  return (
    <div className='flex flex-col gap-1 xs:mt-3'>
      <div className='flex flex-row gap-2 xs:flex-col xs:gap-1'>
        <div className='flex flex-row gap-4 w-full border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
          <div className='flex flex-row items-center gap-2'>
            <label htmlFor='inflationRate' className={INPUT_LABEL_CLASSES}>
              Inflation Rate
            </label>
            <SmallInfoTip
              info='The average inflation rate for the past 25 years is approximately 2.5%.'
              className='bg-lightBrown'
            />
          </div>
          <input
            value={inflationRate}
            name='inflationRate'
            id='inflationRate'
            onChange={handleFormChange}
            className={INPUT_CLASSES}
            autoComplete='off'
            autoCorrect='off'
          ></input>
        </div>
        <div className='flex flex-row gap-4 w-full border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
          <div className='flex flex-row items-center gap-2'>
            <label htmlFor='taxRate' className={INPUT_LABEL_CLASSES}>
              Income Tax Rate
            </label>
            <SmallInfoTip
              info='We use 30% as the default combined income tax rate. If you know your combined tax rate (including federal, state, and local), please feel free to change the percentage.'
              className='bg-lightBrown'
            />
          </div>
          <input
            value={taxRate}
            name='taxRate'
            id='taxRate'
            onChange={handleFormChange}
            className={INPUT_CLASSES}
            autoComplete='off'
            autoCorrect='off'
          ></input>
        </div>
      </div>
      <div className='flex flex-row gap-4 w-full border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
        <label htmlFor='interestRate' className={INPUT_LABEL_CLASSES}>
          Average Yearly Interest Rate Earned On Savings
        </label>
        <input
          value={interestRate}
          name='interestRate'
          id='interestRate'
          onChange={handleFormChange}
          className={INPUT_CLASSES}
          autoComplete='off'
          autoCorrect='off'
        ></input>
      </div>
      <div className='flex flex-row gap-4 w-full border-2 border-darkBrown/[.10] rounded-lg px-2 py-1 text-xs'>
        <label htmlFor='spouseCoverageAge' className={INPUT_LABEL_CLASSES}>
          Replacement Income Provided Until Spouse Is Age
        </label>
        <input
          value={formData['spouseCoverageAge']}
          placeholder='0'
          name='spouseCoverageAge'
          id='spouseCoverageAge'
          onChange={handleFormChange}
          className={INPUT_CLASSES}
          autoComplete='off'
          autoCorrect='off'
        ></input>
      </div>
    </div>
  );
};

export const CalculationSummary = () => {
  const { formData, setFormData1 } = useContext(CalculatorFormContext);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [price, setPrice] = useState<any>(0);
  const [birthCheckModal, setBirthCheckModal] = useState(false);
  const [emailThanksModal, setEmailThanksModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const sessionId = new URLSearchParams(window.location.search).get(
    'session_id'
  );
  const session_personalDetail = sessionStorage.getItem('PersonalDetail');
  const session_fromSession = sessionStorage.getItem('fromSession');
  const session_birthdateCheck: any = sessionStorage.getItem('birthdateCheck');
  const a: any = localStorage.getItem('isShowModalAfterClose');
  const localIsShowModalAfterClose = JSON.parse(a);
  const [isPlaying, setIsPlaying] = useState(false);

  useInactiveTimer(10000, () => {
    if (
      !birthCheckModal &&
      !sessionId &&
      !session_personalDetail &&
      !session_fromSession &&
      !localIsShowModalAfterClose
    ) {
      return setEmailModal(true);
    }
  });

  const getQuote = () => {
    document
      .getElementById('navigateAnimation')
      ?.classList.remove('opacity-100');
    document.getElementById('navigateAnimation')?.classList.add('opacity-0');
    setTimeout(() => {
      localStorage.setItem(
        'quoteData',
        JSON.stringify({ ...formData, calculation: price })
      );
      sessionStorage.setItem(
        'quoteData',
        JSON.stringify({ ...formData, calculation: price })
      );
      if (sessionId) {
        navigate('/term-life-insurance-quote?session_id=' + sessionId, {
          state: {
            coverage: price,
            formData: { ...formData, calculation: price },
          },
        });
      } else {
        navigate('/term-life-insurance-quote', {
          state: {
            coverage: price,
            formData: { ...formData, calculation: price },
          },
        });
      }
    }, 500);
  };

  const [incomeRight, setIncomeRight] = useState(formatCurrency(0));
  const [expenseRight, setExpenseRight] = useState(formatCurrency(0));
  const [savingRight, setSavingRight] = useState(formatCurrency(0));

  const getCalculation = async () => {
    let body = {
      inflationRate: cleanNumber(formData['inflationRate']),
      interestRate: cleanNumber(formData['interestRate']),
      insuredIncome: cleanNumber(formData['insuredIncome']),
      beforeTaxYearlySavings: cleanNumber(formData['beforeTaxYearlySavings']),
      afterTaxYearlySavings: cleanNumber(formData['afterTaxYearlySavings']),
      mortgage: cleanNumber(formData['mortgage']),
      spouseIncome: cleanNumber(formData['spouseIncome']),
      taxRate: cleanNumber(formData['taxRate']),
      spouseCoverageAge: cleanNumber(formData['spouseCoverageAge']),
      spouseAge: cleanNumber(formData['spouseAge']),
      spouseIncomeYears: cleanNumber(formData['spouseIncomeYears']),
      debt: cleanNumber(formData['debt']),
      numInState: cleanNumber(formData['numInState']),
      inStateCost: cleanNumber(formData['inStateCost']),
      numPrivate: cleanNumber(formData['numPrivate']),
      privateCost: cleanNumber(formData['privateCost']),
      oneTime: cleanNumber(formData['oneTime']),
      savings: cleanNumber(formData['savings']),
      stateName: formData['stateName'] || '',
    };
    await axios
      .post(BaseURL + '/user/api/data/summary', body)
      .then(({ data }) => {
        setIncomeRight(formatCurrency(data?.data?.incomeNPV));
        setExpenseRight(formatCurrency(data?.data?.expenses));
        setSavingRight(formatCurrency(data?.data?.savings));
        setPrice(data?.data?.calculation);
      });
  };

  const getCalcSummarySessionData = async () => {
    await axios
      .get(BaseURL + '/user/get/session/' + sessionId)
      .then(async (res) => {
        const data: any = res?.data?.data;
        if (data?.quoteData) {
          setFormData1(data?.quoteData);
          localStorage.setItem('quoteData', JSON.stringify(data?.quoteData));
          let body = {
            inflationRate: cleanNumber(data?.quoteData['inflationRate']),
            interestRate: cleanNumber(data?.quoteData['interestRate']),
            insuredIncome: cleanNumber(data?.quoteData['insuredIncome']),
            beforeTaxYearlySavings: cleanNumber(
              data?.quoteData['beforeTaxYearlySavings']
            ),
            afterTaxYearlySavings: cleanNumber(
              data?.quoteData['afterTaxYearlySavings']
            ),
            mortgage: cleanNumber(data?.quoteData['mortgage']),
            spouseIncome: cleanNumber(data?.quoteData['spouseIncome']),
            taxRate: cleanNumber(data?.quoteData['taxRate']),
            spouseCoverageAge: cleanNumber(
              data?.quoteData['spouseCoverageAge']
            ),
            spouseAge: cleanNumber(data?.quoteData['spouseAge']),
            spouseIncomeYears: cleanNumber(
              data?.quoteData['spouseIncomeYears']
            ),
            debt: cleanNumber(data?.quoteData['debt']),
            numInState: cleanNumber(data?.quoteData['numInState']),
            inStateCost: cleanNumber(data?.quoteData['inStateCost']),
            numPrivate: cleanNumber(data?.quoteData['numPrivate']),
            privateCost: cleanNumber(data?.quoteData['privateCost']),
            oneTime: cleanNumber(data?.quoteData['oneTime']),
            savings: cleanNumber(data?.quoteData['savings']),
          };
          await axios
            .post(BaseURL + '/user/api/data/summary', body)
            .then(({ data }) => {
              setIncomeRight(formatCurrency(data?.data?.incomeNPV));
              setExpenseRight(formatCurrency(data?.data?.expenses));
              setSavingRight(formatCurrency(data?.data?.savings));
              setPrice(data?.data?.calculation);
            });
        }
      })
      .catch((err) => {
        ErrorToast(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById('navigateAnimation')
        ?.classList.remove('opacity-0');
      document
        .getElementById('navigateAnimation')
        ?.classList.add('opacity-100');
    }, 100);
    getCalculation();

    if (sessionId) {
      if (!JSON.parse(session_birthdateCheck)) {
        return setBirthCheckModal(true);
      } else {
        getCalcSummarySessionData();
      }
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ViewContent',
      content_id: 'CalculationSummary',
    });
  }, []);

  return (
    <>
      <div className='h-2 bg-darkBrown w-12/12'></div>
      <Helmet>
        <title>Your Term Life Insurance Summary | ExplainLife</title>
        <meta
          name='description'
          content="When we evaluate your term life insurance needs, our calculator takes your family's unique needs into consideration."
        />
        <meta
          property='og:title'
          content='Your Term Life Insurance Summary | ExplainLife'
        />
        <meta
          property='og:description'
          content="When we evaluate your term life insurance needs, our calculator takes your family's unique needs into consideration."
        />
      </Helmet>
      <NavBar />
      <div
        className='relative flex w-full duration-500 opacity-0 lg:flex-col'
        id='navigateAnimation'
      >
        <nav
          aria-label='Pagination'
          className='fixed bottom-0 right-0 flex flex-col gap-2 mb-6 ml-auto mr-6 sm:mr-2'
        >
          <button
            onClick={() => {
              if (sessionId) {
                navigate(
                  '/term-life-insurance-calculator?session_id=' + sessionId
                );
              } else {
                navigate('/term-life-insurance-calculator');
              }
            }}
            className={classNames(
              'flex justify-center items-center bg-darkBrown w-8 h-8 text-white rounded-md'
            )}
          >
            <ChevronUpIcon className='w-4 h-4' />
          </button>
          <button
            className={classNames(
              'flex justify-center items-center bg-darkBrown w-8 h-8 text-white rounded-md'
            )}
            onClick={getQuote}
          >
            <ChevronDownIcon className='w-4 h-4' />
          </button>
        </nav>
        <div className='w-full min-h-[calc(100vh-10px)] flex flex-col justify-center pt-[100px] bg-lightBrown'>
          <div className='w-[768px] md:w-full mx-auto sm:px-5'>
            <div
              className='video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full '
              onClick={() => {
                if (isPlaying) {
                  setIsPlaying(false);
                }
              }}
            >
              <ReactPlayer
                url='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Calculation+Summary.mp4'
                playing={isPlaying}
                className='react-player'
                playsinline
              />
              <button
                className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                  isPlaying ? 'invisible' : 'visible'
                }`}
                onClick={() => setIsPlaying(!isPlaying)}
              ></button>
            </div>
            <div className='flex w-[550px] md:w-[90vw] mx-auto flex-col gap-2 mt-10 xs:gap-0'>
              <div className='flex justify-between text-xl font-medium xs:flex-col xs:gap-2'>
                <h2 className='font-semibold sm:mx-5 xs:mx-0 xs:text-lg'>
                  How much life insurance do I need ?
                </h2>
                <div className='flex pe-[8px]'>
                  <div className='mx-2 text-xl font-semibold'>
                    {formatCurrency(price)}
                  </div>
                  <img
                    src={editSvg}
                    alt=''
                    className='w-5 xs:w-4 h-5 xs:h-4 mt-[6px] text-darkBrown/[.2] cursor-pointer'
                    onClick={() => setModal(true)}
                  />
                </div>
              </div>
              <div className='text-end xs:text-start xs:mt-3'>
                <button
                  onPointerDown={mouseDown}
                  onPointerUp={mouseUp}
                  onPointerLeave={mouseUp}
                  onMouseDown={mouseDown}
                  onMouseUp={mouseUp}
                  onMouseLeave={mouseUp}
                  className='inline-block text-lg xs:text-[17px] self-start bg-mintGreen py-[7px] px-[30px] rounded-full font-medium shadow-[4px_4px_0px_#3C2C28] border-2 border-darkBrown mt-2 xs:mt-0 mb-5 tracking-wide duration-75'
                  onClick={getQuote}
                >
                  <div className='flex flex-row items-center gap-2'>
                    Ready for a Quote?
                    <ChevronRightIcon className='w-4 h-4' />
                  </div>
                </button>
              </div>
            </div>
            <hr className='border-darkBrown/[.3] mb-5' />
            <div className='summary-div ml-[110px] mr-[110px] sm:mx-5  xs:text-lg'>
              <div className='mb-2 text-sm text-darkBrown'>
                <span className='font-semibold'>Note</span>: Click to expand and
                edit section breakdowns
              </div>
              <div className='relative'>
                <div className='p-3 rounded-lg border border-darkBrown/[.2] overflow-hidden'>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className='w-full'>
                          <div className='flex justify-between'>
                            <h3 className='text-lg font-medium xs:text-base'>
                              Income Summary
                            </h3>
                            <div className='flex flex-row items-center gap-3'>
                              <div className='text-xl font-medium xs:text-lg '>
                                {'+ ' + incomeRight}
                              </div>
                              {open ? (
                                <ChevronUpIcon className='w-5 h-5 text-darkBrown' />
                              ) : (
                                <ChevronDownIcon className='w-5 h-5 text-darkBrown' />
                              )}
                            </div>
                          </div>
                        </Disclosure.Button>
                        <div className='overflow-hidden'>
                          <Transition
                            enter='transition-opacity duration-300 ease-out'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='transition-opacity duration-200 ease-in'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Disclosure.Panel className='pt-5'>
                              <IncomeSummary />
                              <div className='flex justify-end xs:justify-between flex-row gap-8 xs:w-full xs:gap-1 ml-auto text-xs text-darkBrown/[.8] mt-1'>
                                <div className='flex flex-row gap-2 xs:gap-0.5 items-center'>
                                  <h4 className='xs:pl-[5px] text-darkBrown font-semibold'>
                                    Net Present Value of Income Need
                                  </h4>
                                  <SmallInfoTip
                                    info="This value reflects the amount of money needed to replace the insured's income contribution to the household in the event of their demise. 
            ExplainLife assumes a federal tax rate and annual inflation rate, but please feel free to edit those assumptions in the dropdown below."
                                    className='bg-lightBrown xs:ml-1'
                                  />
                                </div>
                                <div className='font-semibold text-darkBrown'>
                                  {'+ ' + incomeRight}
                                </div>
                              </div>
                              <button
                                className='bg-darkBrown text-center text-white text-base rounded-full mt-3 py-1.5 px-4 cursor-pointer'
                                onClick={getCalculation}
                              >
                                Recalculate
                              </button>
                            </Disclosure.Panel>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Disclosure>
                </div>
                <div className='mt-3 p-3 rounded-lg border border-darkBrown/[.2]'>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className='w-full'>
                          <div className='flex justify-between'>
                            <h3 className='text-lg font-medium xs:text-base'>
                              Expenses Summary
                            </h3>
                            <div className='flex flex-row items-center gap-3'>
                              <div className='text-xl font-medium xs:text-lg '>
                                {'+ ' + expenseRight}
                              </div>
                              {open ? (
                                <ChevronUpIcon className='w-5 h-5 text-darkBrown' />
                              ) : (
                                <ChevronDownIcon className='w-5 h-5 text-darkBrown' />
                              )}
                            </div>
                          </div>
                        </Disclosure.Button>
                        <div className='overflow-hidden'>
                          <Transition
                            enter='transition-opacity duration-300 ease-out'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='transition-opacity duration-200 ease-in'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Disclosure.Panel className='pt-5'>
                              <ExpensesSummary />
                              <div className='flex flex-row justify-end my-1 gap-8 ml-auto text-darkBrown/[.8] text-xs'>
                                <h4 className='font-semibold text-darkBrown'>
                                  Total Expenses
                                </h4>
                                <div className='pr-2 font-semibold text-darkBrown'>
                                  {'+ ' + expenseRight}
                                </div>
                              </div>
                              <button
                                className='bg-darkBrown text-center text-white text-base rounded-full md:mt-3 py-1.5 px-4 cursor-pointer'
                                onClick={getCalculation}
                              >
                                Recalculate
                              </button>
                            </Disclosure.Panel>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Disclosure>
                </div>
                <div className='mt-3 p-3 rounded-lg border border-darkBrown/[.2]'>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className='w-full'>
                          <div className='flex justify-between'>
                            <h3 className='text-lg font-medium xs:text-base'>
                              Savings Summary
                            </h3>
                            <div className='flex flex-row items-center gap-3'>
                              <div className='text-xl font-medium xs:text-lg '>
                                {'- ' + savingRight}
                              </div>
                              {open ? (
                                <ChevronUpIcon className='w-5 h-5 text-darkBrown' />
                              ) : (
                                <ChevronDownIcon className='w-5 h-5 text-darkBrown' />
                              )}
                            </div>
                          </div>
                        </Disclosure.Button>
                        <div className='overflow-hidden'>
                          <Transition
                            enter='transition-opacity duration-300 ease-out'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='transition-opacity duration-200 ease-in'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Disclosure.Panel className='pt-5'>
                              <SavingsSummary />
                              <button
                                className='bg-darkBrown text-center text-white text-base rounded-full md:mt-3 py-1.5 px-4 cursor-pointer'
                                onClick={getCalculation}
                              >
                                Recalculate
                              </button>
                            </Disclosure.Panel>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Disclosure>
                </div>
                <div className='mt-3 p-3 rounded-lg border border-darkBrown/[.2] mb-28'>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className='w-full'>
                          <div className='flex justify-between'>
                            <div className='text-lg font-medium xs:text-base'>
                              Assumptions
                            </div>
                            <div className='flex flex-row items-center gap-3'>
                              {open ? (
                                <ChevronUpIcon className='w-5 h-5 text-darkBrown' />
                              ) : (
                                <ChevronDownIcon className='w-5 h-5 text-darkBrown' />
                              )}
                            </div>
                          </div>
                        </Disclosure.Button>
                        <div className='overflow-hidden'>
                          <Transition
                            enter='transition-opacity duration-300 ease-out'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='transition-opacity duration-200 ease-in'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Disclosure.Panel className='pt-5'>
                              <Assumptions />
                              <button
                                className='bg-darkBrown text-center text-white text-base rounded-full mt-3 py-1.5 px-4 cursor-pointer'
                                onClick={getCalculation}
                              >
                                Recalculate
                              </button>
                            </Disclosure.Panel>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangeCoverageModal
        show={modal}
        setShow={setModal}
        price={price}
        setPrice={setPrice}
      />
      <InactiveModal
        show={emailModal}
        setShow={setEmailModal}
        preview={{}}
        urlReturnPage='calculation-summary'
      />
      <EmailThanksModal show={emailThanksModal} setShow={setEmailThanksModal} />
      {sessionId && (
        <BirthCheckModal
          show={birthCheckModal}
          setShow={setBirthCheckModal}
          sessionId={sessionId}
          setIncomeRight={setIncomeRight}
          setExpenseRight={setExpenseRight}
          setSavingRight={setSavingRight}
          setPrice={setPrice}
        />
      )}
    </>
  );
};
