import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import Home from './Pages/Home';
// import AboutUs from "./Pages/AboutUs";
import GetQuote from './Pages/GetQuote';
import { FAQ } from './Pages/FAQ/FAQ';
import NeedsCalculator from './Pages/Calculator/NeedsCalculator';
import QuoteSummary from './Pages/QuoteSummary';
import { CalculationSummary } from './Pages/Calculator/CalculationSummary';
import Licenses from './Pages/Licenses';
import { Privacy } from './Pages/Privacy';
import { Terms } from './Pages/Terms';
import GetInTouch from './Pages/GetInTouch';
import AboutUsNew from './Pages/AboutUsNew';
import Application from './Pages/Application';
import Blog from './Pages/Blog';
import BlogPost from './Pages/BlogPost';
import BlogCategory from './Pages/BlogCategory';
import Referral from './Pages/Referral';
import OnePageCalculator from './Pages/Calculator/OnePageCalculator';

const routes: any = createRoutesFromElements(
  <>
    <Route index element={<Home />} />
    <Route
      path='/term-life-insurance-calculator'
      element={<NeedsCalculator />}
    />
    <Route path='/faqs' element={<FAQ />} />
    <Route path='/calculation-summary' element={<CalculationSummary />} />
    <Route path='/term-life-insurance-quote' element={<GetQuote />} />
    <Route path='/quotes-summary' element={<QuoteSummary />} />
    <Route path='/licenses' element={<Licenses />} />
    <Route path='/privacy' element={<Privacy />} />
    <Route path='/terms' element={<Terms />} />
    <Route
      path='/simple-term-life-insurance-calculator'
      element={<OnePageCalculator />}
    />

    <Route path='/get-in-touch' element={<GetInTouch />} />
    <Route path='/about-us' element={<AboutUsNew />} />
    <Route path='/term-life-insurance-application' element={<Application />} />
    <Route path='/blog' element={<Blog />} />
    <Route path='/blog/posts/:blogId/:blogName' element={<BlogPost />} />
    <Route path='/blog/:categoryName' element={<BlogCategory />} />
    <Route path='/referral' element={<Referral />} />
  </>
);

const router = createBrowserRouter(routes);

export default router;
