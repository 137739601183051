interface tipData {
  question: number;
  infoTip: string;
}

export const tips: tipData[] = [
  {
    question: 1,
    infoTip: `Your yearly income is important to your family. 
    If something were to happen to you, we want to ensure that they're taken 
    care of. By knowing your income, we can start to estimate the amount of life 
    insurance needed to maintain the lifestyle your family is accustomed 
    to. It's a big part of making sure they have the financial support 
    they need, no matter what.`,
  },

  {
    question: 2,
    infoTip: `Your spouse's yearly income is just as important. 
    It contributes to the total income your family depends on for their 
    everyday needs and lifestyle. If something were to happen to you, 
    their income alone might not be enough to cover everything. 
    Knowing your spouse's income helps us paint a complete picture 
    of your family's financial needs. This way, we can estimate how 
    much life insurance you'd need to ensure that your family can 
    maintain their current lifestyle without any financial strain.`,
  },

  {
    question: 3,
    infoTip: `Knowing how long your spouse plans to work helps us 
    figure out for how many years their income will be part of the 
    family's budget. If they're planning to work for quite a few 
    more years, you might need less life insurance, and vice versa. 
    However, if you're more comfortable not relying on your spouse's 
    income in case of any unforeseen events, it's safer to enter zero. 
    This helps us gauge the right life insurance amount that secures 
    your family's financial stability, no matter what comes down the road.`,
  },

  {
    question: 4,
    infoTip: `We want to know your spouse's age as it helps us work out 
    how long they might need financial support. Often, you won't need to 
    provide income for their entire lifetime. Things like retirement savings 
    or lower living costs as they age can lessen the need for ongoing 
    financial help. With this info, we can avoid overestimating your 
    insurance needs, finding the right balance for your unique situation.`,
  },
  {
    question: 5,
    infoTip: `The amount left on your mortgage is important here. If the 
    insured person were to pass away, our calculator presumes that you 
    would to pay it off completely. Adding this into the life insurance 
    coverage can help lift the burden of a mortgage, giving your family 
    a secure place to live and a little more financial peace of mind in 
    tough times. Remember, if you have more than one property with a 
    mortgage, you should add the amounts together. If you rent, 
    just put down zero.`,
  },

  {
    question: 6,
    infoTip: `When it comes to non-mortgage debt like student loans, 
    credit card balances, or car loans, we consider these too. Why? 
    Because including them in the life insurance coverage means 
    they could be paid off, lifting another financial weight off 
    your family during a difficult time. So, add up all those debts 
    and include them here - it helps us make sure your coverage is as 
    comprehensive as possible.`,
  },
  {
    question: 7,
    infoTip: `Consider your personal philosophy on your children's education. 
    Start by specifying the number of children for whom you intend to provide support. 
    We've estimated costs including tuition, room, and board, but keep in 
    mind that costs for in-state institutions can vary on location. 
    You may adjust the total amount you'd like to contribute to each type of institution 
    - remember that each amount should be per child. Including this in your life insurance 
    coverage helps ensure your children's education can be funded, 
    regardless of unforeseen circumstances.`,
  },
  {
    question: 8,
    infoTip: `Big events like weddings or religious ceremonies can 
    come with hefty price tags. That's why we ask about future 
    one-time expenses you want to provide for. By estimating these costs, 
    we can include them in your life insurance coverage, making sure 
    your family can celebrate those special moments, even if you're 
    not around. Input your best guess in today's dollars.`,
  },
  {
    question: 9,
    infoTip: `Our calculator takes into account your savings and investments 
    because life insurance supplements your current savings to ensure your 
    family's living expenses are covered. Including their current value 
    helps ensure that your family's financial needs are adequately met 
    in the event of an unfortunate circumstance. When considering your 
    answer, think about all assets that could be readily converted into 
    cash-on-hand if needed. This may include liquid accounts, investments, 
    and other valuable assets that you are certain you would sell to provide 
    in the event of the insured's death.`,
  },
  {
    question: 10,
    infoTip: `By knowing how much you currently save per year, we gain 
    a more accurate understanding of your family's current financial situation. 
    It helps us estimate the amount of income your family relies on and allows 
    us to provide a more precise assessment of your life insurance needs. It's 
    important to differentiate between pre-tax retirement savings, such as 
    contributions to accounts like a 401k, and after-tax additional savings, 
    which may come from other investment accounts or personal savings.`,
  },
];
