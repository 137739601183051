import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../Components/Footer";
import { NavBar } from "../Components/NavBar";
import { BaseURL } from "../helpers/API/ApiData";
import { ErrorToast } from "../helpers/Toast";

const License = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    await axios
      .get(BaseURL + "/user/get/state")
      .then((res: any) => {
        setData(res?.data?.data);
      })
      .catch((err: any) => ErrorToast(err?.message));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  return (
    <div className="min-h-screen bg-lightBrown">
      <Helmet>
        <title>Our Licences | ExplainLife</title>
        <meta
          name="description"
          content="ExplainLife is committed to complying with all laws and regulations relating to life insurance. All life insurance policies are offered by Adam Kaminer."
        />
        <meta property="og:title" content="Our Licences | ExplainLife" />
        <meta
          property="og:description"
          content="ExplainLife is committed to complying with all laws and regulations relating to life insurance. All life insurance policies are offered by Adam Kaminer."
        />
      </Helmet>
      <NavBar />
      <div className="mb-10 flex flex-col pt-28">
        <div className="min-w-[330px] max-w-[1023px] text-darkBrown mx-auto lg:mx-5">
          <h1 className="font-judson_italic block subpixel-antialiased font-light text-4xl sm:text-3xl mb-10 xs:mb-0">
            Licenses
          </h1>
          <p className="mt-3">
            ExplainLife is committed to complying with all laws and regulations
            relating to life insurance. All life insurance policies are offered
            by Adam Kaminer. Listed below are Adam Kaminer's life insurance
            agent/producer licenses.
          </p>
          <div className="statesMainDiv mt-3 flex flex-wrap">
            {data?.length > 0 &&
              data?.map((val: any, ind: any) => {
                return (
                  <div
                    className="w-4/12 md:w-6/12 xs:w-full p-4 xs:p-2"
                    key={ind}
                  >
                    <div className="bg-middleBrown rounded-lg shadow-lg p-4 flex flex-col xs:flex-row xs:justify-between">
                      <h2 className="text-2xl font-semibold text-darkBrown">
                        {val?.stateName}
                      </h2>
                      <p className="text-lg font-light">#{val?.license}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default License;
