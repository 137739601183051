import { useContext, useEffect, useState } from 'react';
import { FormButton } from '../../Components/Button';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
} from '@heroicons/react/24/solid';
import { useForm } from 'react-hook-form';
import {
  InsuredIncome,
  ReplacementIncomeYears,
  SpouseIncome,
  SpouseIncomeYears,
  Savings,
  YearlySavings,
  Mortgage,
  Debt,
  College,
  OneTime,
  useAutoFocusInput,
} from './Questions';
import classNames from 'classnames';
import { OutlineInfoTip } from '../../Components/InfoTip';
import { tips } from './data';
import { useNavigate } from 'react-router-dom';
import { MobileVideoModal } from '../../Components/Modals/MobileVideoModal';
import { CalculatorFormContext } from './context';
import startFlowImgLg from '../../media/img/startFlowImgLg.png';
import startFlowLineXs from '../../media/img/startFlowLineXs.png';
import startIncomeImg from '../../media/img/startIncomeImg.png';
import startExpenseImg from '../../media/img/startExpenseImg.png';
import startSavingImg from '../../media/img/startSavingImg.png';
import ReactPlayer from 'react-player';

const INPUT_CLASSES = classNames(
  'font-medium',
  'w-full',
  'h-7',
  'text-xl',
  'text-darkBrown',
  'font-archivo',
  'font-semibold',
  'px-2',
  'py-5',
  'outline-none',
  'ml-1',
  'sm:ml-0',
  'mb-4',
  'focus:ring-0',
  'border-b-2',
  'border-darkBrown',
  'bg-explainWhite',
  'rounded-none'
);
export const CalculatorForm = (props: {
  statusBarWidth: number;
  setStatusBarWidth: (statusBarWidth: number) => void;
}) => {
  const { formData } = useContext(CalculatorFormContext);
  const [page, setPage] = useState(0);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [nameData, setNameData] = useState({
    insuredName: '',
    spouseName: '',
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const methods = useForm();
  const navigate = useNavigate();
  const sessionId = new URLSearchParams(window.location.search).get(
    'session_id'
  );

  useEffect(() => {
    const formData1: any = localStorage.getItem('quoteData');
    const questionFilled: any = localStorage.getItem('questionFilled');

    if (formData1 || questionFilled === 'true') {
      const parseLocalData = JSON.parse(formData1);
      if (
        parseLocalData?.calculation ||
        parseLocalData ||
        questionFilled === 'true'
      ) {
        setPage(10);
        props.setStatusBarWidth(11);
      } else {
        setPage(0);
        props.setStatusBarWidth(1);
      }
    }
    window.dataLayer = window.dataLayer || []; // Define the dataLayer object
  }, []);

  const onNext = () => {
    const contentIdMap: { [key: number]: string } = {
      0: 'StartPage',
      1: 'InsuredIncome',
      2: 'SpouseIncome',
      3: 'SpouseIncomeYears',
      4: 'ReplacementIncomeYears',
      5: 'Mortgage',
      6: 'Debt',
      7: 'College',
      8: 'OneTime',
      9: 'Savings',
      10: 'YearlySavings',
    };

    const contentId = contentIdMap[page] || '';

    if (page === 10) {
      if (sessionId) {
        navigate('/calculation-summary?session_id=' + sessionId);
      } else {
        navigate('/calculation-summary');
      }
      localStorage.setItem('quoteData', JSON.stringify(formData));
      sessionStorage.setItem('quoteData', JSON.stringify(formData));
      localStorage.setItem('questionFilled', 'true');
      sessionStorage.setItem('questionFilled', 'true');
    } else {
      if (page === 2) {
        if (!formData['spouseIncome'] || formData['spouseIncome'] === '$0') {
          props.setStatusBarWidth(props.statusBarWidth + 2);
          setPage(page + 2);
        } else {
          props.setStatusBarWidth(props.statusBarWidth + 1);
          setPage(page + 1);
        }
      } else {
        props.setStatusBarWidth(props.statusBarWidth + 1);
        setPage(page + 1);
      }
    }

    window.dataLayer.push({
      event: 'ViewContent',
      content_id: contentId,
    });

    window.scrollTo(0, 0);
  };
  const handleChange = async (e: any) => {
    const { name, value } = e.target;
    setNameData({ ...nameData, [name]: value?.trimStart() });
  };
  const onPrev = () => {
    props.setStatusBarWidth(props.statusBarWidth - 1);
    setPage(page - 1);
    if (page === 0) {
      navigate('/');
    } else if (page === 4) {
      if (!formData['spouseIncome'] || formData['spouseIncome'] === '$0') {
        props.setStatusBarWidth(props.statusBarWidth - 2);
        setPage(page - 2);
      } else {
        props.setStatusBarWidth(props.statusBarWidth - 1);
        setPage(page - 1);
      }
    } else {
      props.setStatusBarWidth(props.statusBarWidth - 1);
      setPage(page - 1);
    }
    window.scrollTo(0, 0);
  };
  useAutoFocusInput('autoFocusId');
  return (
    <>
      <div className='flex flex-col justify-center items-center sm:items-center mt-[100px]'>
        {page === 0 ? (
          <>
            <div className='flex flex-row justify-center items-center w-[1400px] xxl:w-[85vw] gap-32 sm:gap-10 md:gap-10 lg:gap-10 lg:flex-col md:mt-10 lg:mt-10 xl:mt-10 xl:gap-16 mb-20'>
              <form
                className='justify-center w-[400px] max-w-[600px] lg:w-[90vw]'
                onSubmit={() => {
                  onNext();
                  localStorage.setItem(
                    'InsuredSpouseName',
                    JSON.stringify(nameData)
                  );
                }}
              >
                <h1 className='mb-6 md:mb-3 text-2xl font-medium text-black w-[400px] sm:text-[1.2rem] sm:w-fit'>
                  How much life insurance do I need?
                </h1>
                <h3 className='mb-6 font-normal text-sm text-darkBrown font-archivo'>
                  Please enter the first name of the person you would like to
                  insure, as well as the first name of their spouse. Feel free
                  to use aliases if you prefer.
                </h3>
                <div className='w-full'>
                  <div className='w-full'>
                    <input
                      id='autoFocusId'
                      name='insuredName'
                      placeholder="Insured's First Name"
                      className={INPUT_CLASSES}
                      value={nameData?.insuredName}
                      onChange={handleChange}
                      autoComplete='off'
                      autoCorrect='off'
                    />
                  </div>
                  <div className='w-full'>
                    <input
                      name='spouseName'
                      placeholder="Spouse's First Name"
                      className={INPUT_CLASSES}
                      value={nameData?.spouseName}
                      onChange={handleChange}
                      autoComplete='off'
                      autoCorrect='off'
                    />
                  </div>
                </div>
                <FormButton type='submit'>Start</FormButton>
              </form>
              <div className='flex flex-col sm:mb-5 rounded-3xl text-xl lg:w-[80vw] max-h-[70vh] lg:max-h-[unset] overflow-auto lg:overflow-visible text-darkBrown w-full'>
                <div className='MainHead w-full mb-5'>
                  <h2 className='text-4xl font-judson_italic text-center mb-2'>
                    How it works
                  </h2>
                  <h3 className='flex justify-center items-center'>
                    <ClockIcon className='h-6 w-6 mr-1' />{' '}
                    <span className='text-sm'>
                      Approx. 10-15 minutes (10 Questions)
                    </span>
                  </h3>
                </div>
                <div className='flex flex-col justify-center w-full gap-12 relative'>
                  <div className='flex flex-col md:flex-col gap-14 xs:gap-10 w-full'>
                    <div className='flex'>
                      <div className='w-1/2 xs:w-[30%] flex items-center justify-center'>
                        <img
                          src={startIncomeImg}
                          className='z-10 relative hidden xs:block'
                          alt='startIncomeImg'
                        />
                      </div>
                      <div className='w-1/3 xs:w-[70%]'>
                        <div className='part1 w-full'>
                          <div className='flex items-center'>
                            <div className='flex flex-col max-w-[500px]'>
                              <h3 className='text-2xl mb-3 font-judson_italic font-semibold'>
                                Part 1: Income
                              </h3>
                              <p className='text-sm xs:mb-3'>
                                Understanding your current income helps us
                                determine how much your family currently needs
                                to live. You'll need details about your and your
                                spouse's income and the expected career length.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='flex'>
                      <div className='w-1/3 md:w-[50%] xs:w-[70%] xs:order-2'>
                        <div className='part1 w-full'>
                          <div className='flex sm:flex-col gap-10 sm:gap-4 items-center'>
                            <div className='flex flex-col text-end xs:text-start max-w-[500px]'>
                              <h3 className='text-2xl font-judson_italic font-semibold mb-3'>
                                Part 2: Expenses
                              </h3>
                              <p className='text-sm'>
                                Over the next few decades, your family has large
                                expenses that should be accounted for in your
                                life insurance planning. We'll need estimates of
                                your mortgage, debts, college plans, and more.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='w-[50%] md:w-[50%] xs:w-[30%] xs:order-1 flex items-center justify-center'>
                        <img
                          src={startExpenseImg}
                          className='z-10 relative hidden xs:block'
                          alt='startExpenseImg'
                        />
                      </div>
                    </div>
                    <div className='flex'>
                      <div className='w-1/2 xs:w-[30%] flex items-center justify-center'>
                        <img
                          src={startSavingImg}
                          className='z-10 relative hidden xs:block'
                          alt='startSavingImg'
                        />
                      </div>
                      <div className='w-1/3 xs:w-[70%]'>
                        <div className='part1 w-full'>
                          <div className='flex sm:flex-col gap-10 sm:gap-4 items-center'>
                            <div className='flex flex-col max-w-[500px]'>
                              <h3 className='text-2xl mb-3 font-judson_italic font-semibold xs:mt-4'>
                                Part 3: Savings
                              </h3>
                              <p className='text-sm'>
                                Your savings and investments could impact the
                                amount of life insurance you need. Please
                                prepare estimates of your total savings,
                                investments, and annual contributions.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='absolute top-[0px] xs:left-[13%] h-full xs:w-1 w-full'>
                    <img
                      src={startFlowImgLg}
                      className='w-[400px] xs:hidden h-full mx-auto'
                      alt='startFlowImgLg'
                    />
                    <img
                      src={startFlowLineXs}
                      className='xs:block hidden max-w-none z-0 mx-auto'
                      alt='startFlowLineXs'
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          page > 0 &&
          page < 11 && (
            <div className='flex flex-row items-center w-[80vw] h-[70vh] justify-center sm:h-fit sm:gap-10 md:gap-10 lg:gap-10 gap-24 sm:flex-col md:flex-col lg:flex-col lg:pb-3'>
              <div className='flex flex-col w-[500px] sm:w-full xs:ml-[-20px] items-center justify-center'>
                {tips[page - 1]?.infoTip && (
                  <OutlineInfoTip
                    info={tips[page - 1].infoTip}
                    className='ml-auto'
                  />
                )}
                <div className='flex w-full flex-row'>
                  <div className='text-black text-2xl sm:text-lg font-medium mr-4 sm:mr-2'>
                    {page > 2 &&
                    (!formData['spouseIncome'] ||
                      formData['spouseIncome'] === '$0')
                      ? page - 1
                      : page}
                  </div>
                  <form
                    onSubmit={methods.handleSubmit(onNext)}
                    className='w-full'
                  >
                    {page === 1 && <InsuredIncome />}
                    {page === 2 && <SpouseIncome />}
                    {page === 3 && <SpouseIncomeYears />}
                    {page === 4 && <ReplacementIncomeYears />}
                    {page === 5 && <Mortgage />}
                    {page === 6 && <Debt />}
                    {page === 7 && <College />}
                    {page === 8 && <OneTime />}
                    {page === 9 && <Savings />}
                    {page === 10 && <YearlySavings />}
                    <div className='md:text-center md:w-full'>
                      <FormButton type='submit'>
                        {page < 10 ? 'Next' : 'Calculate'}
                      </FormButton>
                    </div>
                  </form>
                </div>
              </div>
              <div className='flex justify-center items-center sm:mb-5 w-[600px] rounded-3xl text-xl sm:w-[80vw] lg:hidden'>
                {page === 1 && (
                  <>
                    <div
                      className='video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full'
                      onClick={() => {
                        if (isPlaying) {
                          setIsPlaying(false);
                        }
                      }}
                    >
                      <ReactPlayer
                        url='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+1.mp4'
                        playing={isPlaying}
                        className='react-player'
                        playsinline
                      />
                      <button
                        className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                          isPlaying ? 'invisible' : 'visible'
                        }`}
                        onClick={() => setIsPlaying(!isPlaying)}
                      ></button>
                    </div>
                    <MobileVideoModal
                      show={showVideoModal}
                      setShow={setShowVideoModal}
                      playsInline
                      videoSrc='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+1.mp4'
                    />
                  </>
                )}
                {page === 2 && (
                  <>
                    <div
                      className='video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full'
                      onClick={() => {
                        if (isPlaying) {
                          setIsPlaying(false);
                        }
                      }}
                    >
                      <ReactPlayer
                        url='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+2.mp4'
                        playing={isPlaying}
                        className='react-player'
                        playsinline
                      />
                      <button
                        className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                          isPlaying ? 'invisible' : 'visible'
                        }`}
                        onClick={() => setIsPlaying(!isPlaying)}
                      ></button>
                    </div>
                    <MobileVideoModal
                      show={showVideoModal}
                      setShow={setShowVideoModal}
                      playsInline
                      videoSrc='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+2.mp4'
                    />
                  </>
                )}
                {page === 3 && (
                  <>
                    <div
                      className='video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full'
                      onClick={() => {
                        if (isPlaying) {
                          setIsPlaying(false);
                        }
                      }}
                    >
                      <ReactPlayer
                        url='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+3.mp4'
                        playing={isPlaying}
                        className='react-player'
                        playsinline
                      />
                      <button
                        className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                          isPlaying ? 'invisible' : 'visible'
                        }`}
                        onClick={() => setIsPlaying(!isPlaying)}
                      ></button>
                    </div>
                    {isPlaying && (
                      <div className='pause-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        {/* Pause button icon */}
                      </div>
                    )}
                    <MobileVideoModal
                      show={showVideoModal}
                      setShow={setShowVideoModal}
                      playsInline
                      videoSrc='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+3.mp4'
                    />
                  </>
                )}
                {page === 4 && (
                  <>
                    <div
                      className='video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full'
                      onClick={() => {
                        if (isPlaying) {
                          setIsPlaying(false);
                        }
                      }}
                    >
                      <ReactPlayer
                        url='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+4.mp4'
                        playing={isPlaying}
                        className='react-player'
                        playsinline
                      />
                      <button
                        className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                          isPlaying ? 'invisible' : 'visible'
                        }`}
                        onClick={() => setIsPlaying(!isPlaying)}
                      ></button>
                    </div>
                    <MobileVideoModal
                      show={showVideoModal}
                      setShow={setShowVideoModal}
                      playsInline
                      videoSrc='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+4.mp4'
                    />
                  </>
                )}
                {page === 5 && (
                  <>
                    <div
                      className='video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full'
                      onClick={() => {
                        if (isPlaying) {
                          setIsPlaying(false);
                        }
                      }}
                    >
                      <ReactPlayer
                        url='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+5.mp4'
                        playing={isPlaying}
                        className='react-player'
                        playsinline
                      />
                      <button
                        className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                          isPlaying ? 'invisible' : 'visible'
                        }`}
                        onClick={() => setIsPlaying(!isPlaying)}
                      ></button>
                    </div>
                    <MobileVideoModal
                      show={showVideoModal}
                      setShow={setShowVideoModal}
                      playsInline
                      videoSrc='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+5.mp4'
                    />
                  </>
                )}
                {page === 6 && (
                  <>
                    <div
                      className='video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full'
                      onClick={() => {
                        if (isPlaying) {
                          setIsPlaying(false);
                        }
                      }}
                    >
                      <ReactPlayer
                        url='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+6.mp4'
                        playing={isPlaying}
                        className='react-player'
                        playsinline
                      />
                      <button
                        className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                          isPlaying ? 'invisible' : 'visible'
                        }`}
                        onClick={() => setIsPlaying(!isPlaying)}
                      ></button>
                    </div>

                    <MobileVideoModal
                      show={showVideoModal}
                      setShow={setShowVideoModal}
                      playsInline
                      videoSrc='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+6.mp4'
                    />
                  </>
                )}
                {page === 7 && (
                  <>
                    <div
                      className='video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full'
                      onClick={() => {
                        if (isPlaying) {
                          setIsPlaying(false);
                        }
                      }}
                    >
                      <ReactPlayer
                        url='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+7.mp4'
                        playing={isPlaying}
                        className='react-player'
                        playsinline
                      />
                      <button
                        className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                          isPlaying ? 'invisible' : 'visible'
                        }`}
                        onClick={() => setIsPlaying(!isPlaying)}
                      ></button>
                    </div>
                    <MobileVideoModal
                      show={showVideoModal}
                      setShow={setShowVideoModal}
                      playsInline
                      videoSrc='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+7.mp4'
                    />
                  </>
                )}
                {page === 8 && (
                  <>
                    <div
                      className='video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full'
                      onClick={() => {
                        if (isPlaying) {
                          setIsPlaying(false);
                        }
                      }}
                    >
                      <ReactPlayer
                        url='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+8.mp4'
                        playing={isPlaying}
                        className='react-player'
                        playsinline
                      />
                      <button
                        className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                          isPlaying ? 'invisible' : 'visible'
                        }`}
                        onClick={() => setIsPlaying(!isPlaying)}
                      ></button>
                    </div>
                    <MobileVideoModal
                      show={showVideoModal}
                      setShow={setShowVideoModal}
                      playsInline
                      videoSrc='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+8.mp4'
                    />
                  </>
                )}
                {page === 9 && (
                  <>
                    <div
                      className='video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full'
                      onClick={() => {
                        if (isPlaying) {
                          setIsPlaying(false);
                        }
                      }}
                    >
                      <ReactPlayer
                        url='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+9.mp4'
                        playing={isPlaying}
                        className='react-player'
                        playsinline
                      />
                      <button
                        className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                          isPlaying ? 'invisible' : 'visible'
                        }`}
                        onClick={() => setIsPlaying(!isPlaying)}
                      ></button>
                    </div>
                    <MobileVideoModal
                      show={showVideoModal}
                      setShow={setShowVideoModal}
                      playsInline
                      videoSrc='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+9.mp4'
                    />
                  </>
                )}
                {page === 10 && (
                  <>
                    <div
                      className='video-container relative w-[600px] mx-auto bg-darkBrown/[.5] rounded-2xl sm:w-full'
                      onClick={() => {
                        if (isPlaying) {
                          setIsPlaying(false);
                        }
                      }}
                    >
                      <ReactPlayer
                        url='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+10.mp4'
                        playing={isPlaying}
                        className='react-player'
                        playsinline
                      />
                      <button
                        className={`play-button absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                          isPlaying ? 'invisible' : 'visible'
                        }`}
                        onClick={() => setIsPlaying(!isPlaying)}
                      ></button>
                    </div>
                    <MobileVideoModal
                      show={showVideoModal}
                      setShow={setShowVideoModal}
                      playsInline
                      videoSrc='https://explain-bucket.s3.amazonaws.com/explainBannerVideos/Question+10.mp4'
                    />
                  </>
                )}
              </div>
              <div className='lg:block hidden text-center'>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className='underline text-underline text-darkBrown font-archivo cursor-pointer'
                  onClick={() => setShowVideoModal(true)}
                >
                  ExplainLife Video Explanation
                </a>
              </div>
            </div>
          )
        )}
      </div>
      <Nav onNext={onNext} onPrev={onPrev} page={page} />
    </>
  );
};

const Nav = (props: {
  onNext: () => void;
  onPrev: () => void;
  page: number;
}) => {
  const allowRight = props.page < 11;
  const allowLeft = true;
  return (
    <nav
      aria-label='Pagination'
      className='flex flex-col ml-auto gap-2 mb-6 sm:mr-2 mr-6 fixed bottom-0 right-0'
    >
      <button
        onClick={() => (allowLeft ? props.onPrev() : undefined)}
        className={classNames(
          'flex justify-center items-center bg-darkBrown w-8 h-8 text-white rounded-md',
          allowLeft ? 'bg-darkBrown' : 'bg-gray-300'
        )}
        disabled={allowLeft ? false : true}
      >
        <ChevronUpIcon className='w-4 h-4' />
      </button>
      <button
        onClick={() => (allowRight ? props.onNext() : undefined)}
        className={classNames(
          'flex justify-center items-center bg-darkBrown w-8 h-8 text-white rounded-md',
          allowRight ? 'bg-darkBrown' : 'bg-gray-300'
        )}
        disabled={allowRight ? false : true}
      >
        <ChevronDownIcon className='w-4 h-4' />
      </button>
    </nav>
  );
};
