import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../Components/Footer";
import { NavBar } from "../Components/NavBar";

export const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-lightBrown">
      <Helmet>
        <title>Privacy Policy | ExplainLife</title>
        <meta
          name="description"
          content="This privacy notice for ExplainLife Insurance LLC describes how we collect, store, use,  share your information when you use our services on our website."
        />
        <meta property="og:title" content="Privacy Policy | ExplainLife" />
        <meta
          property="og:description"
          content="This privacy notice for ExplainLife Insurance LLC describes how we collect, store, use,  share your information when you use our services on our website."
        />
      </Helmet>
      <NavBar />
      <iframe
        src="https://app.termly.io/document/privacy-policy/86491412-eb27-484d-9690-fb83876f921b"
        style={{
          width: "100%",
          marginTop: "87px",
          height: "calc(100vh - 87px)",
        }} // Subtract the heights of your NavBar and Footer
        title="Privacy Policy"
      />
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};
